.b-icon__add-simple {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUZBNjkxMUJCQ0RBMTFFNDk4RUVEOTQ4RTc5NDI2MkMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUZBNjkxMUNCQ0RBMTFFNDk4RUVEOTQ4RTc5NDI2MkMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRkE2OTExOUJDREExMUU0OThFRUQ5NDhFNzk0MjYyQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRkE2OTExQUJDREExMUU0OThFRUQ5NDhFNzk0MjYyQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pkr3dO0AAAErSURBVHjatJU7CsJAEIazPgsLjyFJLL2BCtZ6BSutvIiVdh5E8ApWvvAeii+Q+A/8K+uSjZrowkfIZObfSWZ2oryMK4qil/tcgm8AxmAHLmRHW/DNpiGYgC0YAR+UiU/blj6hZGhiry44gyEo2M5GUIE+4tt1CUpmJ9ByCcUItxgTxgnKKww/FTMEJNOJLRjwu+QdQSvB8SzP2EDudZX7YAbDPUXbSMyMGk/BDphnaMc5NTxFg/RYFbtdtYdSamn1pMc+1Jk1DN8SLgfYyjpDEY4yZCiCN9MgO/sJlXQWhc/rYG0W5fkNUq42WNjn9idt87fGTnv0mklHzxwOgw+Gw4C+vaRpoys2NcZXDRRBhW+hx5f41N+Nr7gBu2ePHcHGHrC2oPr1L+AhwAC0Ea/5keCAnwAAAABJRU5ErkJggg==) no-repeat 0 5px;
    margin-right: 5px;
    cursor: pointer;
}

.b-icon__edit-simple {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REQzRTYzQjhCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REQzRTYzQjlCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpERDNFNjNCNkJEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpERDNFNjNCN0JEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnzhP70AAAFDSURBVHjarJRLagJBEIadaOIjuYeZGTfewRN4DF15EVdm6RE8QryEOgSCkAMEERJ8ZNP5C/6Esq1uHbDgW0xT89HdVV2Jc65y0xChBSIDY1CAAym4lgX/M0Q5mIAVGIEU1EnKtRVz8qgQ0Qd7MAS1yO5rzJHcvinkznagV+Jee5TmllCOMFTfViTg3luTnU5OhCyA3Es1IhTZFMzAg1qv8t9MC6VyI++eLJkj/1LmSqHGWijtkAaEvszx+04JpfqFFkqP1cvKlFB2e9TCoyx6wmfQBPOQTAmfwFfwyIw16IIWeLVkStgBi2BRGFvwSWmDx68EhGdFOWkbxgfYgHfQjjT3edv4jV1mkJiNbT29a4g+PW84DK4YDoPocFDJUrEXNb7afL+PPMXf+JKczsV5aAzYN/ADvsHy0oBNbjHxdfwKMABmJu3KHmRHFgAAAABJRU5ErkJggg==) no-repeat 0 5px;
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.b-icon__padding-around {
    padding: 5px 10px 5px 10px;
}

.b-icon__padding-vertical-5px {
    padding: 5px 10px 5px 10px !important;
}

.b-icon__inline-20px {
    display: inline-block;
    height: 20px;
    width: 20px;
}

.b-icon__float-right {
    float: right;
}

.b-icon__no_padding {
    padding: 0px;
}