.b-diary-st__wrap {
	margin-bottom: 100px;
	padding: 20px 20px;
}

.b-diary-week__column {
	margin-bottom: 30px;
}

.b-diary-week-head__title {
	background: #ebf2f5;
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	text-align: center;
	position: relative;
	padding: 5px 0px;
}

.b-diary-week-head__title span {
	font-size: 14px;
	font-weight: bold;
	color: #6c8796;
}

.b-diary-week-head__title .b-diary-date {
	color: #53a8ad;
}

.b-dl-table__head {
	border-bottom: 2px solid #cfd4d7;
}

.b-dl-th_column {
	display: table-cell;
	vertical-align: middle;
	height: 30px;
	border-right: 1px solid #cfd4d7;
	line-height: normal;
}

.b-dl-th_column:nth-child(1) {
	width: 137px;
}

.b-dl-th_column:nth-child(2) {
	padding-left: 10px;
	width: 416px;
}

.b-dl-th_column:nth-child(3) {
	text-align: center;
	width: 70px;
}

.b-dl-th_column:last-of-type {
	padding-left: 10px;
	width: 232px;
	border-right: none;
}

.b-dl-th_column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

/*----*/
.b-dl__dynamic-pause {
	padding: 5px 0;
	text-align: center;
	background: #f6f9fb;
	border-bottom: 1px solid #cfd4d7;
	font-size: 11px;
	cursor: default;
	line-height: 13px;
}

.b-dl-td_column {
	display: table-cell;
	vertical-align: middle;
	border-right: 1px solid #cfd4d7;
	border-bottom: 1px solid #cfd4d7;
	height: 33px;
	position: relative;
}

.b-dl-td_column:hover {
	background: #d7f0ef;
}

.b-dl-td_column span {
	font-size: 12px;
	color: #454856;
	cursor: default;
}

.lesson-grades-column,
.lesson-subject-name-column {
	line-height: 13px;
}

.b-dl-td_column.lesson-grades-column span,
.b-dl-td_column.lesson-subject-name-column span {
	font-size: 15px;
}

.b-dl-td_column.lesson-subject-name-column span {
	width: 111px;
	word-wrap: break-word;
	display: block;
}

.grade-diary i {
	margin: 0 auto;
	background-position: 0;
}

.b-dl-td_column:nth-child(1) {
	width: 20px;
	text-align: center;
}

.b-dl-td_column:nth-child(2) {
	width: 111px;
	padding-left: 5px;
}

.b-dl-td_column:nth-child(3) {
	width: 426px;
}

.b-dl-td_column:nth-child(4) {
	width: 70px;
	text-align: center;
}

.b-dl-td_column:nth-child(5) {
	width: 241px;
	border-right: none;
}

.b-dl-td_column-hw {
	/*border-bottom: 1px solid #cfd4d7;*/
}

.bold-mark {
	font-weight: bold;
}

.b-dl-td-hw-assessment,
.b-dl-td-hw-comments {
	border-bottom: 1px solid #cfd4d7;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	position: relative;
}

.b-dl-td_column-hw .lesson-comment {
	color: #e02525;
	display: block;
	text-align: center;
}

.b-dl-td_column-hw:last-of-type,
.b-dl-td-hw-assessment:last-of-type,
.b-dl-td-hw-comments:last-of-type {
	border-bottom: none;
}

.b-dl-td_column:nth-child(1):hover,
.b-dl-td_column:nth-child(2):hover,
.b-dl-active,
.b-dl-assessment_active,
.b-dl-td-hw-assessment:hover {
	background: #d7f0ef;
}

.b-dl__comment {
	position: absolute;
	width: 200px;
	padding: 5px 2px;
	border-top: 5px solid #39b3ae;
	box-shadow: 0 0 5px #cfd4d7;
	background: #fff;
	z-index: 1;
	left: 71px;
	top: 100%;
	line-height: 12px;
	text-align: left;
}

.b-dl__comment.grade-info span {
	font-size: 12px;
}

.b-dl__lessons-comment .lesson-kes-item {
	display: block;
	margin: 5px 0;
}

.b-dl__lessons-comment {
	position: absolute;
	width: 250px;
	padding: 5px 2px;
	border-top: 5px solid #39b3ae;
	box-shadow: 0 0 5px #cfd4d7;
	background: #fff;
	z-index: 1;
	left: 100%;
	top: 100%;
	line-height: 12px;
}

.b-dl__lessons-comment span {
	width: 250px !important;
	line-height: 15px;
}

.b-dl__time-comment {
	position: absolute;
	width: 40px;
	padding: 5px 2px;
	border-top: 5px solid #39b3ae;
	box-shadow: 0 0 5px #cfd4d7;
	background: #fff;
	z-index: 1;
	left: 21px;
	top: 100%;
}

.b-dl__time-comment span,
.b-dl__lessons-comment span,
.b-dl__comment span {
	font-size: 11px;
	font-weight: bold;
}

.b-dl-td-hw-section {
	display: table-cell;
	vertical-align: middle;
	/*border-right: 1px solid #cfd4d7;*/
}

.b-dl-td-hw-section .homework-description {
	display: block;
	padding: 5px;
	width: 300px;
	word-wrap: break-word;
}

a.homework-book-link:hover {
	text-decoration: none;
}

a.homework-book-link {
	display: block;
	margin: 7px 0;
	color: #53a8ad;
	line-height: 15px;
}

/*.b-dl-td-hw-section:nth-child(1) {*/
/*width: 40px;*/
/*text-align: center;*/
/*}*/

.b-dl-td-hw-section:nth-child(1) {
	width: 300px;
	text-align: center;
	overflow: hidden;
	padding-top: 5px;
	padding-bottom: 5px;
}

.b-dl-td-hw-section:nth-child(2) {
	border-right: none;
	padding: 3px 3px;
}

.b-dl-td-hw-section_time {
	display: table-cell;
	width: 170px;
	border-right: 1px solid #cfd4d7;
}

.b-dl-td-hw-section_hw {
	display: table-cell;
	width: 40px;
}

.b-dl-td-hw-section_hw .i-icon__home-work {
	position: relative;
	top: 3px;
	left: 4px;
}

.b-dl-td-hw-section_time .i-icon__hw-timer {
	position: relative;
	top: 3px;
}

/*----*/

.i-icon__hw-timer {
	cursor: pointer;
	display: inline-block;
	height: 20px;
	width: 20px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OThDREEyRTNGNEIxMTFFNDk5MDRFRTU0NDc5MjA3RjUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OThDREEyRTRGNEIxMTFFNDk5MDRFRTU0NDc5MjA3RjUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5OENEQTJFMUY0QjExMUU0OTkwNEVFNTQ0NzkyMDdGNSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5OENEQTJFMkY0QjExMUU0OTkwNEVFNTQ0NzkyMDdGNSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnDnFkYAAADpSURBVHjaYvz//z8DNQETA5UB1Q1kAHkZhtGAFhDPAOJ7QPwbiu9BxbRwmoHFQEYgrocasBKIA4BYHYoDoGK/oWoYiTGwC4hfA7E9lM+GxWP2UDVdhAy0AuK/QGyNpLkdiOWwGGoNVWuFz8C1QLwYTaMFEBfjiAKQ2rX4DHwLxIFomkDhtArKNgFiGyQ5kNq3yGagJxshIH6KnhCA+BEQ9wNxMBCfQZJ7CtUDByxomt8BsTQWr00DYlEgPokmLg3VgzMdYgtDfIBgGGKLZVyAqFjGlg4ZKEmHNMkpFOVlxpFXHlLdQIAAAwD5b//nZXFm4wAAAABJRU5ErkJggg==) no-repeat;
	background-position: 0px 2px;
}

.i-icon__home-work {
	cursor: pointer;
	display: inline-block;
	height: 20px;
	width: 20px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAABjCAYAAAD+Q9ILAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDRFMkUzODJGMkY4MTFFNDgyMTZGNTY5OTJEMjQ0NTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDRFMkUzODNGMkY4MTFFNDgyMTZGNTY5OTJEMjQ0NTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpENEUyRTM4MEYyRjgxMUU0ODIxNkY1Njk5MkQyNDQ1OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpENEUyRTM4MUYyRjgxMUU0ODIxNkY1Njk5MkQyNDQ1OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pu5QHFQAAAIZSURBVHja7JjNS0JBFMWzb1pE7SOwTYsWFbUq6A8oF/UPuDdw0yapaJsQtJGQ2lXLFoUJbYuoIKKvjdAmEZdFpNAHRq8zMMJTHjpSM++J58JxVK7Ob+6MF+f4LMtq8kI0N3kkCEIQghDkr9GqmhiOxoMYdmr47nwsEur+dxDEkhz9Crl90JmWiiAGxQNWmVaonr6tcZhMfDYEtdje3gPoi9HDigm/MZxCJzbltB9Wh4qIRfSULaYNKhgFQfigXjkWox16Nw3yA6XLQD6Mb408pMNlW5OBvnSDFOQZsB/WXTda/KcnWnyxX+DXYrkN0gltQ2sKufvQtS4QsY2H1Vo8KiYqNwYFdYHcQ5OYKKWQm4WmoJQOkEXoCFpWzF+HtpS7I296BCEIQQhCEIJ41Q2YSB7U7AZcBOYawA3AKtMK1TN3wcJkjm4AQM26AZjQG24AKkI3gG5ARTdAHla6AeLXQjegxA2o1uJRMTNuACaiG8C/igQhCEEIQhCCEKQhQGq6YIWj8TcMlcyXS2gmFgm96q6IgFiARh10Aw1B5wDuN3HlfMKK7xyqlcdwDA2IiyBeTyPvwa0zMg49yudXbh3WDSgJPUO3UIcpN6AksA0JDAm5TbMYAkZAMJnwRTahLtvbKwDKGvVHMKG4EM270kcUKrIKwExdV6Q+W7wMP7ZlpFqObpCc7BequcpBN4AgBCEIQQjSMCC/AgwATkWtYPNighgAAAAASUVORK5CYII=) no-repeat;
	background-position: -6px -38px;
}

/*left panel*/
.ts-container__variants {
	position: relative;
	width: 150px;
	margin: 54px auto 0px;
}

.ts-container__variant {
	background: #8092a4;
	line-height: 14px;
	padding: 16px 32px;
	cursor: pointer;
}

.ts-container__variant:nth-child(1) {
	margin-bottom: 2px;
}

.ts-container__variant-active {
	background: #97a6b5;
}

.ts-container__variant span {
	font-size: 13px;
	color: #fff;
	text-align: center;
}

.ts-container__variant-active span {
	color: #3B5777 !important;
	font-weight: bold;
}

.ts-marker_container {
	position: absolute;
	top: 51px;
	left: 65px;
	display: block;
	padding: 10px 10px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQxQzFBRTBFODM2MTFFNEIwQTU4REY0ODg0NEJEODgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQxQzFBRTFFODM2MTFFNEIwQTU4REY0ODg0NEJEODgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NDFDMUFERUU4MzYxMUU0QjBBNThERjQ4ODQ0QkQ4OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NDFDMUFERkU4MzYxMUU0QjBBNThERjQ4ODQ0QkQ4OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pmii9bMAAAG+SURBVHjapFS/T8JQEH5tVCIUNpQWwu6PpSAkGmZNHPwDTJwNk+yuOuuG7pq46+AfQIy1Il3ElYQmJTrySyRpvVffiweWUvVLvoS7d+/j3vXuhIOjM/IfnB7uj9iiT2wWeALUgR1Gnfmyky7NePgywDIw73G2xlgCPgCLwCe/DLeA2gSxcdAYrXR8vomdAqohfcY9z1oUBZJKyUSWF0gkMu8GdLt9YlmvxDQtYtsOvzcErkMtq+NPLnM7FJojqrpCotHISEqxmORSURZJrfZMBoMP6p7FJeJPVoG5r8xETzEMeqaqy24sQw6ermLBXX6STCZ8xb5FJTcWYQ8LFrhXluOBe1BRFrBZwIIZ/M9BIUlhbK5iwSH3Oo7916ERsGCde2lrBEW73cWmgQUr3Ev7LCharTdsVrDgJfeaZot0Ot2pYjSGxiJcYUHa5Y/0h23bxDBe4ELPR6wHjV13Yxl0r0kpsoEX+v13omkGSacVkkjEYfTCrL49tyTNpoXFHHb3x7ahGe4Ar3mmjYbpcgq2eXZe2+aGzWQ1wDehMXkQu522D3W28zbYSNIJWELtRb/mBVtzgRYsxx3jr/ApwACBppFC55nD2gAAAABJRU5ErkJggg==) no-repeat;
}

.ts-distribution_cabinet_auto {
	width: 130px;
	margin: 0 auto;
	border: 1px solid #c8d0d8;
	background: #8899aa;
	line-height: 14px;
	padding: 8px 0px 8px 15px;
}

.ts-distribution_cabinet_auto {
	font-size: 13px;
	color: #fff;
}

.ts-container__filter-fields {
	background: #8193a5;
	padding: 10px 0px;
}

.ts-container__filter-fields .r-st-filter__field-name {
	color: #000;
}

.ts-building-adress {
	line-height: 13px;
	padding: 2px 5px 2px 25px;
}

.ts-building-adress span {
	font-size: 13px;
	color: #fff;
}

.ts-color__01 {
	background-color: #ddc7f6 !important;
}

.ts-color__02 {
	background-color: #69daff !important;
}

.ts-color__03 {
	background-color: #f5e49e !important;
}

.ts-color__04 {
	background-color: #80e7b8 !important;
}

.ts-building-list__show {
	margin-top: 5px;
	text-align: center;
}

.ts-building-list__show a {
	color: #fff;
	font-size: 13px;
	font-weight: bold;
}

.ts-building-list__show a:hover {
	text-decoration: none;
}

/**/
.ts-container__check-list u {
	text-decoration: none;
	font-size: 13px;
	color: #fff;
}

.ts-container__check-list input[type="checkbox"] {
	display: none;
}

.ts-container__check-list input[type="checkbox"] + label {
	cursor: pointer;
}

.ts-container__check-list input[type="checkbox"].b-rp-schedule_check + label span {
	display: inline-block;
	width: 13px;
	height: 13px;
	vertical-align: middle;
	cursor: pointer;
}

.ts-container__check-list input[type="checkbox"].b-rp-schedule_check + label span {
	background: #fff;
	position: relative;
	left: 0;
	top: -1px;
}

.ts-container__check-list input[type="checkbox"].b-rp-schedule_check:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjdCNEQ2NzFDRDQ5MTFFNDgxNTZBRDFGMzJEMUMzNzMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjdCNEQ2NzJDRDQ5MTFFNDgxNTZBRDFGMzJEMUMzNzMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyN0I0RDY2RkNENDkxMUU0ODE1NkFEMUYzMkQxQzM3MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyN0I0RDY3MENENDkxMUU0ODE1NkFEMUYzMkQxQzM3MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtNlWP0AAAB2SURBVHjaYvz//z8DMYCFCDXeQBzBADIRD3YC4q9AHI9PkSUQfwbibLDzgAQnFkVGQPwBiEtgYiBiPxAXICnSBuJXQNyArBlE2ALxFyBOBWIVIH4KxD3otsAYrkD8DWrSDCBmxKUQhP2AeDY2RSDMSGyAAwQYAFS75jWN+08GAAAAAElFTkSuQmCC) top no-repeat;
	background-position: 2px 1px;
}

/**/
.rp-parameters {
	margin-top: 55px;
	padding: 0px 0px 0px 8px;
}

.r-st-filter__calendar {
	font-size: 14px;
	color: #000;
	width: 134px;
	margin-left: 5px;
	padding: 8px 5px;
	margin-bottom: 10px;
	border: none;
	background: #fff;
	-webkit-appearance: none;
}

.r-st-filter__calendar::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
}

/*RIGHT PANEL*/
.sj-ts-container__lessons,
.sj-ts-container__assessment,
.sj-container_variants {
	margin-top: 0px;
	position: relative;
}

.sj-ts-container__lessons .ts-container__variant,
.sj-ts-container__assessment .ts-container__variant,
.sj-container_variants .ts-container__variant {
	text-align: center;
	margin-bottom: 2px;
}

.sj-ts-container__lessons .ts-marker_container,
.sj-container_variants .ts-marker_container,
.sj-ts-container__assessment .ts-marker_container:nth-child(1) {
	top: 37px;
}

.sj-ts-container__assessment .ts-marker_container:nth-child(2) {
	top: 113px;
}

.sj-ts-container__lessons .ts-marker_container:nth-child(2) {
	top: 85px;
}

.sj-all_lessons {
	color: #39b3ae;
}

.sj-all_selected_lessons {
	color: #fff;
}

/*END RIGHT PANEL*/
/*end left panel*/
