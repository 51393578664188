body.white-theme .student-progress-all-marks .filter-title,
body.white-theme .student-progress-all-marks .filter-field-name {
    color: #000;
}

body.white-theme .student-progress-all-marks .filter-container-variant {
    background: #000;
    color: #fff;
}

body.white-theme .student-progress-all-marks .filter-container-variant.active {
    background: #fff;
    color: #000 !important;
    border: 1px solid #000;
}

body.white-theme .student-progress-all-marks .help-buttons ul > li {
    background: #000;
}

body.white-theme .student-progress-all-marks {
    background: #fff;
}