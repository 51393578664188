.student-journal-mark-wrapper {
    border-bottom: none;
    padding: 5px;
    position: relative;
    line-height: 13px;
}

.student-journal-mark-wrapper:hover {
    background: #d7f0ef;
}

.student-journal-mark {
    text-align: center;
    font-size: 15px;
    color: #454856;
    cursor: default;
}

.student-journal-mark.bold-mark {
    font-weight: bold;
}

.student-journal-mark-weight {
    font-size: 10px !important;
    position: absolute !important;
    top: 15px;
}

.student-journal-mark .criterial-mark-icon {
    display: block;
    width: 20px;
    height: 20px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjZDOTNGQjcxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjZDOTNGQjgxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNkM5M0ZCNTE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNkM5M0ZCNjE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvzqPbAAAABUSURBVHjaYvz//z8DNQETA5UB1Q1kQeZ0dXVhKCgrKxucXv4PxYMsDLEB9HAlFKbEupDoIKC/l0kNgkGTU3CG6eDPy0OstEECjCTyERKDvsQGCDAABpIVJlUI0EQAAAAASUVORK5CYII=) no-repeat;
    background-position: 4px 0px;
}

.student-journal-mark-info {
    position: absolute;
    width: 200px;
    padding: 5px 2px;
    border-top: 5px solid #39b3ae;
    box-shadow: 0 0 5px #cfd4d7;
    background: #fff;
    z-index: 1;
    left: 71px;
    top: 100%;
    line-height: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 12px;
}