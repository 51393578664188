

.b-matrix-table__list table.b-matrix-table__class-unit-name tbody tr > td:first-of-type {
	border-right: 1px solid #dadcdc;
	border-left: 1px solid #dadcdc;
}

table.b-matrix-table__class-unit-name tbody tr > td {
	border-top: 1px solid #dadcdc;
}


/*icon*/
.b-control__matrix {
	left: 208px;
	width: 100px;
}

/*end icon*/

