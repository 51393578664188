/*   СПИСОК ДЕТЕЙ КЛАССА   */

.classunit-students-table {
	width: 100%;
	position: relative;
	border-collapse: collapse;
}

.classunit-students-table-header {
	background-color: #ebf2f5;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	vertical-align: middle;
}

.classunit-students-table-header th,
.classunit-students-table-body td {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	border-right: 1px solid #cfd4d7;
}

.classunit-students-table-body td {
	border-width: 1px;
}

.classunit-students-table-header th:last-child,
.classunit-students-table-body td:last-child {
	border-right: none;
}

.classunit-students-table-header-cols th,
.classunit-students-table-body td {
	text-align: left;
	padding: 3px 0 3px 3px;
}

.classunit-students-table-header-cols th:nth-child(1) {
	width: 20px;
}

.classunit-students-table-header-cols th:nth-child(2) {
	width: 161px;
}

.classunit-students-table-header-cols th:nth-child(3) {
	width: 104px;
}

.classunit-students-table-header-cols th:nth-child(4) {
	width: 156px;
}

.classunit-students-table-header-cols th:nth-child(5) {
	width: 161px;
}

.classunit-students-table-header-cols th:nth-child(6) {
	width: 104px;
}

.classunit-students-table-header-cols th:nth-child(7) {
	width: 104px;
}

.classunit-students-table-header-cols th:last-child {
	width: 162px;
}

.classunit-students-table-body {
	font-size: 12px;
	line-height: 13px;
	color: #454856;
	font-weight: normal;
}

.classunit-students-table-body .deleted-student {
	color: #929292;
}

.classunit-students-table-body .deleted-student:hover {
	background: none !important;
}

.classunit-students-clear,
.classunit-missing-clear {
	clear: both !important;
	padding: 0;
	margin: 0;
	width: 100% !important;
}

.classunit-students-parents-table {
	width: 100%;
	height: 100%;
	border-collapse: collapse;
	padding: 0;
	margin-bottom: -1px;
	min-height: 62px;
}

.long-word-break {
	white-space: pre-line;
	white-space: -o-pre-wrap;
	white-space: -moz-pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
}

.classunit-students-parents-table tr td {
	height: 100%;
}

.classunit-students-parents-table tr:first-child td {
	border-top: none !important;
}

.classunit-students-parents-table tr td:nth-child(1) {
	width: 161px;
}

.classunit-students-parents-table tr td:nth-child(2) {
	width: 104px;
}

.classunit-students-parents-table tr td:nth-child(3) {
	width: 104px;
	white-space: pre-line;
	white-space: -o-pre-wrap;
	white-space: -moz-pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
}

.classunit-students-parents-table tr td:nth-child(4) {
	width: 162px;
	white-space: pre-line;
	white-space: -o-pre-wrap;
	white-space: -moz-pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
}

.classunit-students-clear-padding {
	padding: 0 !important;
	border-top: none !important;
	border-bottom: none !important;
}

.classunit-students-edit-button,
.classunit-students-view-button {
	cursor: pointer;
	margin: 0 3px;
	display: inline-block;
}

.classunit-students-edit-button {
	width: 19px;
	height: 19px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REQzRTYzQjhCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REQzRTYzQjlCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpERDNFNjNCNkJEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpERDNFNjNCN0JEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnzhP70AAAFDSURBVHjarJRLagJBEIadaOIjuYeZGTfewRN4DF15EVdm6RE8QryEOgSCkAMEERJ8ZNP5C/6Esq1uHbDgW0xT89HdVV2Jc65y0xChBSIDY1CAAym4lgX/M0Q5mIAVGIEU1EnKtRVz8qgQ0Qd7MAS1yO5rzJHcvinkznagV+Jee5TmllCOMFTfViTg3luTnU5OhCyA3Es1IhTZFMzAg1qv8t9MC6VyI++eLJkj/1LmSqHGWijtkAaEvszx+04JpfqFFkqP1cvKlFB2e9TCoyx6wmfQBPOQTAmfwFfwyIw16IIWeLVkStgBi2BRGFvwSWmDx68EhGdFOWkbxgfYgHfQjjT3edv4jV1mkJiNbT29a4g+PW84DK4YDoPocFDJUrEXNb7afL+PPMXf+JKczsV5aAzYN/ADvsHy0oBNbjHxdfwKMABmJu3KHmRHFgAAAABJRU5ErkJggg==) 0 0 no-repeat;
}

.classunit-students-view-button {
	width: 19px;
	height: 18px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wgFBRkQqG7EPgAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAQwSURBVDjLNZPda5NnAMWfvnmbtvm2pK6u2qqN3ZKatGO24j6YsMG2i9WBYVdu3syCUYrBi01ZRYqgDhGtxEjB/QFiw7R3gqWUTrH1i9rkhSJsF8NhLTXB+L5J+jzPbzfx9nAuzuGcn1BK+ZRSPq11C9AB/LKwsEA6nSYWi+H3+/H7/cRisZV0Os3CwgLAB4CptW5RSm0A4g11wWUYRnhxcTFz9erVfZZlib1794pkMvljT0/PohDi/eXlZfPmzZtTMzMzIhqNilQq9SCRSHyptTYMw2gWSikf0DM5ObkshCCTySClTKyvr4eA3nrKbVrrLqVUe61Wi2WzWYQQ5HK5h0CT4zjNolKp+AuFwpJpmuU7d+4ADAINgBvYoLVuA5qBNillGGgBNk1PT2OaJoVC4XfAJ4D2kZERLl++DBCRUjbUajUTCAGt1Wr1PcdxgkAAaNNam9Vq1QVsv3TpEseOHSsDX4hHjx7N9fX1PSiXy31Ag5TSVSqVPNlslt27d+Pz+f4KhUIvBwcHyWazrK2tBQEDaLRte19vby/z8/PLYmRkhCtXrgAEAXNyctLd0dHB0NAQd+/e/dy27U/fvn37yfT09E/JZPJlR0fHai6XiwEmsDOTyfxz/PhxRDwex7KsFsAzNjbWEIlEmJ2d/Q4IFIvF/tOnTzM2NlYulUrtQGhubm4oEolw7ty5HwC/ZVnfRKNRRCAQ4MWLF+4TJ074E4kEr1698kopPVLKtkOHDiGEQAjB8PAwUkof4FlZWdna39/PyZMnB1ZXV9s9Hg+GEGI+GAzKarUqhBBCa224XK5GoOZ2u+dM0xSmaQrDMPJCiJoQQrtcrkrd+5/f7w8qpYSIRqM8ffrUBxijo6Pu7u5uZmdnPwM227b94dmzZ/8+c+bMw9evX5tAeGZm5vvOzk556tSpPYD72bNnX/f09CCGh4fJZDK/Aq5KpWLkcrnNW7ZsIZlMcu/evY/X1tZ8xWLRd//+/c79+/fT1dXF1NRUcx0p49q1a1Y6nUYUCoUDiUQCYGt91rDjODsvXLhAIpEgFArR1tbGwMAA58+fx3EcE9gopQxqrbfu2LGDx48f/ywA8+jRo+8OGQXcpVKpWWu9CdgMNK6vr5tAUCnVXiwW3YBLStl78eJFjhw5AhAWgOvJkycHPB4Pt27dWgY+Appqtdp2KWVT/S8NWmu3UipQR6f79u3bBINB8vn8b8A2Ua/jv3Hjxp9er5eJiQnevHmzB9gGBMvlcsC27RDQJKV0lcvl8PXr1/F6veRyuT+klKZSqlUAjYChlGpZWlr6KpVKEY/HmZiY+Pf58+eDUkqf4zhuy7I+HR8fX4lGo6RSKZaWlka11gHApZQyBdCqtX5Hu69Sqbjz+fz4wYMH2bVrF0IIGhsbVwYGBjh8+DCWZX1r27YH2Fir1cL1Rq3/A9J5H3cUu+1uAAAAAElFTkSuQmCC) 0 0 no-repeat;
}

.classunit-students-table-body tr:hover {
	background-color: #d7f0ef;
}

/* ОТСУТСТВУЮЩИЕ */

.classunit-missing-table-header {
	position: relative;
	background-color: #ebf2f5;
	text-align: left;
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.classunit-missing-table-detail-row .classunit-missing-table-body-column {
	border-right: 1px solid #cfd4d7;
	width: 110px;
	display: inline-block;
	padding: 10px 0;
	text-align: center;
}

.classunit-missing-table-header-column {
	border-right: 1px solid #cfd4d7;
	display: block;
	padding: 10px 0;
	text-align: center;
	text-transform: uppercase;
	float: left;
}

.classunit-missing-table-header .b-bgt-ht-rotate_row-left {
	left: 289px;
	height: 22px;
}

.classunit-missing-table-header .b-bgt-ht-rotate_row-right {
	left: 749px;
	height: 22px;
}

.classunit-missing-table-header-column.rotate-wrapper,
.classunit-missing-table-body-column.rotate-wrapper {
	width: 443px;
	position: relative;
	overflow-x: hidden;
	padding: 0;
	height: 40px;
}

.classunit-missing-table-body-column.rotate-wrapper {
	height: 33px;
}

.classunit-missing-table-header-column .rotate-content,
.classunit-missing-table-body-column .rotate-content {
	display: block;
	position: absolute;
	text-align: left;
}

.classunit-missing-table-header-column:first-child,
.classunit-missing-table-body-column:first-child {
	text-align: left;
	width: 300px;
	padding-left: 5px;
}

.classunit-missing-table-body-column.action-column {
	width: 127px;
	border-right: none;
	text-align: center;
}

.classunit-missing-table-period-item {
	width: 110px;
	display: inline-block;
	padding: 10px 0;
	border-right: 1px solid #cfd4d7;
	text-align: center;
}

.classunit-missing-table-period-item:last-child {
	border-right: none;
}

.classunit-missing-table-body {
	font-size: 12px;
	line-height: 13px;
	color: #454856;
	font-weight: normal;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.classunit-missing-table-body-column {
	border-bottom: 1px solid #cfd4d7;
	text-transform: none;
	float: left;
	border-right: 1px solid #cfd4d7;
	display: block;
	padding: 10px 0;
}

.classunit-missing-table-detail-row {
	background-color: #f7f9fa;
}

.classunit-missing-table-body .selected {
	background-color: #d7f0ef;
}

.classunit-missing-table-row a.hide-detail {
	color: #999999 !important;
}

.classunit-missing-table-row a {
	color: #39b3ae;
	cursor: pointer;
	text-decoration: none;
}

.classunit-missing-table-detail-add-missing a {
	color: #39b3ae;
	cursor: pointer;
	text-decoration: none;
	border-bottom: 1px dashed #39b3ae;
	margin-right: 15px;
}

.classunit-missing-table-detail-add-missing {
	width: 100% !important;
	text-align: center !important;
	height: 30px;
	line-height: 30px;
	padding: 1px 0 !important;
	display: block !important;
	border-bottom: 1px solid #cfd4d7;
}

.classunit-missing-table-detail-add-missing input {
	margin: 0 10px 0 4px;
	width: 120px;
	font-size: 12px;
}

.classunit-missing-table-detail-add-missing span {
	height: 20px;
	line-height: 20px;
	margin-right: 5px;
	padding: 0;
	display: inline-block;
}

.classunit-missing-table-detail-add-missing span:first-child {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUZBNjkxMUJCQ0RBMTFFNDk4RUVEOTQ4RTc5NDI2MkMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUZBNjkxMUNCQ0RBMTFFNDk4RUVEOTQ4RTc5NDI2MkMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRkE2OTExOUJDREExMUU0OThFRUQ5NDhFNzk0MjYyQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRkE2OTExQUJDREExMUU0OThFRUQ5NDhFNzk0MjYyQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pkr3dO0AAAErSURBVHjatJU7CsJAEIazPgsLjyFJLL2BCtZ6BSutvIiVdh5E8ApWvvAeii+Q+A/8K+uSjZrowkfIZObfSWZ2oryMK4qil/tcgm8AxmAHLmRHW/DNpiGYgC0YAR+UiU/blj6hZGhiry44gyEo2M5GUIE+4tt1CUpmJ9ByCcUItxgTxgnKKww/FTMEJNOJLRjwu+QdQSvB8SzP2EDudZX7YAbDPUXbSMyMGk/BDphnaMc5NTxFg/RYFbtdtYdSamn1pMc+1Jk1DN8SLgfYyjpDEY4yZCiCN9MgO/sJlXQWhc/rYG0W5fkNUq42WNjn9idt87fGTnv0mklHzxwOgw+Gw4C+vaRpoys2NcZXDRRBhW+hx5f41N+Nr7gBu2ePHcHGHrC2oPr1L+AhwAC0Ea/5keCAnwAAAABJRU5ErkJggg==) 0 0 no-repeat;
	width: 20px;
	height: 20px;
	margin-right: 0;
	cursor: pointer;
}

.classunit-missing-lessons-list-wrapper {
	position: absolute;
	width: 200px !important;
	z-index: 10;
	left: 100%;
	top: 100%;
	padding: 0 !important;
	border: none !important;
	font-size: 12px;
}

.classunit-missing-lessons-list {
	padding: 5px 0 0 0 !important;
	border-top: 5px solid #39b3ae;
	-webkit-box-shadow: 0 0 5px 0 #cfd4d7;
	-moz-box-shadow: 0 0 5px 0 #cfd4d7;
	box-shadow: 0 0 5px 0 #cfd4d7;
	background: #ffffff;
	line-height: 12px;
	width: 100% !important;
}

.classunit-missing-lessons-list-header {
	position: relative;
	font-weight: bold;
	color: #000000;
	cursor: default;
	width: 100% !important;
	padding: 5px !important;
	border: none !important;
}

.classunit-missing-lessons-list-close {
	position: absolute;
	right: 15px;
	top: 3px;
	cursor: pointer;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wgGBwM223/AmAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAGGSURBVCjPnZI7q+pQEIW/vUm0EEEExQdoBFGIqGDr37ezMhJBEYuADzSaQkHMTjK3OCie6l7u6qaYWd9iFiIit9tN4jiWb6Vp+mvOskzCMBRjjOgwDPE8D9/3+ZbWGmMMWZYBEAQB8/mc7XaLtiwLEeFyueB5HiICgIhg2zZaa4IgYLPZYFkWxWIRXSqVmEwm2LbN6XRitVoBoJRCRDgcDvi+T5ZlDAYDms0mGqBQKDAej8nlcuz3e9brNQDH4xHf98nn8wyHQ6rV6s9BefMA1+sVz/MwxlAoFHg+nwB0u10cx/lg6zRNP+HL5TLD4ZD7/c5sNmO/39PpdHAc55NVKYX+MkIphVKKv+n/8ADSNCWKIpbLJcYY2u020+kU13UREXa7HYfDgTRNf/AAHo8HnucRxzHNZpN+vw9AvV5nMBjwer1YLpecz2cArCiKWCwWGGOo1Wq4rvvBUErRaDRIkoT1ev35l35bVioVRqPRr4V3jVqtFr1ejyRJuN/v8C6sMeafC/sHoM86a+B6r2UAAAAASUVORK5CYII=) 0 0 no-repeat;
	width: 15px !important;
	height: 15px !important;
	border: none !important;
}

.classunit-missing-lessons-list-items {
	padding: 5px !important;
	margin: 0;
	list-style: none;
}

.classunit-missing-lessons-list-items li {
	border-top: 1px solid #cfd4d7;
	padding: 10px 0;
	text-align: left;
	display: block;
}

.rotate-content {
	-webkit-transition: left 0.2s ease-in-out;
	-moz-transition: left 0.2s ease-in-out;
	-ms-transition: left 0.2s ease-in-out;
	-o-transition: left 0.2s ease-in-out;
	transition: left 0.2s ease-in-out;
}

.classunit-missing-table-detail-row .classunit-missing-table-body-column.rotate-wrapper .classunit-missing-table-period-item {
	padding: 0 !important;
}

.attendances-day-cell .b-icon__close-cab__add {
	margin-bottom: -5px;
	margin-left: 10px;
	margin-right: -12px;
}

.classunit-missing-table-period-item .attendances-day-cell {
	height: 32px;
	line-height: 33px;
	padding: 0;
	margin: 0;
	border-bottom: 1px solid #cfd4d7;
	cursor: default;
}

.classunit-missing-table-period-item .attendances-day-cell:last-child {
	border-bottom: none;
}

.classunit-missing-table-period-item.attendances-col {
	display: block;
	float: left;
}

.journals-list-table {
	width: 100%;
	margin: 0;
	padding: 0;
	border-spacing: 0;
	border-collapse: collapse;
	font-size: 12px;
	line-height: 13px;
	text-align: left;
}

.journals-list-header th {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	border-right: 1px solid #cfd4d7;
	font-weight: bold;
	background-color: #ebf2f5;
	color: #6c8796;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	padding: 8px 0 8px 15px;
	vertical-align: middle;
}

.journals-list-header th:last-child {
	border-right: none;
}

.journals-list-body tr .groups-list {
	border-bottom: 1px solid #cfd4d7;
}

.journals-list-body tr .groups-list:last-child {
	border-bottom: none;
}

.journals-list-subject-name-col {
	width: 200px;
	padding-left: 15px;
}

.journals-list-group-name-col {
	width: 300px;
}

.journals-list-subject-name-col,
.journals-list-teacher-name-col,
.journals-list-group-name-col {
	padding-left: 15px;
}

.journals-list-group-name-col a,
.journals-list-teacher-name-col span,
.journals-list-subject-name-col a {
	display: inline-block;
	border-bottom: 1px solid var(--font-color);
	color: var(--font-color);
	cursor: pointer;
	margin: 10px 0;
	text-decoration: none;
}

.journals-list-teacher-name-col span {
	color: #454856;
	border-bottom: none;
}

.journals-list-body td {
	border-bottom: 1px solid #cfd4d7;
}

.journals-list-body .journals-list-subject-name-col,
.journals-list-body .journals-list-group-name-col {
	border-right: 1px solid #cfd4d7;
}

.journals-list-body .journals-list-group-name-col,
.journals-list-body .journals-list-teacher-name-col {
	float: left;
}

.journals-list-body-clear {
	clear: both;
}
