.all-marks-wrapper {
    position: relative;
    margin: 20px auto;
    width: 1100px;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.all-marks-header {
    display: table;
    width: 1100px;
    background-color: #ebf2f5 !important;
    cursor: default;
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    font-weight: bold;
    color: #6c8796;
}

.all-marks-data {
    display: table;
    width: 1100px;
    color: #454856;
}

.all-marks-header.fix-top {
    position: fixed;
    top: 0;
    left: 50%;
    background-color: #ffffff;
    z-index: 100;
    margin-left: -600px;
}

.table-col {
    display: table-cell;
    line-height: 14px;
    vertical-align: middle;
    border-right: 1px solid #cfd4d7;
    border-bottom: 1px solid #cfd4d7;
    box-sizing: border-box;
}

.all-marks-header .table-col {
    text-align: center;
    height: 38px;
    min-height: 38px;
}

.all-marks-data .table-col {
    text-align: left;
    min-height: 50px;
}

.all-marks-header .table-col:last-child,
.all-marks-data .table-col:last-child {
    border-right: none;
}

.table-col.subject-name {
    width: 110px;
    text-align: left;
}

.table-col.all-marks {
    width: 690px;
    position: relative;
}

.table-col.avg,
.table-col.first-half-year,
.table-col.second-half-year {
    width: 60px;
    text-align: center;
}

.all-marks-data .table-col.all-marks {
    padding: 0 !important;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 50px;
}

.all-marks-wrapper .slider-button {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 38px;
    width: 17px;
    cursor: pointer;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAD/Rn+7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg1Nzc3NENERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg1Nzc3NERERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5ODU3Nzc0QUREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5ODU3Nzc0QkREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkxI2MEAAADfSURBVHjaYvz//z/DYAZMDIMcjDqQlg60pKM7LEl1YDQQHwXiSXRw3ESoXdHEOtAJiOcBMSMQX6eDA29A7ZoHtRsVgIoZJKwLxB/+Q0A7mhwtcTvUzg9QN8DlkBXJAPFjqMIlQMxIRwcyQu38D3WDDLoDRYH4KlTBXiBmo6PjYJgNajcIXIG6CSUNsg6i0oUdWxocTFEsiy0NDvpMAsNOQPwTqiGTDo7LhNr1E2o3ijy2cnAfECeBYh+INemQ3jSgdiVB7UYBjHiaW6Dq5zgdqzqsdjGOtgdHHTjAACDAAOCOBAWQkOCzAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-color: #dbdbde;
}

.all-marks-wrapper .slider-button.left {
    background-position: 3px 9px;
    top: 0;
    left: 0;
}

.all-marks-wrapper .slider-button.right {
    background-position: -26px 9px;
    top: 0;
    right: 0;
    left: initial;
}

.all-marks-data .br-text {
    white-space: pre-line;
    white-space: -o-pre-wrap;
    white-space: -moz-pre-wrap;
    word-wrap: break-word;
}

.bold-mark {
    font-weight: bold;
}

.subject-periods-wrapper {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    border-collapse: collapse;
    cursor: default;
    -webkit-transition: left 0.2s ease-in-out;
    -moz-transition: left 0.2s ease-in-out;
    -ms-transition: left 0.2s ease-in-out;
    -o-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out;
}

.subject-periods-table {
    display: table;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    -webkit-transition: left 0.2s ease-in-out;
    -moz-transition: left 0.2s ease-in-out;
    -ms-transition: left 0.2s ease-in-out;
    -o-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out;
}

.subject-period {
    display: table-cell;
    position: relative;
    padding: 5px;
    width: 325px;
    min-height: 51px;
    border-right: 1px solid #cfd4d7;
    vertical-align: top;
    box-sizing: border-box;
}

.subject-period.selected {
    background-color: var(--menu-color-01);
}

.module-title {
    height: 21px;
    box-sizing: border-box;
}

.subject-period:hover .module-title {
    border-bottom: 1px solid #cfd4d7;
}

.module-title .module-date {
    font-weight: bold;
    margin-left: 10px;
}

.module-marks {
    position: relative;
    padding-right: 34px;
    padding-top: 2px;
}

.module-marks-row span {
	  margin-right: 13px;
    position: relative;
    min-width: 20px;
    display: inline-block;
    line-height: 20px;
}

.module-marks-row span sub {
    font-size: 9px;
}

.module-marks-row.final-mark {
    position: absolute;
    bottom: 10px;
    width: 315px;
    margin-top: 2px;
    padding-top: 4px;
    border-top: 1px solid #cfd4d7;
    line-height: 14px;
}

.module-marks-row .icon-graph001 {
    display: inline-block;
    margin-bottom: -5px;
    margin-left: -8px;
    width: 20px;
    height: 20px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjZDOTNGQjcxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjZDOTNGQjgxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNkM5M0ZCNTE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNkM5M0ZCNjE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvzqPbAAAABUSURBVHjaYvz//z8DNQETA5UB1Q1kQeZ0dXVhKCgrKxucXv4PxYMsDLEB9HAlFKbEupDoIKC/l0kNgkGTU3CG6eDPy0OstEECjCTyERKDvsQGCDAABpIVJlUI0EQAAAAASUVORK5CYII=) no-repeat;
    background-position: 4px 0;
}

.mark-value.final-mark {
    position: absolute;
    right: -13px;
    color: red;
    font-weight: bold;
    font-size: 16px;
}

.module-avg-mark {
    position: absolute;
    top: 3px;
    right: 0;
    color: var(--font-color);
    font-weight: bold;
    font-size: 16px;
}

.criterial-info {
    position: absolute;
    width: 200px;
    padding: 5px 2px;
    border-top: 5px solid var(--color-button-5);
    box-shadow: 0 0 5px #cfd4d7;
    background: #fff;
    line-height: 12px;
    text-align: left;
    font-size: 12px;
    color: #454856;
    z-index: 101;
}

.all-grades-print-version {
    display: table;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    border-collapse: collapse;
    color: #454856;
    box-sizing: border-box;
}

.all-grades-print-version-subject-header div {
    border: 1px solid #cfd4d7;
    font-weight: bold;
    color: #6c8796;
}

.all-grades-print-version-subject-header div:nth-child(1) {
    width: 405px;
}

.all-grades-print-version-subject-header div:nth-child(2) {
    width: 350px;
}

.all-grades-print-version-period-final-mark {
    display: table;
    width: 404px;
    margin-bottom: 0 !important;
}

.all-grades-print-version-period-final-mark span {
    display: table-cell;
    vertical-align: middle;
    width: 202px;
    padding: 5px 0;
    font-size: 12px;
}

.all-grades-print-version-period-final-mark span:nth-child(1) {
    text-align: center;
}

.all-grades-print-version-period-final-mark span:nth-child(2) {
    text-align: left;
}

.all-grades-print-version-period-name .period-name {
    line-height: 14px;
    display: table;
    width: 404px;
}

.all-grades-print-version-period-name .period-name span {
    display: table-cell;
    vertical-align: middle;
}

.all-grades-print-version-period-name .period-name span:nth-child(1) {
    width: 324px;
}

.all-grades-print-version-period-name .period-name span:nth-child(2) {
    width: 80px;
    text-align: right;
    padding-right: 3px;
}

.all-grades-print-version-subject-header div:nth-child(3) {
    width: 100px;
}

.all-grades-print-version-periods-wrapper {
    display: table-cell;
}

.all-grades-print-version-col {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
}

.all-grades-print-version-period-name {
    width: 404px;
    border: 1px solid #cfd4d7;
    border-top: none;
}

.all-grades-print-version-period-marks .mark {
    margin-right: 15px;
    position: relative;
    min-width: 20px;
    display: inline-block;
}

.all-grades-print-version-period-marks {
    display: table-cell;
    border: 1px solid #cfd4d7;
    border-collapse: collapse;
    width: 350px;
    border-top: none;
    text-align: left;
    padding: 5px;
}

.all-grades-print-version-subject-avg {
    width: 99px;
    border: 1px solid #cfd4d7;
    border-top: none;
    border-left: none;
    box-sizing: border-box;
}

.all-grades-print-version-period-name div {
    margin: 5px 0;
}

.criterial-info div {
    margin: 0 0 5px 2px;
}

.all-marks-table-header.fixtop {
    position: fixed;
    top: 0;
    left: 50%;
    background-color: #ffffff;
    z-index: 100;
    margin-left: -600px;
}

.print_version {
    display: none;
}

.full-version {
    display: block;
}

@media print {
    header.header,
    div.b-left__sidebar,
    div.b-right__nav_sidebar,
    .b-events__right_nav,
    footer.footer, .date-period {
        display: none;
    }
    .date-period-active {
        display: block;
    }
    .b-right__sidebar, .b-container__wrap, .row-1-1, main {
        width: 100%!important;
        margin: 0;
        padding: 0;
    }

    .prd-row {
        page-break-after: auto;
        page-break-inside: avoid;
    }

    .print_version {
        display: block;
    }

    .full-version,
    .criterial-info {
        display: none;
    }

    div.content {
        width: 1357px !important;
    }

    div.b-right__sidebar {
        width: 1141px !important;
        border: none !important;
    }
}
