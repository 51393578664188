.journals_list_lm {

}

.journals_list_lm-list {
    list-style: none;
}

.journals_list_lm-item {
    margin: 5px;
    border: 1px solid white;
    display: block;
    vertical-align: baseline;
    padding: 5px;
    text-align: center;
    color: white;
    overflow: hidden;
    font-size: 16px;
}

.journals_list_lm-item_link {
    text-decoration: none;
    color: white;
    font-weight: bold;
    vertical-align: middle;
}

.journals_list_lm-item_link-container {
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: center;
    flex-direction: column;
}