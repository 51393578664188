/*events*/

.b-events__wrapper {
	width: 536px;
	float: right;
	border-left: 1px solid #dce3e7;
	border-bottom: 1px solid #dce3e7;
}

.b-events__wrapper header {
	background: #6c8796;
	height: 56px;
	float: right;
	width: 537px;
}

.b-events__add:hover,
.b-events__all:hover {
	background-color: #eff4f7;
}

.b-events__add {
	background: #fff;
	height: 55px;
	line-height: 55px;
	display: table-cell;
	cursor: pointer;
}

.b-events__placeholder {
	background: #fff;
	width: 218px;
	height: 56px;
	float: left;
}

.events-list-header-wrapper {
	display: block;
	width: 481px;
	height: 56px;
	background-color: #fff;
	position: relative;
	padding-right: 56px;
}

.events-list-header-wrapper .b-events__right_nav,
.events-list-header-wrapper .b-events__close {
	position: absolute;
	right: 0;
	top: 0;
	width: 56px;
	height: 55px;
	cursor: pointer;
}

.b-events__all {
	background: #fff;
	height: 56px;
	line-height: 56px;
	display: table-cell;
	cursor: pointer;
}

.b-events__search {
	background: #fff;
	width: 55px;
	height: 55px;
	border: 1px solid #dce3e7;
	position: absolute;
	right: 55px;
	top: 0;
	cursor: pointer;
	transition-property: width;
	transition-duration: 0.7s;
}

.b-events__search a,
.b-events__right_nav a {
	width: 27px;
	height: 27px;
	display: block;
	position: absolute;
	top: 1px;
	right: 13px;
}

.b-events__search-input {
	border: none;
	height: 55px;
	line-height: 55px;
	width: 0;
	padding: 0 10px;
	transition-property: width;
	transition-duration: 0.7s;
}

.b-events__search.opened .b-events__search-input {
	width: 220px;
}

.b-events__search.opened {
	width: 308px;
}

.b-events__add,
.b-events__all {
	font-size: 18px;
	padding: 0 10px;
}

.b-events__search a, .b-events__right_nav a {
	padding: 14px 0 0 14px;
}

.b-events__close a {
	padding: 20px 16px;
}

.b-events__all-content ul li {
	list-style: none;
	height: 60px;
	background: #fff;
}

.b-events__all-content ul li:last-child div.name, .b-events__all-content ul li:last-child div.arrow {
	border: none;
}

.b-events__all-content ul li div span {
	color: #272036;
	padding: 20px 10px;
}

.b-events__all-content > .clearfix {
	-webkit-transition: all 0.09s ease-out;
	-moz-transition: all 0.09s ease-out;
	-ms-transition: all 0.09s ease-out;
	-o-transition: all 0.09s ease-out;
	transition: all 0.09s ease-out;
}

.b-events__all-content ul li.bold div.name span {
	font-weight: bold;

}

.b-events__all-content ul li div {
	display: inline-block;
	float: left;
	height: 59px;
}

.b-events__all-content ul li div.time {
	width: 90px;
	padding-left: 20px;

}

.b-events__all-content ul li div.name {
	width: 304px;
	border-bottom: 1px solid #dce3e7;
}

.b-events__all-content ul li div.arrow .importance {
	float: none;
	vertical-align: top;
	padding: 23px 0 0 30px;
}

.b-events__all-content ul li div.name span {
	padding-left: 0;
}

.b-events__all-content ul li div.arrow {
	width: 122px;
	border-bottom: 1px solid #dce3e7;
}

.b-events__all-content ul li div.arrow i {
	margin: 20px;
	float: right;
}

.b-events__all-content ul li.double div.time i {
	position: relative;
	top: -8px;
	left: -1px;
}

.b-events__all-content ul li div.arrow i.b-i-xl {
	margin: 0 10px 0 0;
}

.b-events__all-content ul li.double div.time span {
	padding: 10px 8px;
}

.b-events__all-content ul li div.detail {
	height: auto;
	padding: 20px 0 30px 100px;

}

.b-events__all-content ul li div.detail div {
	height: auto;
}

.b-events__all-content ul li div.detail div.field {
	width: 415px;
}

.b-events__all-content ul li div.detail button.delete {
	margin: 80px 0 0;
}

.b-events__all-content ul li:hover {
	cursor: pointer;
}

.b-events__all-content ul li:hover div.main-info {
	border-color: #b1ccd7;
	background: #b1ccd7;
}

.b-events__all-content ul li:hover div span {
	color: #fff;
}

.b-events__add-content {
	padding: 20px 25px 0 20px;
	width: 492px;
}

h3.gray-title {
	color: #aabcc6;
	text-transform: uppercase;
	font-size: 13px;
	padding-bottom: 5px;
}

.accent-text {
	color: var(--font-color2);
	display: inline !important;
	padding: 0 !important;
}

.gray-text {
	color: #6C8796;
	font-weight: 700;
	display: inline !important;
	padding: 0 !important;
}

.underline {
	border-bottom: 1px solid var(--font-color2);
}

.btn-green {
	background: #1fc1af;
	color: #fff;
	height: 46px;
	width: 250px;
	margin-bottom: 10px;
}

.btn {
	border-radius: 2px;
	border: none;
	font-size: 15px;
	text-transform: uppercase;
	display: inline-block;
}

.btn:hover {
	cursor: pointer;
}

.adding-events {
	border-bottom: 1px solid #dce3e7;
}

.adding-events .b-events__add {
	border: none;
	margin: 0;
}

.b-i-xl.circle {
	border: 2px solid black;
	background: none;
	line-height: 1px;
	padding: 7px;
	display: inline-block;
}

.b-i-xl.circle.cont {
	background: none;
	line-height: 1px;
	display: inline-block;
	position:absolute;
	margin-top:-9px;
	cursor: pointer;
}

.b-i-xl.thin {
	border: 1px solid black;
	padding: 8px;
}

.b-i-xl.circle.red {
	border-color: #fd0822;
}

.b-i-xl.circle.blue {
	border-color: #79a4fc;
}

.b-i-xl.circle.green {
	border-color: #76d775;
}

.b-i-xl.circle.red.checked {
	background-color: #fd0822;
}

.b-i-xl.circle.blue.checked {
	background-color: #79a4fc;
}

.b-i-xl.circle.green.checked {
	background-color: #76d775;
}

.b-i-xl.circle:after {
	content: ' ';
	margin-left: -6px;
}

.b-i-xl.circle.checked:after {
	content: '\2713';
	margin-left: -5px;
}

.b-i-xl.circle.checked_delete:after {
	content:"\D7";
	font-style: normal;
	padding-left: 3px;
}

.which-day input {
	width: 9px;
	border: none;
	background: transparent;
}

.events-weekday-selector ul {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 150px;
}

.events-weekday-selector li {
	display: inline-block;
	margin: 2px 2px 0 0;
	background: #f0f4f5;
	color: #000;
	cursor: pointer;
	width: 20px;
	height: 20px;
	font-size: 13px;
	text-align: center;
}

.events-weekday-selector li:hover {
	background: #1fc1af;
	color: #fff;
}

.event-edit-form-buttons {
	text-align: center;
	width: 100%;
}

.event-edit-form-buttons button {
	display: block;
	float: right;
	width: 250px;
	height: 46px;
	margin: 0 9px !important;
}

.event-editor-clear {
	clear: both;
}

.event-editor-wrapper {
	position: relative;
	padding: 20px 25px 0 20px;
	font-size: 13px;
}

.event-editor-block {
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	border-bottom: 1px solid #edeff0;
}

.upload-event-attach-link {
	text-decoration: none;
	color: #39b3ae;
	font-size: 12px;
	border-bottom: 1px dashed #39b3ae;
	cursor: pointer;
}

.event-author-detail {
	border: none !important;
	color: #a9acad;
	text-align: right;
}

.btn:disabled {
	background: #f0f4f5;
	color: #000;
}

.event-edit-name-field {
	width: 336px;
	height: 45px;
	float: left;
	background: #f0f4f5;
}

.event-edit-name-field input {
	width: 316px;
	height: 45px;
	border: none;
	background: transparent;
	color: #000;
	padding: 0 10px;
	font: 16px 'Open Sans', sans-serif;
}

.event-edit-priority-field {
	width: 140px;
	height: 45px;
	float: right;
}

.event-edit-priority-field h3 {
	font-size: 75%;
	padding: 0;
	line-height: 19px;
}

.event-edit-event-regularity-type-field {
	width: 170px;
	height: 42px;
	background: #f0f4f5;
	float: left;
	padding-top: 3px;
}

.event-edit-event-regularity-type-field select {
	width: 170px;
	height: 40px;
	line-height: 40px;
	padding: 0 0 0 8px;
	cursor: pointer;
	border: none;
	-webkit-appearance: none;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat 146px 15px;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none !important;
	color: #000;
}

.event-edit-interval-time {
	width: 290px;
	float: right;
}

.event-edit-interval-time h3 {
	font-size: 75%;
	padding: 0;
	line-height: 19px;
	margin-top: -5px;
}

.event-edit-interval-time-inputs {
	float: left;
	width: 167px;
	padding: 2px 0 2px 5px;
	cursor: pointer;
}

.event-interval-separator {
	position: absolute;
	top: 5px;
	left: 163px;
}

.event-edit-interval-all-day i {
	vertical-align: middle;
}

.event-edit-interval-all-day {
	width: 95px;
	float: left;
	cursor: pointer;
	height: 30px;
	line-height: 30px;
	padding-left: 20px;
	display: table-cell;
	vertical-align: middle;
	border: 1px solid #f0f4f5;
	border-radius: 3px;
}

.event-edit-interval-all-day:hover {
	border: 1px solid #3b5777;
}

.event-edit-interval-time-selector {
	position: relative;
}

.event-editor-wrapper .gray-bg {
	background: #f0f4f5;
	color: #000;
}

.event-editor-wrapper .green-bg {
	background: #1fc1af;
	color: #fff;
}

.event-editor-regularity-types-list,
.event-type-list {
	display: block;
	list-style: none;
	padding: 0;
}

.event-editor-regularity-types-list {
	margin: 15px 0 0 0;
}

.event-type-list li,
.event-editor-regularity-types-list li {
	display: inline-block;
	background: #f0f4f5;
	color: #000;
	cursor: pointer;
	font-size: 13px;
	text-align: center;
}

.event-editor-regularity-types-list li {
	min-width: 87px;
	padding: 12px 5px;
}

.event-editor-regularity-types-list li:hover,
.event-type-list li:hover {
	background: #1fc1af;
	color: #fff;
}

.event-type-list li {
	width: 242px;
	padding: 12px 0;
}

.event-editor-which-day {
	width: 140px;
	height: 45px;
	line-height: 45px;
	padding-left: 10px;
	float: left;
}

.event-editor-which-day input {
	border: none;
	background: transparent;
	width: 10px;
}

.events-weekday-selector {
	float: left;
	width: 150px;
}

.event-edit-datepicker {
	position: absolute;
	top: 6px;
	left: 215px;
}

.event-edit-date-end-field {
	padding-left: 10px;
	height: 33px;
	padding-top: 12px;
	float: left;
	width: 185px;
	cursor: pointer;
	margin-left: 30px;
}

.event-edit-date-end-separator {
	position: absolute;
	top: 14px;
	left: 366px;
}

.event-edit-date-end-unlimited {
	float: left;
	height: 45px;
	line-height: 45px;
	width: 100px;
	padding-left: 15px;
	cursor: pointer;
}

.event-edit-address,
.event-edit-description {
	height: 45px;
	width: 492px;
}

.event-edit-address input,
.event-edit-description input {
	width: 472px;
	height: 45px;
	line-height: 45px;
	border: none;
	background: transparent;
	color: #000;
	padding: 0 10px;
	font: 16px 'Open Sans', sans-serif;
}

.event-editor-wrapper .mrko-date-time-picker-input {
	border: none;
	background-color: #f0f4f5;
}

.event-editor-wrapper .mrko-date-time-picker-input input {
	color: #1fc1af;
	background-color: #f0f4f5;
}


div.events-list-attachments span,
div.events-list-attachments a {
	color: #00acc1 !important;
}
