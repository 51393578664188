.schedule-class-transfer-form {
    background-color: #fff;
    border: 1px solid #6f8498;
    border-top: 5px solid var(--color-button-5);
    width: 380px;
    padding: 0;
    margin: 0 0 0 -180px;
    left: 50%;
    color: #2a2a2a;
    font-size: 14px;
    position: fixed;
    top: 200px;
    z-index: 1001;
}

.schedule-class-transfer-form-title {
    border-bottom: 1px solid #cfd4d7;
    text-align: center;
    padding: 5px 0;
}

.schedule-class-transfer-form-block {
    display: table;
    margin: 10px 0;
}

.schedule-class-transfer-form-label {
    width: 150px;
    padding-left: 30px;
}

.schedule-class-transfer-form-field {
    width: 200px;
}

.schedule-class-transfer-form-field .mrko-date-time-picker-directive-wrapper {
    margin: 0;
}

.schedule-class-transfer-form-label,
.schedule-class-transfer-form-field {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.schedule-class-modal-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0.7;
    filter: alpha(opacity=70);
    background-color: #e9eeee;
}
