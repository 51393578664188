.students-to-groups-name-column {
    line-height: 14px;
}

.students-to-groups-main_box {
	width: calc(100% - 95px);
	height: 35px;
	float: left;
	padding-left: 40px;
	padding-top: 20px;
	background: #eff4f7;
}
