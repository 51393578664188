/*ДНЕВНИК ДОМАШНЕЕ ЗАДАНИЕ 2 */
.br-text {
	white-space: pre-line;
	white-space: -o-pre-wrap;
	white-space: -moz-pre-wrap;
	word-wrap: break-word;
}

.student-homework-file-upload-link,
.student-homework-file-upload-link span,
.student-homework-file-upload-link a {
	text-decoration: none;
	color: #39b3ae;
	font-size: 12px;
	border-bottom: 1px dashed #39b3ae;
	cursor: pointer;
}

/*------------------------------*/
/*УСПЕВАЕМОСТЬ ВСЕ ОЦНКИ - ГРАФИК*/
.tc-chart-js-legend {
	list-style-type: none;
}

.tc-chart-js-legend li {
	display: block;
	float: left;
	clear: both;
	padding: 10px;
	cursor: pointer;
}

.dynamic-progress-subjects-legend li {
	display: inline-block !important;
	clear: none !important;
	width: 260px;
}

.tc-chart-js-legend input[type="checkbox"] {
	display: none;
}

.tc-chart-js-legend input[type="checkbox"] + label {
	cursor: pointer;
}

.tc-chart-js-legend input[type="checkbox"] + label span {
	display: inline-block;
	width: 14px;
	height: 14px;
	vertical-align: middle;
	cursor: pointer;
	background: none !important;
	border-width: 2px;
	border-style: solid;
}

.tc-chart-js-legend input[type="checkbox"]:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAABAklEQVQokY2QrU7DcBTFf7f/lfxT0ZqmomamoqaCibkZ3gAJ4Q3QvAohQU5iSBCQLHsB4A0QY1MzTZYUQUhzL2ZNJrqPm1xxzz3nfhzhxBiNRjEwcKeQq6qKzWymqslRQVmWsZnNzGysqj9yiFwURSwiM2AM/InIZAAwHA4D4BH4WC6XD1ssbtu2IyMid4vF4l3yPA9EZApcA2pmV8DbzmSAZ+fc5Wq1QrIsuwGmO5e0wAZIt/V3EATn6/V6AyBpmp6p6itw0fPGr4hM6rr+7AABSJIkUtWXHtFt0zT3vY5EURR57+fee9vm0yEHAQjDMHLOzZ1zX2EYxkcF3TIg39f8BwSKTTdJAhlYAAAAAElFTkSuQmCC) 1px 1px no-repeat !important;
}

.tc-chart-js-legend li span {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}


/*КОНЕЦ УСПЕВАЕМОСТЬ ВСЕ ОЦНКИ - ГРАФИК*/

/* ВСЕ ОЦЕНКИ TRUE */

.bold-mark {
	font-weight: bold;
}

.mark-value.final-mark {
	position: absolute;
	right: -13px;
	color: red;
	font-weight: bold;
	font-size: 16px;
}

.criterial-info {
	position: absolute;
	width: 200px;
	padding: 5px 2px;
	border-top: 5px solid #39b3ae;
	box-shadow: 0 0 5px #cfd4d7;
	background: #fff;
	line-height: 12px;
	text-align: left;
	font-size: 12px;
	color: #454856;
	z-index: 101;
}


.criterial-info div {
	margin: 0 0 5px 2px;
}
