.b-call-schedule__title {
	margin: 0 0 15px 20px;
}

.b-call-schedule__title span {
	font-size: 13px;
	font-weight: bold;
	line-height: normal;
	color: #a8b8c4;
}

.b-csl__table table {
	border-collapse: collapse;
}

.b-csl__table table tbody:first-of-type tr td:nth-child(1) {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAATCAIAAADeafBOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REI0Rjc3MUFEMTNCMTFFNEJCMTBFMjE3N0QyOEI1MkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REI0Rjc3MUJEMTNCMTFFNEJCMTBFMjE3N0QyOEI1MkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQjRGNzcxOEQxM0IxMUU0QkIxMEUyMTc3RDI4QjUyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQjRGNzcxOUQxM0IxMUU0QkIxMEUyMTc3RDI4QjUyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhluGPUAAAAVSURBVHjaYrj/4BETAwMDNTBAgAEAyCsCxsANmFQAAAAASUVORK5CYII=) no-repeat 25px 30px;
}

.b-csl__table table tbody:last-of-type tr td:nth-child(1) {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAATCAIAAADeafBOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REI0Rjc3MUFEMTNCMTFFNEJCMTBFMjE3N0QyOEI1MkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REI0Rjc3MUJEMTNCMTFFNEJCMTBFMjE3N0QyOEI1MkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQjRGNzcxOEQxM0IxMUU0QkIxMEUyMTc3RDI4QjUyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQjRGNzcxOUQxM0IxMUU0QkIxMEUyMTc3RDI4QjUyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhluGPUAAAAVSURBVHjaYrj/4BETAwMDNTBAgAEAyCsCxsANmFQAAAAASUVORK5CYII=) no-repeat 25px 0;
	border-bottom: none;
}

.b-csl__table table tbody tr td:nth-child(1) {
	width: 190px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAATCAIAAADeafBOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REI0Rjc3MUFEMTNCMTFFNEJCMTBFMjE3N0QyOEI1MkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REI0Rjc3MUJEMTNCMTFFNEJCMTBFMjE3N0QyOEI1MkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQjRGNzcxOEQxM0IxMUU0QkIxMEUyMTc3RDI4QjUyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQjRGNzcxOUQxM0IxMUU0QkIxMEUyMTc3RDI4QjUyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhluGPUAAAAVSURBVHjaYrj/4BETAwMDNTBAgAEAyCsCxsANmFQAAAAASUVORK5CYII=) repeat-y 25px 0;
	padding: 10px 10px 10px 17px;
	border-bottom: 1px solid #DFE0E2;
	border-right: 1px solid #DFE0E2;
	border-left: 1px solid #DFE0E2;
}

.b-csl__table table tbody tr td:nth-child(1) span {
	font-size: 16px;
	color: #3c4e5b;
	margin-right: 25px;
}

.b-csl__table table tbody tr td:nth-child(2) {
	/*display: none;*/
	padding: 0 0 0 5px;
}

.b-csl__table table tbody tr td:nth-child(2) {
	border-bottom: 1px solid #DFE0E2;
}

.b-csl__table table tbody tr td:nth-child(2) span {
	font-size: 13px;
	font-weight: bold;
	line-height: normal;
	color: #a8b8c4;
}

input.b-csl__time {
	font-size: 16px;
	color: #3b3e41;
	width: 80px;
	height: 40px;
	background: #eef2f4;
	text-align: center;
	border: none;
	margin: 0 10px;
}

input.b-csl__time-btn {
	font-size: 15px;
	color: #fff;
	width: 140px;
	height: 40px;
	background: #39b3ae;
	text-align: center;
	border: none;
	margin-left: -13px;
	cursor: pointer;
}

/*------------------------*/
b.b-csl__count {
	font-size: 14px;
	font-style: normal;
	color: #576368;
	padding: 1px 9px;
	margin-right: 5px;
	width: 13px;
	background: #fff;

	border: 1px solid #DFE0E2;
	-moz-border-top-left-radius: 90px;
	-moz-border-top-right-radius: 10px;
	-moz-border-bottom-right-radius: 10px;
	-moz-border-bottom-left-radius: 90px;
	-webkit-border-radius: 90px 10px 10px 90px;
	border-radius: 90px 10px 10px 90px;
}

/*---------------------------*/

/*---------------------------*/
.b-csl__btns {
	margin-top: 20px;
}

input.b-csl__btn-big {
	font-size: 24px;
	color: #fff;
	width: 219px;
	height: 70px;
	background: #39b3ae;
	text-align: center;
	border: none;
	cursor: pointer;
}
