.report-progress-list-wrapper {
    padding: 30px;
}

.report-progress-list-wrapper .report-progress-table-header {
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    background-color: #ebf2f5;
}

.report-progress-list-wrapper .report-progress-list-column {
    display: table-cell;
    padding: 5px 5px;
    border-right: 1px solid #cfd4d7;
    vertical-align: middle;
}

.report-progress-list-wrapper .report-progress-list-column.student {
    width: 520px;
}

.report-progress-list-wrapper .report-progress-list-column.report {
    border: none;
}

.report-progress-list-wrapper .report-progress-table-header span {
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
}

.report-progress-list-wrapper .report-progress-table-body {
    border-bottom: 1px solid #cfd4d7;
}

.report-progress-list-wrapper .report-progress-table-body span {
    color: #747a80;
    font-size: 12px;
}

.report-progress-list-wrapper .report-progress-list-column a {
    color: var(--font-color) !important;
    font-size: 12px;
}

.report-progress-list-wrapper .report-progress-list-column.student .deleted {
    color: #ff0000 !important;
}

.report-progress-list-wrapper .report-progress-list-column.student .transferred {
	color: #0000ff !important;
}
