/*--------3 ЗАМЕНЫ СПИСОК ОТ 23.06.2015 ШИРОКИЙ ЭКРАН-----------------*/
.replacement-room-name {
	color: #454856;
	font-size: 12px;
	line-height: 13px;
}

.replacement-building-name {
	color: #ccc;
	font-size: 10px;
	line-height: 13px;
}

.autocomplete-wrapper {
	background-color: #fff;
	margin: -2px -104px 0;
	position: absolute;
	border: medium none;
	border-radius: 3px;
	box-shadow: 0 0 7px #cbcbcb;
	color: #2a2a2a;
	font-family: "Open Sans";
	font-size: 14px;
	/*padding: 5px;*/
	width: 250px;
	z-index: 5;
	max-height: 300px;
	overflow-x: hidden;
}

.replacement-st-filter__field ul {
	margin-left: 10px;
	width: 250px;
}

.b-rpl__item-column-replacement {
	width: 100px;
}

.replacement-st-filter__field ul li {
	color: #444b53;
	list-style-type: none;
	padding: 0 15px 0 0;
	width: 250px;
}

.autocomplete-wrapper > ul {
	margin: 0px !important;
}

.autocomplete-wrapper li {
	cursor: pointer;
	display: block;
	padding: 0 5px !important;
	width: 250px !important;
}

.autocomplete-wrapper li:hover {
	background-color: #0000ee;
	color: #fff;
}

.r-st-filter__field-without-padding {
	/*padding: 0px!important;*/
}

.b-rpl__item-column-cancellation {
	width: 392px;
	border-bottom: 2px solid #cfd4d7;
	display: table-cell;
	height: 25px;
	position: relative;
	vertical-align: middle;
	color: #e81010 !important;
	text-align: center;
}

.r-st-filter__field-without-padding input[type="text"] {
	margin: 0 8px;
	padding: 5px;
	width: 128px;
	background-color: #fff;
	border: medium none;
	border-radius: 3px;
	box-shadow: 0 0 7px #cbcbcb;
	color: #2a2a2a;
	font-family: "Open Sans";
	font-size: 14px;
}

span.b-rpl__grey {
	color: #a9abb4;
}

.b-rplcw_tbody-column_control {
	position: relative;
	text-align: center;
}

.b-rplcw_tbody-column_control .b-icon__edit {
	position: relative;
	/*top: 5px;*/
}

.b-rplcw__cancel-replacment {
	text-align: center;
}

.b-rplcw__cancel-replacment span {
	color: #e81010 !important;
}

/*--------3 ЗАМЕНЫ СПИСОК ОТ 23.06.2015 ШИРОКИЙ ЭКРАН-----------------*/
/*///////////////////////////////////////////////*/
/*--------2 ЗАМЕНЫ ОТ 23.06.2015-----------------*/
.b-rpl__wrap {
	margin-bottom: 100px;
	padding: 20px 20px;
}

.b-rpl__conteiner {
	display: block;
}

.b-rpl__table-body {
	margin-bottom: 20px;
}

.b-rpl__table-thead {
	background: #ebf2f5;
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	text-align: center;
	position: relative;
	padding: 5px 0px;
}

.b-rpl__table-thead span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

span.b-rpl__color-green {
	color: var(--font-color);
}

.b-rpl-tthc__column {
	display: table-cell;
	vertical-align: middle;
	border-bottom: 2px solid #cfd4d7;
}

.b-rpl-tthc__column:nth-child(1) {
	/*width: 365px;*/
}

.b-rpl-tthc__column:nth-child(2) {
	text-align: center;
	width: 72px;
	border-right: 1px solid #cfd4d7;
	border-left: 1px solid #cfd4d7;
}

.b-rpl-tthc__column:nth-child(3) {
	/*width: 430px;*/
}

.b-rpl-tthc__column span {
	color: #6c8796;
	font-weight: bold;
	font-size: 12px;
}

.b-rpl-tthcc__schedule,
.b-rpl-tthcc__replacement {
	text-align: center;
	padding: 2px 0px;
}

.b-rpl-tthcc__schedule-details,
.b-rpl-tthcc__replacement-details {
	border-top: 2px solid #cfd4d7;
}

.b-rpl-tthcc-sd_column-title {
	display: table-cell;
	vertical-align: middle;
	padding: 2px 0px;
	border-right: 1px solid #cfd4d7;
	box-sizing: border-box;
}

.b-rpl-tthcc-sd_column-title:nth-child(1) {
	width: 53px;
}

.b-rpl-tthcc-sd_column-title:nth-child(2) {
	width: 72px;
	text-align: center;
}

.b-rpl-tthcc-sd_column-title:nth-child(3) {
	padding-left: 5px;
	width: 113px;
}

.b-rpl-tthcc-sd_column-title:nth-child(4) {
	padding-left: 5px;
	width: 127px;
	border-right: none;
}

.b-rpl-tthcc-rd_column-title {
	display: table-cell;
	vertical-align: middle;
	padding: 2px 0px;
	border-right: 1px solid #cfd4d7;
	box-sizing: border-box;
}

.b-rpl-tthcc-rd_column-title:nth-child(1) {
	width: 115px;
	padding-left: 5px;
}

.b-rpl-tthcc-rd_column-title:nth-child(2) {
	width: 91px;
	padding-left: 5px;
}

.b-rpl-tthcc-rd_column-title:nth-child(3) {
	width: 187px;
	text-align: center;
}

.b-rpl-tthcc-rd_column-title:nth-child(4) {
	width: 42px;
	padding-left: 5px;
	border-right: none;
}

.b-rpl__table-tbody_conteiner {
	display: block;
}

.b-rpl__item-list {
	display: block;
}

.b-rpl__item-column {
	display: table-cell;
	vertical-align: middle;
	border-right: 1px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	height: 25px;
	position: relative;
	word-wrap: break-word;
	word-break: break-all;
	box-sizing: border-box;
}

.b-rpl__item-column:nth-child(1) {
	width: 53px;
}

.b-rpl__item-column:nth-child(2) {
	padding-left: 5px;
	width: 72px;
}

.b-rpl__item-column:nth-child(3) {
	padding-left: 5px;
	width: 113px;
}

.b-rpl__item-column:nth-child(4) {
	padding-left: 5px;
	width: 128px;
}

.b-rpl__item-column:nth-child(5) {
	width: 72px;
}

.b-rpl__item-column:nth-child(6) {
	width: 116px;
	padding-left: 5px;
	cursor: pointer;
}

.b-rpl__item-column:nth-child(7):not(.b-rpl__item-column-reason){
	width: 91px;
	padding-left: 5px;
	cursor: pointer;
}

.replacement-reason-icon {
	height: 20px;
	width: 20px;
	cursor: pointer;
	display: inline-block;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.replacement-reason-icon-unactive {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0EzMDUxOUQxNDE3MTFFNTg4ODhEMzRFM0U5QjUwMEUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0EzMDUxOUUxNDE3MTFFNTg4ODhEMzRFM0U5QjUwMEUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQTMwNTE5QjE0MTcxMUU1ODg4OEQzNEUzRTlCNTAwRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQTMwNTE5QzE0MTcxMUU1ODg4OEQzNEUzRTlCNTAwRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnrsECkAAABgSURBVHjaYvz//z8DNQETLollG3f9B2FixQkaSC5ghHkZn63EgCh/N0aauJAFl00wF8P4yGGITR1OA9ENJlacZpHCgi/ZEBMJRBtIyGujXh71Mi29TGmpQ7vykFoAIMAAYZ45L744TJAAAAAASUVORK5CYII=) 0 0 no-repeat;
}

.replacement-reason-icon-active {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wwRCzI0wniWhAAAAGFJREFUOMtj/P//PwM1ARMuCTfP8P9unuH/iRUnaCC5gBHmZXy2EgN2bV/JSBMXsuCyCeZiGB85DLGpw2kgusHEitMsUljwJRtiIoFoAwl5bdTLo16mpZcpLXVoVx5SCwAA7Sg5L+Z5MqcAAAAASUVORK5CYII=) 0 0 no-repeat;
}

.replacement-reason-create-icon {
	height: 20px;
	width: 20px;
	cursor: pointer;
	display: block;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: absolute;
	top: 50%;
	transform: translate(40%, -50%);
}

.b-rpl__item-column:nth-child(8) {
	padding-left: 5px;
	border-right: 1px #cfd4d7;
	width: 185px;
	cursor: pointer;
}

.b-rpl__item-column:nth-child(9) {
	/*width: 42px;*/
	border-right: none;
	cursor: pointer;
}

.b-rpl__item-column span {
	font-size: 12px;
	line-height: 13px;
	color: #454856;
}

.b-rpl__item-column-group {
	border-bottom: 1px solid #cfd4d7;
	padding: 2px 0px;
	padding-left: 5px;
}

.b-rpl__item-column-group:last-of-type {
	border-bottom: none;
	height: 100%;
}

.b-rpl__item-column-reason {
	width: 42px;
	border-right: none!important;
	cursor: pointer!important;
	border-left: 1px solid #cfd4d7;
	padding-left: 0!important;
}

.b-rpl__item-column-reason textarea,
.b-rpl__item-column-reason-textarea {
	width: 268px;
	height: 110px;
	resize: none;
}

.b-rpl__item-column-reason a,
.b-rpl__item-column-reason-a {
	color: #0088CC;
	border-bottom: 1px dashed #0088CC;
	font-size: 13px;
}


/*-----check-------------*/
.b-rpl__item-control input[type="checkbox"] {
	display: none;
}

.b-rpl__item-control input[type="checkbox"] + label {
	cursor: pointer;

}

.b-rpl__item-control input[type="checkbox"] + label span {
	display: inline-block;
	width: 72px;
	height: 25px;
	vertical-align: middle;
	cursor: pointer;
}

.b-rpl__item-control input[type="checkbox"] + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYzODY3QjZGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYzODY3QjdGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NjM4NjdCNEYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NjM4NjdCNUYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuirhYcAAAEZSURBVHja7NQ9S0JRHMdxlVxEglwcjBuBSCC4CQpNQTVI6JCBi74EfRU6ubg6Ci4OSS0KRQ8GNvsS7isQBcEHuH4vHEHilPd6lRzOgQ8H7oXfef67DcNwOW0e1w7a4YQcJdstRwH9TPZ/l3OKV4S3DTnHJ65Q3SbkAj0R9I2C3ZAY3hES/Q2GdkLieEMQHaQw/rmx5jT7OJMEXOIFATwijYnsdCpIiA2LrP2/RhfHaOIBs9+O2NygL2j4QBR3eIYPdeSxkN5Y0Y9wi7YY3TwBP7yooQTDymWbiNGfcCICzGUW/wpYn8mqTXGPBgYoW3qAkm9z5DaNbuXt2Cp3blUe91RjdV13FKBpmqqxqsaqGqtqrKqx8rYUYAAMRWAd03EUCAAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 27px -19px;
}

.b-rpl__item-control input[type="checkbox"]:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYzODY3QjZGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYzODY3QjdGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NjM4NjdCNEYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NjM4NjdCNUYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuirhYcAAAEZSURBVHja7NQ9S0JRHMdxlVxEglwcjBuBSCC4CQpNQTVI6JCBi74EfRU6ubg6Ci4OSS0KRQ8GNvsS7isQBcEHuH4vHEHilPd6lRzOgQ8H7oXfef67DcNwOW0e1w7a4YQcJdstRwH9TPZ/l3OKV4S3DTnHJ65Q3SbkAj0R9I2C3ZAY3hES/Q2GdkLieEMQHaQw/rmx5jT7OJMEXOIFATwijYnsdCpIiA2LrP2/RhfHaOIBs9+O2NygL2j4QBR3eIYPdeSxkN5Y0Y9wi7YY3TwBP7yooQTDymWbiNGfcCICzGUW/wpYn8mqTXGPBgYoW3qAkm9z5DaNbuXt2Cp3blUe91RjdV13FKBpmqqxqsaqGqtqrKqx8rYUYAAMRWAd03EUCAAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 27px 2px;
}

/*------end check---------*/
.b-rpl__list-teachers-conteiner {
	position: absolute;
	z-index: 1;
	padding: 5px;
	background: #fff;
	border-top: 2px solid var(--color-button-5);
	width: 270px;

	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=90, Color=#333333)";
	-moz-box-shadow: 0px 0px 3px #333333;
	-webkit-box-shadow: 0px 0px 3px #333333;
	box-shadow: 0px 0px 3px #333333;
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=90, Color=#333333);
}

.b-rpl__list-teachers-conteiner-teachers {
	left: 0px;
	top: 100%;
}

.b-rpl__list-teachers-conteiner-rooms {
	left: 0px;
	top: 100%;
}

.b-rpl__list-teachers-conteiner-groups {
	left: 0px;
	top: 100%;
}

.b-rpl__ltc-head {
	position: relative;
}

.b-rpl__ltc-head span {
	font-size: 14px;
	font-weight: bold;
}

.b-rpl__ltc-head .i_icon_close-big {
	pointer-events: all;
	position: absolute;
	right: 0;
	top: 2px;
}

.i_icon_close-big {
	cursor: pointer;
	display: inline-block;
	height: 16px;
	width: 16px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDBGMzQzQjVFRjMxMTFFNEJENzZDQ0QzQUM5MjgxNEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDBGMzQzQjZFRjMxMTFFNEJENzZDQ0QzQUM5MjgxNEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMEYzNDNCM0VGMzExMUU0QkQ3NkNDRDNBQzkyODE0QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMEYzNDNCNEVGMzExMUU0QkQ3NkNDRDNBQzkyODE0QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgjL8iIAAACFSURBVHjapFNbDoAgDJvcgZ2Iy8uvfHgbRDN0xgGNLOkPaxv2InpiITw+3FCwFnhAfHKiaK5w8pALtoHJmUvCjfonrBItEy1OFoc7hKG4ZwKLLZNdAIu1SRVWI1PsGgaZJmKqBKuJPDMBJIcRehy9yqM6ubXKQR482OTXMf0553v8hwADACvtQRAxi4mUAAAAAElFTkSuQmCC) no-repeat;
}

.b-rpl__item-select-group {
	width: 270px;
	margin: 10px 0px;
	pointer-events: all;
}

select.b-rpl__item-select-list {
	border: 1px solid #cfd4d7;
	/**/
	cursor: pointer;
	-webkit-appearance: none;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==);
	filter: hue-rotate(var(--icon-hue-rotate));
	background-position: 245px 9px;
	background-repeat: no-repeat;
	-moz-appearance: none;
	text-indent: 0.01px;
	text-overflow: '';
	-ms-appearance: none;
	appearance: none !important;
	/**/
	font-size: 12px;
	color: #454856;
	width: 269px;
	height: 30px;
	padding: 5px 20px 5px 5px;
}

.b-rpl__list-teachers {
	width: 270px;
	height: 130px;
	overflow-y: scroll;
}

.b-ktp-metagroup-list dd {
	padding: 5px 0px;
	font-size: 13px;
	color: #454856;
	line-height: 14px;
}

/*--------END 2 ЗАМЕНЫ ОТ 23.06.2015-----------------*/
/*///////////////////////////////////////////////*/
/*--------1 ОУ-ЗАМЕНА ОТ 20.03.2015--------------*/
.b-replacement__body {
	margin: 0 18px 100px 18px;
	width: 900px;
}

.day-wrap {
	border-bottom: 1px solid #eef2f3;
	font-size: 17px;
	font-weight: bold;
	margin-left: 22px;
	margin-right: 16px;
	padding: 15px 0;
}

div.b-rt__container {
	padding-top: 35px;
	padding-bottom: 30px;
	width: 441px;
	float: left;
	border-right: 1px solid #eef2f3;
	border-bottom: 1px solid #eef2f3;
}

div.b-rt__container:nth-child(even) {
	border-right: none;
}

.b-replacement_teachers {
	margin: 0 5px 0 22px;
	width: 400px;
}

.b-rt__teachers span {
	display: inline-block;
}

.b-rt__teachers span {
	color: #000;
	font-size: 17px;
	font-weight: bold;
	line-height: 15px;
}

.b-rt__teachers span > em {
	font-style: normal;
	font-size: 12px;
	font-weight: normal;
	color: #818387;
}

.b-rt__location {
	margin-top: 5px;
	font-size: 12px;
	font-weight: bold;
	color: #3c4e5b;
}

.b-rt__location p {
	margin-bottom: 5px;
}

.b-rt__location span {
	font-weight: normal;
	color: #818387;
}

.b-rt__location em {
	margin-right: 25px;
}

/*icon*/
.i-rt-rows {
	padding: 17px 15px 0 5px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAQCAYAAAD0xERiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkMwQjlGM0ZDRjBCMTFFNDlBMTZCOTIyRkE0M0U5QjMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkMwQjlGNDBDRjBCMTFFNDlBMTZCOTIyRkE0M0U5QjMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyQzBCOUYzRENGMEIxMUU0OUExNkI5MjJGQTQzRTlCMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyQzBCOUYzRUNGMEIxMUU0OUExNkI5MjJGQTQzRTlCMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuIo3QwAAAEvSURBVHjaYrwRZsOADyTGFjEQC5jQBdR4eNpVubkfg2gcesSA+D8Q/4OycRv2//9//je/fsmAaCwG8QDxMyibEYhfIBvIxEA8EALid0D8E0kMxUAmEgwCuegrEE+BijUiGfiSFMNALvoCxOuB+DuSuDiyK0nxpggQJ6GJvYI6CIT/szBQDv5jRIA6D48CNpXH1s9SJCmdqXBz7/j7//9lYNrqREtznUDxS4fXzdxBtGHA6Lj/89+/v7///UsHcjmg7uYA8UHiQPkHRBt2++vXTA4mpuV//v9n+P3/f+CH37/BNIgPFF9hE5SeQVJ2ghkI9BYjKOGAaFIMAgGU2AQZCMyXDMrc3LFAA5eD+Fj0TEOjEQkNW6kBjFm5m1++PMIQX3kYr8sYgRmagVoAIMAAfwt1mlNaI5MAAAAASUVORK5CYII=) no-repeat 0 7px;
}

/*end icon*/
/*--------END 1 ОУ-ЗАМЕНА ОТ 20.03.2015--------------*/

