#pass-overlay-wrap {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1002; /* перебиваем cg-busy */
	font-family: "Open Sans", ​sans-serif;
}

#pass-overlay {
	background-color: #fff;
	height: 226px;
	margin: 100px auto 0;
	width: 480px;
	padding: 20px;
	border-radius: 10px;
}

#pass-overlay h1 {
	text-align: center;
	padding-bottom: 20px;
}

#pass-overlay p {
	font-size: 13pt;
	text-align: center;
	line-height: 26px;
}

#pass-overlay #close-button {
	cursor: pointer;
	float: none;
	text-align: right;
	font-size: 26px;
}

#pass-overlay input[type="password"] {
	padding: 5px;
	width: 220px;
	background-color: #fff;
	border: medium none;
	border-radius: 3px;
	box-shadow: 0 0 7px #cbcbcb;
	color: #2a2a2a;
	font-family: "Open Sans";
	font-size: 14px;
	margin-bottom: 10px;
}