.b-decision-gusoev__thead {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-decision-gusoev__thead-column,
.b-decision-gusoev__item-column {
	display: table-cell;
	padding: 5px 5px;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
}

.b-decision-gusoev__thead-column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-decision-gusoev__thead-column:nth-child(1) {
	width: 141px;
}

.b-decision-gusoev__thead-column:nth-child(2) {
	text-align: center;
	width: 537px;
}

.b-decision-gusoev__thead-column:nth-child(3) {
	text-align: center;
	width: 109px;
}

.b-decision-gusoev__item-conteiner {
	border-bottom: 1px solid #cfd4d7;
}

.b-decision-gusoev__item-column:nth-child(1) {
	width: 141px;
}

.b-decision-gusoev__item-column:nth-child(2) {
	width: 537px;
	text-align: center;
}

.b-decision-gusoev__item-column:nth-child(3) {
	width: 109px;
	text-align: center;
}

.b-decision-gusoev__item-column span {
	color: #747a80;
	font-size: 12px;
}

.b-decision-gusoev__item-column a {
	color: #39b3ae;
	font-size: 12px;
}

.b-decision-gusoev__item-column a:hover {
	text-decoration: none;
}

.compare-left,
.compare-right {
	display: inline-block;
	max-width: 400px;
	vertical-align: top;
}

/*.compare-right {*/
/*border-left: dashed 1px #aeaeae;*/
/*}*/


.decision-tabs {
	height: 48px;
	border-bottom: 1px solid rgba(0,0,0,0.12);
}

.decision-tabs a {
	font-size: 14px;
	text-align: center;
	line-height: 24px;
	padding: 12px 24px;
	white-space: nowrap;
	position: relative;
	text-transform: uppercase;
	float: left;
	font-weight: 500;
	box-sizing: border-box;
	overflow: hidden;
	text-overflow: ellipsis;
	color: rgba(0,0,0,0.54);
	text-decoration: none;
}

.decision-tabs a.active {
	color: var(--menu-color);
	border-bottom: 3px solid var(--menu-color);
}

.decision-tabs a.active span {
	margin-bottom:-3px;
	display: block;
}
