.grade-system-titles {
    padding: 0 5px !important;
    border: 1px solid #92bfdd;
    border-bottom: none;
    box-sizing: border-box;
}

.grade-system-titles span {
    text-transform: uppercase;
    color: #79a0bd;
    font-weight: bold;
}

.dark-bg,
.dark-bg input,
.dark-bg input:disabled {
    background-color: #f0f4f5 !important;
}

.criteria-column .criterial-header {
    height: 70px;
}

.title-column .criterial-header {
    height: 71px;
}

.ta-left {
    text-align: left;
}

.ta-right {
    text-align: right;
}

.nmax-values,
.hundred-values {
    height: 67px;
}

.nmax-header,
.five-header {
    height: 31px;
}

.title-column,
.criteria-column {
    display: inline-block;
}

.criteria-column input,
.criteria-column input:disabled {
    border: none;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
}

.title-column {
    width: 167px;
}

.grade-systems-values {
    border: 1px solid #92bfdd;
    border-bottom: none;
    border-left: none;
    box-sizing: border-box;
}

.grade-systems-values .criterial-pair-values:first-child div {
    border-left: none;
}

.title-column .grade-system-titles:last-child {
    border-bottom: 1px solid #92bfdd;
}

.criteria-column {
    width: 302px;
}

.no-margin {
    margin: 0 !important;
}

.criterial-name-field {
    border-top: 1px solid #92bfdd;
    width: 100%;
}

.criterial-name-field input {
    height: 28px;
    line-height: 7px;
    text-align: center;
}

.criterial-pair-values {
    display: inline-block;
    width: 50px;
    height: 67px;
}

.criterial-pair-values div {
    border: 1px solid #92bfdd;
    border-top: none;
}

.criterial-pair-values input {
    width: 50px !important;
    height: 33px;
}

.five-cell-wrapper {
    display: inline-block;
    width: 49px;
    height: 31px;
    border-left: 1px solid #92bfdd;
}

.grade-systems-values .five-cell-wrapper:first-child {
    border-left: none;
    margin-left: 1px;
}

.five-cell-wrapper input {
    width: 49px;
    height: 31px;
}