body.black-theme .portfolio-final-mark-year-title,
body.black-theme .portfolio-final-mark-subjects {
    border-color: #fff;
}

body.black-theme .portfolio-academic-year-selector {
    color: #fff;
}

body.black-theme .portfolio-view-type-selector .tab {
    border-color: #fff !important;
    background: #000;
}

body.black-theme .portfolio-view-type-selector .tab.active {
    background: #fff;
}

body.black-theme .portfolio-view-type-selector .tab span {
    color: #fff;
}

body.black-theme .portfolio-view-type-selector .tab.active span {
    color: #000;
}

body.black-theme .b-portfolio__thead {
    border-color: #fff;
    background: #fff;
}

body.black-theme .b-portfolio__thead span {
    color: #000;
}

body.black-theme .b-portfolio__pre-thead-col span {
    color: #fff;
}

body.black-theme .b-portfolio__tbody-item-col span {
    color: #fff;
}

body.black-theme .i-icon__attach {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkE2MkM1ODUxRkVCMTFFNUExNTBDQzI2MTVCM0Q3NDkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkE2MkM1ODYxRkVCMTFFNUExNTBDQzI2MTVCM0Q3NDkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2QTYyQzU4MzFGRUIxMUU1QTE1MENDMjYxNUIzRDc0OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2QTYyQzU4NDFGRUIxMUU1QTE1MENDMjYxNUIzRDc0OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhvPSlMAAABSSURBVHjaYvz//z8DuYAFShsCMRMBtSBbrgLxT5gAExqNDzACsRYQczKQoAkZgNRrADE3OZphetTJ1QzzAtmaGUY1D0nN/0jUB1bPSEmWBAgwAO3SC6zdSxFTAAAAAElFTkSuQmCC) no-repeat;
}