.schedule-class-right-nav-select {
    width: 100% !important;
}

.schedule-class-right-nav-select label {
    color: #fff !important;
}

.schedule-class-right-nav-select md-select-value {
    background-color: #fff !important;
}