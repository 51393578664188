.attendances-notification-calendar-wrapper {
    margin: 30px auto;
    width: 665px;
    padding: 0;
}

.attendances-notification-month {
    width: 190px;
    height: 180px;
    float: left;
    margin: 15px;
    cursor: default;
    font-size: 12px;
}

.attendances-notification-month-title {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.attendances-notification-week-title {
    color: #6c8796;
    font-weight: bold;
}

.attendances-notification-month-day {
    width: 22px;
    height: 22px;
    line-height: 22px;
    float: left;
    text-align: center;
    margin: 2px;
    box-sizing: border-box;
    position: relative;
}

.attendances-notification-month-day.weekend {
    color: #ff0000;
}

.i-icon-color_holiday.holiday,
.attendances-notification-month-day.holiday {
    background-color: #dfcaff;
}

.i-icon-color_holiday.nonattendances,
.attendances-notification-month-day.nonattendances {
    background-color: #baf3bc !important;
}

.attendances-notification-month-day.nonattendances-full {
    background-color: #b8deff !important;
}

.i-icon-color_holiday.vacation,
.attendances-notification-month-day.vacation {
    background-color: #ffb3ff;
}

.attendances-notification-month-day.day {
    cursor: pointer;
}

.attendances-notification-month-day.day:hover {
    background-color: #eee;
}

.attendances-notification-month-day-lessons {
    position: absolute;
    width: 250px;
    padding: 5px 2px;
    border-top: 5px solid #39b3ae;
    box-shadow: 0 0 5px #cfd4d7;
    background: #fff;
    z-index: 100;
    left: 100%;
    top: 100%;
    line-height: 12px;
    display: none;
    text-align: left;
}

.attendances-notification-month-day:hover .attendances-notification-month-day-lessons {
    display: block;
}

.attendances-notification-month-day-lessons span {
    line-height: 15px;
}

.attendances-notification-half-day {
    border: 1px dashed #ff0000;
}

.attendances-notification-full-day {
    border: 1px solid #ff0000;
}

.attendances-notification-future-day {
    border: 1px dashed #4e4e4e !important;
}