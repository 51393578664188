.portfolio-list-wrapper {
    width: 880px;
    margin: 30px auto;
}

.portfolio-academic-year-selector {
    margin: 10px 0;
}

.porfolio-academic-year-item {
    display: inline;
    padding: 0;
    margin-right: 20px;
}

.portfolio-academic-year-selector .porfolio-academic-year-item:last-child {
    margin-right: 0;
}

.portfolio-academic-year-selector .porfolio-academic-year-item:first-child {
    margin-left: 15px;
}

.portfolio-final-mark-year-title {
    padding: 5px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.portfolio-final-mark-subjects {
    border-bottom: 1px solid #e7e7e7;
}
.portfolio-final-mark-subjects .subject-name {
    width: 300px;
    font-size: 13px;
}

.portfolio-final-mark-subjects .subject-name,
.portfolio-final-mark-subjects .mark-value {
    display: table-cell;
    vertical-align: middle;
    min-height: 40px;
}

.portfolio-final-mark-subjects .mark-value {
    text-align: center;
    width: 40px;
}

.portfolio-final-mark-subjects li {
    display: block !important;
    width: 400px  !important;
}

.portfolio-view-type-selector .tab {
    width: 439px !important;
    border-color: #39b3ae !important;
    float: left;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 12px;
    border-bottom: 5px solid #0dcfb1;
    background: #dedee1;
    cursor: pointer;
    box-sizing: border-box;
}

.portfolio-view-type-selector .tab.active {
    background: #39b3ae;
}

.portfolio-view-type-selector .tab span {
    font-size: 17px;
    color: #454856;
}

.portfolio-view-type-selector .tab.active span {
    color: #fff;
}

.b-portfolio__table {
    margin-top: 30px;
}

.b-portfolio__thead {
    background: #ebf2f5;
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    padding: 5px 0;
    text-align: center;
}

.b-portfolio__thead span {
    font-size: 14px;
    font-weight: bold;
    color: #6c8796;
}

.b-portfolio__pre-thead-col {
    display: table-cell;
    border-right: 1px solid #cfd4d7;
    border-bottom: 1px solid #cfd4d7;
    vertical-align: middle;
    padding: 5px 5px 5px 5px;
}

.b-portfolio__pre-thead-col:nth-child(1) {
    width: 170px;
}

.b-portfolio__pre-thead-col:nth-child(2) {
    width: 410px;
}

.b-portfolio__pre-thead-col:nth-child(3) {
    width: 265px;
    border-right: none;
}

.b-portfolio__pre-thead-col span {
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
}

.b-portfolio__tbody-item-col {
    display: table-cell;
    vertical-align: middle;
    padding: 5px 5px;
    border-right: 1px solid #cfd4d7;
    border-bottom: 1px solid #cfd4d7;
}

.b-portfolio__tbody-item-col:nth-child(1) {
    width: 30px;
}

.b-portfolio__tbody-item-col:nth-child(2) {
    width: 129px;
}

.b-portfolio__tbody-item-col:nth-child(3) {
    width: 410px;
}

.b-portfolio__tbody-item-col:nth-child(4) {
    width: 200px;
}

.b-portfolio__tbody-item-col:nth-child(5) {
    width: 54px;
    text-align: center;
    border-right: none;
}

.b-portfolio__tbody-item-col span {
    font-size: 12px;
    color: #6c8796;
}

.i-icon__print {
    width: 20px;
    height: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjQ3RkE0RDYxRjNFMTFFNThFNDlBNzBDMTRGNTU1NkQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjQ3RkE0RDcxRjNFMTFFNThFNDlBNzBDMTRGNTU1NkQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGNDdGQTRENDFGM0UxMUU1OEU0OUE3MEMxNEY1NTU2RCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGNDdGQTRENTFGM0UxMUU1OEU0OUE3MEMxNEY1NTU2RCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpBDS2oAAAEYSURBVHjaYvz//z8DVQGagdFA/BmI/xCJP0P1wM1iQTPfAYh/AbEnke7ZCNWzFCaAbuAnIL4HxEeINPAeVA8DuoGKQKwLxFZArA3EwUQaqA31uh8QXwbi+4wgfzMyMr4GcgSA+B0Q/wZiQSINfA/ErEAsBMQfgGaJwiIFRFggKWQmEsOABSROEJHyAy0s/pKYWD5BzWBgQhLkoSD1wfUyMVAZICebW0DsCMQLSDQjAYjPoxsIcukHUIw5OjrKNTU1EWVSXV0dw/79+1mhepmQDWQD4kwg1tHU1GSwsbEhykCQWqCB/kCmMtQMuIGrgbgVlP7u3r3LcPXqVaIMBKkFgixoelyNrXCIhCYBUnAkcuHASO3iCyDAANX/aYyOM4zKAAAAAElFTkSuQmCC) no-repeat;
    display: inline-block;
    cursor: pointer;
}

.i-icon-remove-achievement {
    padding: 5px 10px 5px 10px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkY4RUU4Q0NCRTU1MTFFNDg5Qjk5NEJEOUE1QTFBN0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkY4RUU4Q0RCRTU1MTFFNDg5Qjk5NEJEOUE1QTFBN0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RjhFRThDQUJFNTUxMUU0ODlCOTk0QkQ5QTVBMUE3RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2RjhFRThDQkJFNTUxMUU0ODlCOTk0QkQ5QTVBMUE3RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PolULJIAAAEmSURBVHjarJVLbsJAEEQZz4asuARCwc4x4ADkGHwkuAhZhGydS9lwDVZErEyVVJY6bVsh4JGeNMx0P8+fUFXVoM+SdHWEEFKwBwW4iEJtaaeRI7SgMPgTlGAHMvAiMrWx78DYRr6TLcAVrEH0wSYuUQxjF61ClKkC5l2iFvFcOdM2Iae5+c8mKXbD3F9CrU1ZT9OUMdvM76g2K4zKzayQC7wyo2WZgDPIlRRVP6vPzm5FhxUW9ReMkIJv5kmUm3p0Qq7/0Qr51ZETDsyoKi9zQh6pC+vJX+t+Z1tjp0p7SM0tyh+dcu+b0u+xefJgrxsH21292QNXL+16HN7N45A89Ti45+ugdVmCVzAUnMX27ufLid/AFziBH3EEH22imtD3X8BNgAEAF5fRuAnK5icAAAAASUVORK5CYII=) no-repeat 0 5px;
    margin-right: 5px;
    cursor: pointer;
}

.i-icon__attach {
    width: 20px;
    height: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDE1ODVGOEIxRjAyMTFFNTkwMEM4Mjk4MzM0NUQ5MTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDE1ODVGOEMxRjAyMTFFNTkwMEM4Mjk4MzM0NUQ5MTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMTU4NUY4OTFGMDIxMUU1OTAwQzgyOTgzMzQ1RDkxOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMTU4NUY4QTFGMDIxMUU1OTAwQzgyOTgzMzQ1RDkxOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkRbOWoAAACWSURBVHjaYvz//z8DuYAFRDAyMhoCKSZcimpraxk0NTX/R0VFXQVyf4LEQJbCNDDhs0FYWJiBi4uLEcjUAmJOBmI0YQEg9RpAzA13NiEAcqK5uTnDhg0bwAZ8+fJFHUifYwRJAP1sjE+zkpISg52dHZz/8eNHhnXr1p0lSjMO15xlYqAAjGoeCM3/SNQHVs9ISZYECDAA1xAsWPbzzUMAAAAASUVORK5CYII=) no-repeat;
    display: inline-block;
    cursor: pointer;
}