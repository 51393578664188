.control-forms-add-link-wrapper {
    margin: 15px 0;
    padding: 10px 0 10px 34px;
    vertical-align: middle;
    text-align: left;
}

.control-forms-icon-add {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUZBNjkxMUJCQ0RBMTFFNDk4RUVEOTQ4RTc5NDI2MkMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUZBNjkxMUNCQ0RBMTFFNDk4RUVEOTQ4RTc5NDI2MkMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRkE2OTExOUJDREExMUU0OThFRUQ5NDhFNzk0MjYyQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRkE2OTExQUJDREExMUU0OThFRUQ5NDhFNzk0MjYyQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pkr3dO0AAAErSURBVHjatJU7CsJAEIazPgsLjyFJLL2BCtZ6BSutvIiVdh5E8ApWvvAeii+Q+A/8K+uSjZrowkfIZObfSWZ2oryMK4qil/tcgm8AxmAHLmRHW/DNpiGYgC0YAR+UiU/blj6hZGhiry44gyEo2M5GUIE+4tt1CUpmJ9ByCcUItxgTxgnKKww/FTMEJNOJLRjwu+QdQSvB8SzP2EDudZX7YAbDPUXbSMyMGk/BDphnaMc5NTxFg/RYFbtdtYdSamn1pMc+1Jk1DN8SLgfYyjpDEY4yZCiCN9MgO/sJlXQWhc/rYG0W5fkNUq42WNjn9idt87fGTnv0mklHzxwOgw+Gw4C+vaRpoys2NcZXDRRBhW+hx5f41N+Nr7gBu2ePHcHGHrC2oPr1L+AhwAC0Ea/5keCAnwAAAABJRU5ErkJggg==) no-repeat;
    padding: 0;
    margin-right: 5px;
    margin-bottom: -5px;
    width: 20px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
}

.control-forms-add-link-wrapper a {
    color: #39b3ae;
    text-decoration: none;
    border-bottom: 1px dashed #39b3ae;
}

.control-forms-list-wrapper {
    width: 878px;
    margin: 0 auto;
}

.control-forms-list-wrapper .control-forms-column {
    display: table-cell;
    padding: 3px 5px;
    border-right: 1px solid #cfd4d7;
    vertical-align: middle;
}

.control-forms-list-header {
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    background-color: #ebf2f5;
}

.control-forms-list-header .control-forms-column {
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
    text-transform: uppercase;
}

.control-forms-list-body .control-forms-column {
    color: #2a2a2a;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    padding: 5px;
}

.control-forms-column.name {
    width: 455px;
}

.control-forms-column.short-name,
.control-forms-column.weight,
.control-forms-column.action
{
    width: 90px;
}

.control-forms-column.type {
    width: 100px;
}

.control-forms-column.weight {
    text-align: center;
}

.control-forms-column.action {
    border-right: none;
}

.control-forms-column.action .edit {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REQzRTYzQjhCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REQzRTYzQjlCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpERDNFNjNCNkJEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpERDNFNjNCN0JEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnzhP70AAAFDSURBVHjarJRLagJBEIadaOIjuYeZGTfewRN4DF15EVdm6RE8QryEOgSCkAMEERJ8ZNP5C/6Esq1uHbDgW0xT89HdVV2Jc65y0xChBSIDY1CAAym4lgX/M0Q5mIAVGIEU1EnKtRVz8qgQ0Qd7MAS1yO5rzJHcvinkznagV+Jee5TmllCOMFTfViTg3luTnU5OhCyA3Es1IhTZFMzAg1qv8t9MC6VyI++eLJkj/1LmSqHGWijtkAaEvszx+04JpfqFFkqP1cvKlFB2e9TCoyx6wmfQBPOQTAmfwFfwyIw16IIWeLVkStgBi2BRGFvwSWmDx68EhGdFOWkbxgfYgHfQjjT3edv4jV1mkJiNbT29a4g+PW84DK4YDoPocFDJUrEXNb7afL+PPMXf+JKczsV5aAzYN/ADvsHy0oBNbjHxdfwKMABmJu3KHmRHFgAAAABJRU5ErkJggg==) no-repeat;
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.control-forms-column.action .copy {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTU0QTZDOURENzcwMTFFNDhGODk5QUNERTk5MzZFMzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTU0QTZDOUVENzcwMTFFNDhGODk5QUNERTk5MzZFMzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NTRBNkM5QkQ3NzAxMUU0OEY4OTlBQ0RFOTkzNkUzNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NTRBNkM5Q0Q3NzAxMUU0OEY4OTlBQ0RFOTkzNkUzNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtXDd0MAAACPSURBVHjaYmSgAvj//z92cTJwBcxAGGZBM5SRBIe1YxNkooaXGRkZwRjdRf+R+IeBWIqAOVxQPd+hfGWQl3EZ+BCIo4H4GZGOvAvSix45yDyQgXKkRDQscqgShsiAhVjbccUHOQaSkpRGvTwivIxuYBqUZqWGl2cBsTGtwvYhGYUtOC9jlBCwco3cqgAgwAB2FkgeWxn7CwAAAABJRU5ErkJggg==) no-repeat;
    margin-right: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: inline-block;
}

.control-forms-column.action .remove {
    padding: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkY4RUU4Q0NCRTU1MTFFNDg5Qjk5NEJEOUE1QTFBN0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkY4RUU4Q0RCRTU1MTFFNDg5Qjk5NEJEOUE1QTFBN0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RjhFRThDQUJFNTUxMUU0ODlCOTk0QkQ5QTVBMUE3RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2RjhFRThDQkJFNTUxMUU0ODlCOTk0QkQ5QTVBMUE3RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PolULJIAAAEmSURBVHjarJVLbsJAEEQZz4asuARCwc4x4ADkGHwkuAhZhGydS9lwDVZErEyVVJY6bVsh4JGeNMx0P8+fUFXVoM+SdHWEEFKwBwW4iEJtaaeRI7SgMPgTlGAHMvAiMrWx78DYRr6TLcAVrEH0wSYuUQxjF61ClKkC5l2iFvFcOdM2Iae5+c8mKXbD3F9CrU1ZT9OUMdvM76g2K4zKzayQC7wyo2WZgDPIlRRVP6vPzm5FhxUW9ReMkIJv5kmUm3p0Qq7/0Qr51ZETDsyoKi9zQh6pC+vJX+t+Z1tjp0p7SM0tyh+dcu+b0u+xefJgrxsH21292QNXL+16HN7N45A89Ti45+ugdVmCVzAUnMX27ufLid/AFziBH3EEH22imtD3X8BNgAEAF5fRuAnK5icAAAAASUVORK5CYII=) no-repeat 0 0;
    margin-right: 0;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: inline-block;
}