.b-cert-school__thead {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-cert-school__thead-column,
.b-cert-school__item-column {
	display: table-cell;
	padding: 5px 5px;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
}

.b-cert-school__thead-column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-cert-school__thead-column:nth-child(1) {
	width: 40px;
}

.b-cert-school__thead-column:nth-child(2) {
	text-align: center;
	width: 300px;
}

.b-cert-school__thead-column:nth-child(3) {
	text-align: center;
	width: 300px;
}

.b-cert-school__thead-column:nth-child(4) {
	width: 155px;
}

.b-cert-school__thead-column:nth-child(5) {
	width: 90px;
}

.b-cert-school__thead-column:nth-child(6) {
	width: 150px;
}

.b-cert-school__thead-column:nth-child(7) {
	width: 100px;
}

.b-cert-school__thead-column:nth-child(8) {
	width: 100px;
	border-right: none;
}

.b-cert-school__item-conteiner {
	border-bottom: 1px solid #cfd4d7;
}

.b-cert-school__item-column:nth-child(1) {
	width: 40px;
}

.b-cert-school__item-column:nth-child(2) {
	width: 300px;
	text-align: center;
}

.b-cert-school__item-column:nth-child(3) {
	width: 300px;
	text-align: center;
}

.b-cert-school__item-column:nth-child(4) {
	width: 155px;
}

.b-cert-school__item-column:nth-child(5) {
	width: 90px;
}

.b-cert-school__item-column:nth-child(6) {
	width: 150px;
}

.b-cert-school__item-column:nth-child(7) {
	width: 99px;
}

.b-cert-school__item-column:nth-child(8) {
	width: 100px;
	border-right: none;
}

.b-cert-school__item-column span {
	color: #747a80;
	font-size: 12px;
}

.b-cert-school__item-column a {
	color: #39b3ae;
	font-size: 12px;
}

.b-cert-school__item-column a:hover {
	text-decoration: none;
}

/** Предметы для аттестата */

.b-cert-subjects__conteiner {
	/*width: 482px;*/
	display: inline-block;
	float: left;
}

.b-cert-subjects__thead {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	border-left: 1px solid #cfd4d7;
}

.b-cert-subjects__thead-column,
.b-cert-subjects__item-column {
	display: table-cell;
	padding: 5px 5px;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
	text-align: center;
}

.b-cert-subjects__thead-column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-cert-subjects__thead-column:nth-child(1) {
	text-align: center;
	width: 300px;
}

.b-cert-subjects__thead-column:nth-child(2) {
	text-align: center;
	width: 35px;
}

.b-cert-subjects__thead-column:nth-child(3) {
	width: 47px;
}

.b-cert-subjects__thead-column:nth-child(4) {
	width: 90px;
}

.b-cert-subjects__thead-column:nth-child(5) {
	width: 150px;
}

.b-cert-subjects__thead-column:nth-child(6) {
	width: 100px;
}

.b-cert-subjects__thead-column:nth-child(7) {
	width: 100px;
	border-right: none;
}

.b-cert-subjects__item-conteiner {
	border-bottom: 1px solid #cfd4d7;
	border-left: 1px solid #cfd4d7;
}

.b-cert-subjects__item-column:nth-child(1) {
	width: 300px;
	text-align: center;
}

.b-cert-subjects__item-column:nth-child(2) {
	width: 35px;
	text-align: center;
}

.b-cert-subjects__item-column:nth-child(3) {
	width: 47px;
}

.b-cert-subjects__item-column:nth-child(4) {
	width: 90px;
}

.b-cert-subjects__item-column:nth-child(5) {
	width: 150px;
}

.b-cert-subjects__item-column:nth-child(6) {
	width: 99px;
}

.b-cert-subjects__item-column:nth-child(7) {
	width: 100px;
	border-right: none;
}

.b-cert-subjects__item-column span {
	color: #747a80;
	font-size: 12px;
}

.b-cert-subjects__item-column a {
	color: #39b3ae;
	font-size: 12px;
}

.b-cert-subjects__item-column a:hover {
	text-decoration: none;
}

.cert_subject_select {
	border: 1px solid #aeaeae;
	/* margin-top: 8px; */
	padding-right: 25px;
	cursor: pointer;
	width: 280px;
	height: 32px;
	-ms-appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none !important;
	background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat 97% 11px;
}

.abs_center {
	position: absolute;
	font-family: "Times New Roman", Times, serif;
	text-align: center;
}
.abs_center img {
	max-height: 100%;
}

.cert-print-container {
	width: 290mm;
	height: 205mm;
	position: relative;
	background-size: contain;
}

.fill-z {
	/*border-bottom: 0.2mm solid black;*/
	/*border-top: 0.2mm solid black;*/
	margin: 5mm 2mm;
	width: 97%;
	transform: translateZ(0);
	height: 100%;
	/*background: linear-gradient(to top left, transparent 49.9%, black 0.1%, transparent 50.1%) no-repeat;*/
	/*background: url('~images/Z.svg');*/

}

.cert-print-marks .flex-row {
	width: 100%;
	align-items: flex-end;
	line-height: 1;
	font-family: "Times New Roman", Times, serif;
	min-height: 4.0mm;
	margin: 0 0 0 3mm;
	justify-content: space-between;
	flex: 0 0 auto;
}

.cert-print-marks .flex-row:first-of-type {
	margin-top: 4mm;
}

.print-instructions p {
	margin: 10px;
}

.selected-box {
	outline: 1px solid #cf0404;
}

.hidden-controls .rg-top,
.hidden-controls .rg-left,
.hidden-controls .rg-bottom,
.hidden-controls .rg-right {
	display: none;
}

.selected-box .rg-top,
.selected-box .rg-left,
.selected-box .rg-bottom,
.selected-box .rg-right {
	display: block;
}

.selected-box .rg-top span,
.selected-box .rg-left span,
.selected-box .rg-bottom span,
.selected-box .rg-right span {
	border-color: #cf0404;
}

.settings {
	width: 100%;
}
.setting-item:nth-child(2) {
	margin-left: 30px;
}
.settings-title {
	margin-top: 10px;
	margin-left: 15px;
	font-weight: bold;
	font-size: 16px;
}
.setting-filters {
	margin-top: 10px;
	margin-left: 15px;
	margin-bottom: 15px;
	align-items: flex-end;

}
.setting-item {
	display: flex;
	flex-direction: column;
}
.setting-item input {
	align-content: flex-end;
}
.settings-fixed {
	position: fixed;
	width: 1141px;
	top:0;
	z-index: 100;
	background: #fff;
}
