.lessons-header-wrapper,
.lessons-body-wrapper {
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    box-sizing: border-box;
}

.lessons-header-wrapper {
    height: 44px;
    min-height: 44px;
}

.lessons-header-wrapper .lessons-table-col {
    height: 40px;
    vertical-align: middle;
}

.lessons-page-wrapper {
    width: 1100px;
    max-width: 1100px;
    min-width: 1100px;
    margin: 0 auto;
}

.lessons-body-wrapper {
    border-top: none;
    min-height: 30px;
}

.lessons-class-unit-column {
    text-transform: uppercase;
    cursor: default;
    line-height: 15px;
    border-right: 2px solid #cfd4d7;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
    vertical-align: middle;
}

.lessons-header-wrapper .lessons-class-unit-column {
	width: 90px;
	min-width: 90px;
	max-width: 90px;
}

.lessons-body-wrapper .lessons-class-unit-column {
	width: 60px;
	min-width: 60px;
	max-width: 60px;
    border-right: none;
    color: #454856;
}

.lessons-day-column {
    border-right: 2px solid #cfd4d7;
    box-sizing: border-box;
}

.lessons-day-column.five {
    width: 205px;
    max-width: 205px;
    min-width: 205px;
}

.lessons-body-wrapper .lessons-day-column.five {
    width: 170px;
    max-width: 170px;
    min-width: 170px;
}

.lessons-day-column.six {
    width: 175px;
    max-width: 175px;
    min-width: 175px;
}

.lessons-body-wrapper .transfer-info.six {
    width: 175px !important;
    max-width: 175px !important;
    min-width: 175px !important;
}

.lessons-body-wrapper .transfer-info.five {
    width: 205px !important;
    max-width: 205px !important;
    min-width: 205px !important;
}

.lessons-body-wrapper .transfer-info .lessons-lesson-title {
    min-height: 15px;
}

.lessons-body-wrapper .lessons-day-column.six {
    width: 140px;
    max-width: 140px;
    min-width: 140px;
}

.lessons-table-col {
    display: table-cell;
    padding: 0;
    margin: 0;
}

.bell-row-number {
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    font-size: 12px;
    color: #454856;
    vertical-align: middle;
    text-align: center;
    border-left: 1px solid #cfd4d7;
    border-right: 1px solid #cfd4d7;
    border-bottom: 1px solid #cfd4d7;
    box-sizing: border-box;
}

.lessons-bell-wrapper {
    border-bottom: 1px solid #cfd4d7;
    box-sizing: border-box;
    vertical-align: middle;
}

.lessons-bell-time {
    border-right: 1px solid #cfd4d7;
    border-bottom: 1px solid #cfd4d7;
    box-sizing: border-box;
    width: 35px;
    min-width: 35px;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
    cursor: default;
}

.lessons-lesson-title {
    position: relative;
    display: block;
    border-bottom: 1px solid #cfd4d7;
    font-size: 12px;
    line-height: 15px;
    cursor: default;
    padding: 3px;
}

.lessons-bell-wrapper .lessons-lesson-title:last-child {
    border-bottom: none;
}

.lessons-empty-lesson-title {
    min-height: 30px;
    height: 30px;
}

.lessons-slider-wrapper {
    position: relative;
    display: block;
	width: 1010px;
    overflow: hidden;
}

.lessons-slider-content {
    position: relative;
    display: block;
}

.b-rotator-weeks__left,
.b-rotator-weeks__right {
    position: absolute;
    text-align: center;
    width: 29px;
    background: #dbdbde;
    padding: 11px 0;
    cursor: pointer;
    color: #fff;
    z-index: 100;
}

.b-rotator-weeks__right {
    right: 0;
}

.b-rotator-weeks__left {
    left: 0;
}

.i-icon-rotate_row-l {
    width: 20px;
    height: 20px;
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAD/Rn+7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg1Nzc3NENERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg1Nzc3NERERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5ODU3Nzc0QUREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5ODU3Nzc0QkREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkxI2MEAAADfSURBVHjaYvz//z/DYAZMDIMcjDqQlg60pKM7LEl1YDQQHwXiSXRw3ESoXdHEOtAJiOcBMSMQX6eDA29A7ZoHtRsVgIoZJKwLxB/+Q0A7mhwtcTvUzg9QN8DlkBXJAPFjqMIlQMxIRwcyQu38D3WDDLoDRYH4KlTBXiBmo6PjYJgNajcIXIG6CSUNsg6i0oUdWxocTFEsiy0NDvpMAsNOQPwTqiGTDo7LhNr1E2o3ijy2cnAfECeBYh+INemQ3jSgdiVB7UYBjHiaW6Dq5zgdqzqsdjGOtgdHHTjAACDAAOCOBAWQkOCzAAAAAElFTkSuQmCC) no-repeat 6px 0;
    cursor: pointer;
}

.i-icon-rotate_row-r {
    width: 20px;
    height: 20px;
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAD/Rn+7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg1Nzc3NENERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg1Nzc3NERERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5ODU3Nzc0QUREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5ODU3Nzc0QkREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkxI2MEAAADfSURBVHjaYvz//z/DYAZMDIMcjDqQlg60pKM7LEl1YDQQHwXiSXRw3ESoXdHEOtAJiOcBMSMQX6eDA29A7ZoHtRsVgIoZJKwLxB/+Q0A7mhwtcTvUzg9QN8DlkBXJAPFjqMIlQMxIRwcyQu38D3WDDLoDRYH4KlTBXiBmo6PjYJgNajcIXIG6CSUNsg6i0oUdWxocTFEsiy0NDvpMAsNOQPwTqiGTDo7LhNr1E2o3ijy2cnAfECeBYh+INemQ3jSgdiVB7UYBjHiaW6Dq5zgdqzqsdjGOtgdHHTjAACDAAOCOBAWQkOCzAAAAAElFTkSuQmCC) no-repeat -20px 0;
    cursor: pointer;
}

.lessons-empty-timetable {
    text-align: center;
    color: #cfd4d7;
    cursor: default;
}

.invalid-lesson {
    color: red !important;
}

.replaced-lesson {
    color: blue !important;
}

.cancel-lesson {
    color: green !important;
}

.intercepted-lesson {
	color: #7030A0 !important;
}

.overlimit-med-lesson {
	color: #FFC000 !important;
}


.schedule-item-up-ordinal,
.schedule-item-down-ordinal {
    position: absolute;
    width: 15px;
    height: 10px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    right: 26px;
    border: none;
    display: none;
}

.lessons-lesson-title:hover .schedule-item-remove,
.lessons-lesson-title:hover .schedule-item-up-ordinal,
.lessons-lesson-title:hover .schedule-item-down-ordinal {
    display: block;
}

.schedule-item-up-ordinal {
    top: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUcAsS7qEsAAABOSURBVDjLY/z48eN/BjIBEwMFYJBqjo+PJ08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGAsXLsRvM7oCfOJMxCjEZSATISfi0jiEEwkAhCEhuybrLiwAAAAASUVORK5CYII=);
    background-position: 0 0;
}

.schedule-item-down-ordinal {
    bottom: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUcAsS7qEsAAABOSURBVDjLY/z48eN/BjIBEwMFYJBqjo+PJ08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGAsXLsRvM7oCfOJMxCjEZSATISfi0jiEEwkAhCEhuybrLiwAAAAASUVORK5CYII=);
    background-position: 0 -10px;
}

.schedule-item-remove {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkY4RUU4Q0NCRTU1MTFFNDg5Qjk5NEJEOUE1QTFBN0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkY4RUU4Q0RCRTU1MTFFNDg5Qjk5NEJEOUE1QTFBN0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RjhFRThDQUJFNTUxMUU0ODlCOTk0QkQ5QTVBMUE3RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2RjhFRThDQkJFNTUxMUU0ODlCOTk0QkQ5QTVBMUE3RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PolULJIAAAEmSURBVHjarJVLbsJAEEQZz4asuARCwc4x4ADkGHwkuAhZhGydS9lwDVZErEyVVJY6bVsh4JGeNMx0P8+fUFXVoM+SdHWEEFKwBwW4iEJtaaeRI7SgMPgTlGAHMvAiMrWx78DYRr6TLcAVrEH0wSYuUQxjF61ClKkC5l2iFvFcOdM2Iae5+c8mKXbD3F9CrU1ZT9OUMdvM76g2K4zKzayQC7wyo2WZgDPIlRRVP6vPzm5FhxUW9ReMkIJv5kmUm3p0Qq7/0Qr51ZETDsyoKi9zQh6pC+vJX+t+Z1tjp0p7SM0tyh+dcu+b0u+xefJgrxsH21292QNXL+16HN7N45A89Ti45+ugdVmCVzAUnMX27ufLid/AFziBH3EEH22imtD3X8BNgAEAF5fRuAnK5icAAAAASUVORK5CYII=) no-repeat 0 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 3px;
    border: none;
    top: 6px;
    display: none;
}

.schedule-item-up-ordinal:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUeKVoxM4kAAABOSURBVDjLY7x06dJ/BjIBEwMFYJBqrqurI08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGE1NTfhtRleAT5yJGIW4DGQi5ERcGodwIgEAjV0hAQzCJM0AAAAASUVORK5CYII=);
}

.schedule-item-down-ordinal:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUeKVoxM4kAAABOSURBVDjLY7x06dJ/BjIBEwMFYJBqrqurI08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGE1NTfhtRleAT5yJGIW4DGQi5ERcGodwIgEAjV0hAQzCJM0AAAAASUVORK5CYII=);
}

.schedule-item-up-ordinal:active {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUhJpJQBCQAAABNSURBVDjLY6yoqPjPQCZgYqAADFLN7e3t5GmGacRnABMxNuIygIlYp2ITZxyNZ9IACykJo7KyEr/N6ArwiTMRoxCXgUyEnIhL4xBOJACJqh3yQVTE3gAAAABJRU5ErkJggg==) !important;
}

.schedule-item-down-ordinal:active {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUhJpJQBCQAAABNSURBVDjLY6yoqPjPQCZgYqAADFLN7e3t5GmGacRnABMxNuIygIlYp2ITZxyNZ9IACykJo7KyEr/N6ArwiTMRoxCXgUyEnIhL4xBOJACJqh3yQVTE3gAAAABJRU5ErkJggg==) !important;
}

.lessons-cell-active {
    background: var(--menu-color-02);
}

#lessons-grid {
    overflow-x: hidden;
    overflow-y: auto;
    width: 1100px;
}

.mentor-schedule-button-wrapper {
	flex-direction: row;
	justify-content: center;
	display: flex;
	margin-top: 20px;
}

.mentor-schedule-button-wrapper .download-button {
	display: flex;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	background: var(--color-button-5);
	color: #fff;
	border-radius: 3px;
	margin-right: 16px;
	cursor: pointer;
}
