.pseudolink {
	color: #53a8ad;
	border-bottom: 1px dashed #53a8ad;
	cursor: pointer;
}

.pseudolink:hover {
	/*border-bottom: none;*/
}

.tooltip-wrap {
	position: relative;
}

.tooltip {
	position: absolute;
	z-index: 5;
	background-color: #112435;
}

.tooltip-small {
	width: 220px;
	font-size: 12px;
	color: #ffffff;
}

.invisible-form-ctrl{
	display: none;
}

.md-datepicker-calendar-pane {
	z-index: 2000 !important;
}
