.grade-systems-scales table.evaluation-scale-table {
    width: auto;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
}

.grade-systems-scales table.evaluation-scale-table tbody tr td {
    border: 1px solid #92bfdd !important;
    padding: 0 !important;
    width: 233px;
    margin: 0 !important;;
}

.grade-systems-scales table.evaluation-scale-table tbody tr td:nth-child(1) {
    padding: 5px !important;
    vertical-align: middle;
    width: 155px;
}

.grade-systems-scales table.evaluation-scale-table tbody tr td:nth-child(2) {
    width: 305px;
}

.grade-systems-scales table.evaluation-scale-table tbody tr td.evaluation-scale-table-title {
    text-transform: uppercase;
    color: #79a0bd;
    font-weight: bold;
}

.evaluation-scale-table-title-column-left {
    float: left;
    width: 134px;
    height: 44px;
    padding-top: 23px;
}

.evaluation-scale-table-title-column-right {
    float: right;
    width: 20px;
    height: 67px;
}

.evaluation-scale-table-title-column-right div {
    padding: 7px 0;
}

.grade-systems-scales table.evaluation-scale-table .dark-background,
.grade-systems-scales table.evaluation-scale-table .dark-background input {
    background-color: #f0f4f5;
}

.evaluation-scale-pairs-wrapper {
    float: left;
    border-left: 1px solid #92bfdd !important;
}

.evaluation-scale-pairs-wrapper:first-child {
    border-left: none !important;
}

.evaluation-scale-pairs-wrapper .evaluation-scale-pairs-part:last-child {
    border-top: 1px solid #92bfdd !important;
}

.evaluation-scale-pairs-part {
    clear: both;
    width: 50px;
    height: 38px;
    margin: 0;
    padding: 0;
    text-align: center;
}

.evaluation-scale-pairs-part input:disabled {
    background-color: #fff;
    color: #000;
}

.evaluation-scale-pairs-wrapper input,
.grade-systems-scales table.evaluation-scale-table .criterial-col input {
    width: 100%;
    height: 100%;
    padding: 0;
}

.grade-systems-scales input {
    border: none;
    text-align: center;
}

.grade-systems-scales input:focus {
    color: #ff5a00;
}

.grade-systems-scales table.evaluation-scale-table .criterial-col {
    text-align: center;
}

.grade-systems-scales input.invalid-field {
    background-color: #ffbfbc !important;
}
