.flex-row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
}

.cert-marks-flex-head {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	font-size: 12px;
	transition: margin .1s ease-in-out
}

.cert-marks-flex-head > div {
	border-right: 1px solid #cfd4d7;
	min-width:100px;
	max-width: 100px;
	height: 40px;
	min-height: 40px;
	padding: 5px;
}


.cert-marks-flex-body {
	font-size:12px;
	transition: margin .1s ease-in-out
}

.cert-marks-flex-body > div {
	border-right: 1px solid #cfd4d7;
	border-bottom: 1px solid #cfd4d7;
	border-collapse: collapse;
	min-width:100px;
	max-width: 100px;
	padding: 5px;
	align-content: center;
	min-height: 50px;
}

.cert-marks-flex-body > div:first-child {
	/* min-width: 150px; */
}

.cert-marks-slider-nav a {
	padding: 17px 1px;
	display: block;
	text-decoration: none;
}

.cert-marks-slider-nav {
	display: inline-block;
	background: #DCDBE0;
	width: 10px;
	position: absolute;
	top:0;
}

.cert-marks-slider-nav.left {
	left: -10px;
}

.cert-marks-slider-nav.right {
	right: -10px;
}

.cert-marks-slider-container {
	overflow: hidden;
	width: 888px;
	padding-bottom: 100px;
}

.cert-marks-slider-container > .cert-marks-flex-head {
	width:10000px;
}

.cert-marks-input {
	font-size: 12px;
	color: #3b3e41;
	padding: 10px 5px;
	width: 30px;
	text-align: center;
	border: 1px solid #aeaeae;
	margin: 0 5px 0 20px;
}

.cert-marks-with-comment {
	display: flex;
	flex-direction: column;
	position: absolute;
	align-items: center;
	min-width: 200px;
	max-width: 650px;
	min-height: 32px;
	max-height: 120px;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 3px solid #546f7a;
}

.cert-marks-with-comment textarea{
	width: 100%;
	height: 100%;
	min-height: 32px;
	resize: none
}

.cert-marks-with-comment-inner{
	text-align: center;
	width: 100%;
	font-size: 18px;
	background: #eff4f7;
}


.b-pop-up__container {
	/*display: none;*/
	/*background: rgba(238, 238, 238, 0.8);*/
	/*height: 100%;*/
	position: fixed;
	/*width     : 100%;*/
	z-index: 100;
	-webkit-transition: left 150ms ease-in-out;
	-moz-transition: left 150ms ease-in-out;
	-ms-transition: left 150ms ease-in-out;
	-o-transition: left 150ms ease-in-out;
	transition: left 150ms ease-in-out;
}

.b-pop-up__container .small-popup-wrapper {
	width: 360px;
}

.b-pop-up__wrap {
	width: 549px;
	margin: 0 auto;
	position: relative;
	top: 0%;
	background: #fff;
	border-top: 5px solid var(--color-button-5);
	box-shadow: 0px 0px 5px #cfd4d7;
}

.b-pop-up__head {
	position: relative;
	height: 40px;
	border-bottom: 1px solid #cfd4d7;
}

.b-pop_up_close {
	width: 16px;
	height: 16px;
	position: absolute;
	right: 10px;
	top: 11px;
	z-index: 101;
}

.b-pop-up__title {
	width: 480px;
	padding: 10px 0px 10px 10px;
}

.b-pop-up__title span {
	font-size: 15px;
	font-weight: bold;
	color: #1c1d1e;
}

.certificate-marks-fixed {
	position: fixed;
	top:0;
	z-index: 100;
	background: #fff;
}

.certificate-marks-fixed.cert-marks-slider-nav {
	background: #DCDBE0;
}

.certificate-marks-fixed.cert-marks-flex-head {
	min-width: 228px;
}
