.b-cert-numbers__conteiner {
	/* width: 482px; */
	display: inline-block;
	float: left;
}

.b-cert-numbers__thead {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	border-left: 1px solid #cfd4d7;
}

.b-cert-numbers__thead-column,
.b-cert-numbers__item-column {
	display: table-cell;
	padding: 5px 5px;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
	text-align: center;
}

.b-cert-numbers__thead-column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-cert-numbers__thead-column:nth-child(1) {
	width: 260px;
}

.b-cert-numbers__thead-column:nth-child(2) {
	text-align: center;
	width: 80px;
}

.b-cert-numbers__thead-column:nth-child(3) {
	text-align: center;
	width: 110px;
}

.b-cert-numbers__thead-column:nth-child(4) {
	width: 110px;
}

.b-cert-numbers__thead-column:nth-child(5) {
	width: 110px;
}

.b-cert-numbers__thead-column:nth-child(6) {
	width: 180px;
}

.b-cert-numbers__thead-column:nth-child(7) {
	width: 100px;
}

.b-cert-numbers__thead-column:nth-child(8) {
	width: 100px;
	border-right: none;
}

.b-cert-numbers__item-conteiner {
	border-bottom: 1px solid #cfd4d7;
	border-left: 1px solid #cfd4d7;
}

.b-cert-numbers__item-column:nth-child(1) {
	width: 260px;
}

.b-cert-numbers__item-column:nth-child(2) {
	width: 80px;
	text-align: center;
}

.b-cert-numbers__item-column:nth-child(3) {
	width: 110px;
	text-align: center;
}

.b-cert-numbers__item-column:nth-child(4) {
	width: 110px;
}

.b-cert-numbers__item-column:nth-child(5) {
	width: 110px;
}

.b-cert-numbers__item-column:nth-child(6) {
	width: 180px;
}

.b-cert-numbers__item-column:nth-child(7) {
	width: 99px;
}

.b-cert-numbers__item-column:nth-child(8) {
	width: 100px;
	border-right: none;
}

.b-cert-numbers__item-column span {
	color: #747a80;
	font-size: 12px;
}

.b-cert-numbers__item-column a {
	color: #39b3ae;
	font-size: 12px;
}

.b-cert-numbers__item-column a:hover {
	text-decoration: none;
}