.b-staff-tb-view__wrap {
	margin-bottom: 100px;
	padding: 20px 20px;
}

.b-records_page {
	padding: 0px 0px 10px 0px;
	text-align: right;
}

.b-records_page span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-v-staff-thead {
	background: #ebf2f5;
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-v-staff-tbody__column,
.b-v-staff-thead__column {
	display: table-cell;
	vertical-align: middle;
	border-right: 1px solid #cfd4d7;
	padding: 5px 5px;
}

.b-v-staff-tbody__column:nth-child(1),
.b-v-staff-thead__column:nth-child(1) {
	width: 30px;
}

.b-v-staff-tbody__column:nth-child(2),
.b-v-staff-thead__column:nth-child(2) {
	width: 157px;
}

.b-v-staff-tbody__column:nth-child(3),
.b-v-staff-thead__column:nth-child(3) {
	width: 163px;
}

.b-v-staff-tbody__column:nth-child(4),
.b-v-staff-thead__column:nth-child(4) {
	width: 210px;
}

.b-v-staff-tbody__column:nth-child(5),
.b-v-staff-thead__column:nth-child(5) {
	width: 150px;
}

.b-v-staff-tbody__column:nth-child(6),
.b-v-staff-thead__column:nth-child(6) {
	width: 101px;
	border-right: none;
	text-align: right;

}

.b-v-staff-thead__column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-v-staff-tbody__column {
	line-height: 13px;
}

.b-v-staff-tbody__column span {
	color: #454856;
	font-size: 12px;
}

.b-v-staff-tbody__column a {
	font-size: 12px;
	color: #53a8ad;
}

.b-v-staff-tbody__column a:hover {
	text-decoration: none;
}

.b-v-staff-conteiner-item {
	border-bottom: 1px solid #cfd4d7;
}
