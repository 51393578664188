body.white-theme .b-progress__dynamics-thead {
    background: #fff;
    color: #000;
    border-color: #000;
}

body.white-theme .b-progress__dynamics-thead-column,
body.white-theme .b-progress__dynamics-cell-column-item,
body.white-theme .b-progress__dynamics-thead-column-item,
body.white-theme .b-progress__dynamics-tbody-item-column {
    border-color: #000 !important;
}

body.white-theme .b-progress__dynamics-thead-left,
body.white-theme .b-progress__dynamics-thead-right {
    background-color: #000;
    border: 1px solid #000;
    height: 78px;
}

body.white-theme .b-progress__dynamics-thead-column span,
body.white-theme .b-progress__dynamics-cell-column-item span,
body.white-theme .b-progress__dynamics-thead-column:nth-child(1) span {
    color: #000;
}

body.white-theme .b-progress__dynamics-thead-weeks-header {
    background-color: #fff;
    color: #000;
    border-color: #000;
}
body.white-theme .b-progress__dynamics-tbody-item-column span {
    color: #000;
}

body.white-theme .b-progress__dynamics-footer-wrap {
    border-top: 3px solid #39b3ae;
    background: #faf9fb;
}

body.white-theme .b-progress__dynamics-f_title {
    background: #fff;
}

body.white-theme .b-progress__dynamics-f_title span {
    color: #454856;
}

body.white-theme .b-progress__dynamics-f-graph-conteiner {
    border-bottom: 1px solid #cfd4d7;
}

body.white-theme .header-week-info,
body.white-theme .body-week-info {
    border-color: #000;
    background: #fff;
    color: #000;
}