/* REPORT MISSING DETAILS */
.mrd-header,
.mrd-row {
    color: #6c8796;
}

.mrd-row {
    height: 28px;
    /*
    line-height: 12px;
    width: 888px;
        */

}

.mrd-row:nth-last-child(1) .mrd-column {
    border-bottom: 2px solid #cfd4d7;
}

.mrd-header-column,
.mrd-column {
    border-top: 2px solid #cfd4d7;
    /*border-bottom: 2px solid #cfd4d7;*/
    border-right: 1px solid #cfd4d7;
    height: 100%;
    display: table-cell;
}

.mrd-header-column:nth-child(1),
.mrd-column:nth-child(1) {
    border-left: 2px solid #cfd4d7;
    border-right: 1px solid #cfd4d7;
    max-width: 165px;
    min-width: 165px;
    padding-left: 5px;
    width: 165px;
    vertical-align: middle;
}

.mrd-header-column:nth-child(2),
.mrd-column:nth-child(2) {
    /*
    width: 660px;
    line-height: 11px;
    */
    border-right: 1px solid #cfd4d7;
}

.mrd-header-column:nth-child(1) span,
.mrd-month-name-wrap span {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.mrd-month-name-wrap {
    height: 21px;
    margin-top: 12px;
    text-align: center;
}

.mrd-header-month {
    border-right: 1px solid #cfd4d7;
    display: table-cell;
    height: 56px;
}

.mrd-month {
    border-right: 1px solid #cfd4d7;
    display: table-cell;
    height: 22px;
}

.mrd-month-days {
    border-right: 1px solid #cfd4d7;
    display: table-cell;
    font-weight: bold;
    height: 28px;
    text-align: center;
    width: 22px;
    vertical-align: middle;
}

.mrd-month-days-clicked {
    cursor: pointer;
}

.mrd-month-days:nth-last-child(1) {
    border-right: none;
}

.mrd-days-header-wrap {
    /*border-top: 1px solid #cfd4d7;*/
    /*height: 22px;*/
}

.missing-year-table {
    display: table;
    width: 800px;
    margin: 0 auto;
    padding: 0;
}

.missing-year-table-header {
    background: #ebf2f5;
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    color: #6c8796;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

.missing-year-table .report-column {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid #cfd4d7;
    padding: 5px 0;
}

.missing-year-table .student {
    width: 296px;
}

.missing-year-table .attendances {
    width: 180px;
}

.missing-year-table .lessons,
.missing-year-table .percent {
    width: 160px;
}

.missing-year-table .percent {
    border-right: none;
}

.missing-year-table-body {
    border-bottom: 1px solid #cfd4d7;
    color: #454856;
}

.missing-year-table-body .student {
    text-align: left;
    padding-left: 5px;
}

.report-missing-filter-select:disabled {
    background-color: #d2d2d2;
}


/* END REPORT MISSING DETAILS */
