.i-icon-color_holiday.holiday {
    background-color: #dfcaff;
}

.i-icon-color_holiday.nonattendances {
    background-color: #baf3bc !important;
}

.i-icon-color_holiday.nonattendances-full {
    background-color: #b8deff !important;
}

.i-icon-color_holiday.vacation {
    background-color: #ffb3ff;
}

.i-icon-color_holiday.attendances-notification-half-day {
    border: 1px dashed #ff0000;
}

.i-icon-color_holiday.attendances-notification-full-day {
    border: 1px solid #ff0000;
}

.i-icon-color_holiday.attendances-notification-future-day {
    border: 1px dashed #4e4e4e !important;
}