@media print {
    header.header,
    div.b-left__sidebar,
    div.b-right__nav_sidebar,
    .b-events__right_nav,
    footer.footer, .date-period {
        display: none;
    }
    .date-period-active {
        display: block;
    }
    .b-right__sidebar, .b-container__wrap, .row-1-1, main {
        width: 100%!important;
        margin: 0;
        padding: 0;
    }

    .prd-row {
        page-break-after: auto;
        page-break-inside: avoid;
    }

}

.prd-row:nth-last-child(1) {
    border-bottom: none;
}

.prd-row {
    color: #6c8796;
}

.report-progress-details {
    display: table;
    width: 856px;
    margin: 20px auto;
    padding: 0;
    border-collapse: collapse;
    color: #454856;
    box-sizing: border-box;
}

.report-progress-details-subject-header div {
    border: 1px solid #cfd4d7;
    font-weight: bold;
    color: #6c8796;
}

.report-progress-details-subject-header div:nth-child(1) {
    width: 405px;
}

.report-progress-details-subject-header div:nth-child(2) {
    width: 350px;
}

.report-progress-details-period-final-mark {
    display: table;
    width: 404px;
    margin-bottom: 0 !important;
}

.report-progress-details-period-final-mark span {
    display: table-cell;
    vertical-align: middle;
    width: 202px;
    padding: 5px 0;
    font-size: 12px;
}

.report-progress-details-period-final-mark span:nth-child(1) {
    text-align: center;
}

.report-progress-details-period-final-mark span:nth-child(2) {
    text-align: left;
}

.report-progress-details-period-name .period-name {
    line-height: 14px;
    display: table;
    width: 404px;
}

.report-progress-details-period-name .period-name span {
    display: table-cell;
    vertical-align: middle;
}

.report-progress-details-period-name .period-name span:nth-child(1) {
    width: 324px;
}

.report-progress-details-period-name .period-name span:nth-child(2) {
    width: 80px;
    text-align: right;
    padding-right: 3px;
}

.report-progress-details-subject-header div:nth-child(3) {
    width: 100px;
}

.report-progress-details-periods-wrapper {
    display: table-cell;
}

.report-progress-details-col {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
}

.report-progress-details-period-name {
    width: 404px;
    border: 1px solid #cfd4d7;
    border-top: none;
}

.report-progress-details-period-marks .mark {
    margin-right: 15px;
    position: relative;
    min-width: 20px;
    display: inline-block;
}

.report-progress-details-period-marks {
    display: table-cell;
    border: 1px solid #cfd4d7;
    border-collapse: collapse;
    width: 350px;
    border-top: none;
    text-align: left;
    padding: 5px;
}

.report-progress-details-subject-avg {
    width: 99px;
    border: 1px solid #cfd4d7;
    border-top: none;
    border-left: none;
    box-sizing: border-box;
}

.report-progress-details-period-name div {
    margin: 5px 0;
}

.mark .report-progress-details-criterial-mark {
    margin-right: 7px;
}

.mark .report-progress-details-criterial-mark:last-child {
    margin-right: 0;
}