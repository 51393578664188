.b-dir-lessons__title {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-dir-lessons__list {
	margin-top: 20px;
}

.b-dll__table table {
	width: 96%;
}

.b-dll__table table tbody tr td {
	padding: 10px 5px;
}

.b-dll__table table tbody tr:nth-child(1) td {
	border-bottom: 1px solid #DFE0E2;
}

.b-dll__table table tbody tr:nth-child(2) {
	/*display: none;*/
}

.b-dll__name-lesson {
	width: 500px;
	float: left;
}

.b-dll__name-lesson span {
	font-size: 16px;
	font-weight: bold;
	color: #496f84;
}

.b-dll__controls-lesson {
	width: 100px;
	float: right;
	text-align: right;
}

.b-dll__lesson-theme {
	padding: 0 20px;
}

.b-dll__lesson-theme ul li {
	color: #0eb0a0;
}

.b-dll__lesson-theme ul li > span {
	font-size: 16px;
	line-height: normal;
	color: #496f84;
}

/*icons*/
/*.b-icon__remove {*/
	/*padding: 5px 10px 5px 10px;*/
	/*background: url('~images/i-icon_remove_black20-20.png') no-repeat 0 0;*/
	/*margin-right: 5px;*/
	/*cursor: pointer;*/
/*}*/

/*end icons*/
