body.black-theme .student-journal-filter-panel-wrapper .filter-title,
body.black-theme .student-journal-filter-panel-wrapper .filter-field-name {
    color: #000;
}

body.black-theme .student-journal-filter-panel-wrapper .filter-container-variant {
    background: #000;
    color: #fff;
}

body.black-theme .student-journal-filter-panel-wrapper .filter-container-variant.active {
    background: #fff;
    color: #000 !important;
    border: 1px solid #000;
}

body.black-theme .student-journal-filter-panel-wrapper {
    background: #fff;
}