body.black-theme a.homework-book-link,
body.black-theme .lesson-comment,
body.black-theme .homework-time,
body.black-theme .student-journal-day-lesson .column,
body.black-theme .student-journal-day .table-header div,
body.black-theme .student-journal-day .title .date,
body.black-theme .student-journal-day .table-header,
body.black-theme .student-journal-day .title
{
    background: #000 !important;
    color: #fff !important;
    border-color: #fff !important;
}

body.black-theme .dynamic-pause,
body.black-theme .dynamic-pause span,
body.black-theme .student-journal-day-lesson .column:hover,
body.black-theme .student-journal-day-lesson .column:hover span,
body.black-theme .student-journal-day-lesson .column:hover .homework-time,
body.black-theme .student-journal-day-lesson .column .additional-info,
body.black-theme .student-journal-day-lesson .column .additional-info span {
    background: #fff !important;
    color: #000 !important;
}

body.black-theme .student-journal-day-lesson .column .additional-info {
    border-color: #000 !important;
}

body.black-theme .homework-timer {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjNGNkE5MDAxRkU2MTFFNUE0MTRGQjVGMDIwN0QxNkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjNGNkE5MDExRkU2MTFFNUE0MTRGQjVGMDIwN0QxNkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCM0Y2QThGRTFGRTYxMUU1QTQxNEZCNUYwMjA3RDE2QiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCM0Y2QThGRjFGRTYxMUU1QTQxNEZCNUYwMjA3RDE2QiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlXchT0AAAENSURBVHja7NQxagJREMbxfSIp7BSshLTCktIiaMArKNa5hDmApWBjJ9tbJbAHEdJ4gS0EK0lKmyWs/8CnLuOLrCipfPCD3XkzgzijLsuy4JanFNz4/GvDEBESpJIoFl7S0GGEJaoY4kmGii2V406qf4diTLBBV+8Pnpyucib2zia28YNOLjbGo6dpR7ntcw1jzE3sGW+ehoFy43MNv9A3MYcPPbfwkrvrq+aQb4dSw9p+zVhhigE+c3dr1RxO2RR/o+GZ/Ax1LEy8oZrjipifXowtXgvu8RwVfXLv2vim/JdCU/btYXDNHu6nOkKKd/TQlJ5iqXJckYZ7ISIkapDqOdKdt87d/w+vPjsBBgCzKBsXPn6ioQAAAABJRU5ErkJggg==) no-repeat;
    background-position: 0 2px;
}

body.black-theme .student-journal-day-lesson .column:hover .homework-timer {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OThDREEyRTNGNEIxMTFFNDk5MDRFRTU0NDc5MjA3RjUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OThDREEyRTRGNEIxMTFFNDk5MDRFRTU0NDc5MjA3RjUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5OENEQTJFMUY0QjExMUU0OTkwNEVFNTQ0NzkyMDdGNSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5OENEQTJFMkY0QjExMUU0OTkwNEVFNTQ0NzkyMDdGNSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnDnFkYAAADpSURBVHjaYvz//z8DNQETA5UB1Q1kAHkZhtGAFhDPAOJ7QPwbiu9BxbRwmoHFQEYgrocasBKIA4BYHYoDoGK/oWoYiTGwC4hfA7E9lM+GxWP2UDVdhAy0AuK/QGyNpLkdiOWwGGoNVWuFz8C1QLwYTaMFEBfjiAKQ2rX4DHwLxIFomkDhtArKNgFiGyQ5kNq3yGagJxshIH6KnhCA+BEQ9wNxMBCfQZJ7CtUDByxomt8BsTQWr00DYlEgPokmLg3VgzMdYgtDfIBgGGKLZVyAqFjGlg4ZKEmHNMkpFOVlxpFXHlLdQIAAAwD5b//nZXFm4wAAAABJRU5ErkJggg==) no-repeat;
    background-position: 0px 2px;
}