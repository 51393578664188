body.white-theme .student-final-marks-table-header {
    background-color: #fff !important;
    border-color: #000 !important;
    color: #000 !important;
}

body.white-theme .student-final-marks-table-body,
body.white-theme .student-final-marks-table-header .subject-name,
body.white-theme .student-final-marks-table-header .periods-wrapper,
body.white-theme .student-final-marks-table-body .subject-name,
body.white-theme .student-final-marks-table-body .periods-wrapper,
body.white-theme .student-final-marks-table-body .year-mark,
body.white-theme .student-final-marks-table-body .periods {
    border-color: #000 !important;
    color: #000 !important;
}

body.white-theme .student-final-marks-table-header .year-mark {
    border-color: #000 !important;
}