/* logs */
.b-logs__title {
	color: #444b53;
	font-size: 19px;
	font-weight: bold;
	/*padding: 25px 35px 20px 39px;*/
	padding: 20px 25px 18px 25px;
	border-bottom: 1px solid #f0f0f0;
}

div.b-logs-body__left {
	float: left;
	width: 380px; /*40%*/
	padding: 10px 25px 10px 25px;
}

div.b-logs-body__left table {
	width: 100%;
}

div.b-logs-body__left table tr td {
	font-size: 14px;
	padding: 10px 0 10px 0;
	color: #262424;
}

div.b-logs-body__left table tr td:first-of-type {
	width: 50px;
	color: #e837d9;
	vertical-align: top;
}
