@media print {
	app-header,
    header.header,
    div.b-left__sidebar,
    div.b-right__nav_sidebar,
    .b-events__right_nav,
    footer.footer, .date-period {
        display: none;
    }

    .date-period-active {
        display: block;
    }

    .b-right__sidebar, .b-container__wrap, .row-1-1, main {
        width: 100% !important;
        margin: 0;
        padding: 0;
    }

    .b_sj-table__head-fixtop {
        position: relative !important;
    }

    .page-title {
	    border-bottom: 0!important;
    }
    .b-right__sidebar {
	    width: 100%!important;
    }
	.report-student-quality-table-header, .report-student-quality-table-body {
		width: 100%!important;
	}

    .report-student-quality-table-header-column, .report-student-quality-table-body-column {
	    width: 25%!important;
    }

}

.fixed-header {
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: #fff;
}

.report-student-quality-table-header {
    display: table;
    padding: 0;
    margin: 0;
    background: #ebf2f5;
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    border-left: 1px solid #cfd4d7;
}

.report-student-quality-table-header-column {
    display: table-cell;
    width: 228px;
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid #cfd4d7;
    padding: 5px 0;
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
}

.report-student-quality-table-body {
    display: table;
    padding: 0;
    margin: 0;
    background: none;
    border-bottom: 1px solid #cfd4d7;
    border-left: 1px solid #cfd4d7;
}

.report-student-quality-table-body-column {
    display: table-cell;
    width: 228.9px;
    min-width: 228px;
    box-sizing: border-box;
    vertical-align: top;
    text-align: center;
    border-right: 1px solid #cfd4d7;
    font-size: 12px;
    font-weight: normal;
}

.report-student-quality-table-body .student-name-block {
    line-height: 16px;
    padding: 8px 0;
    width: 227.2px;
    text-align: center;
    border-bottom: 1px solid #cfd4d7;
}

.report-student-quality-bad-mark-student,
.report-student-quality-bad-marks {
    display: table-cell;
    vertical-align: middle;
    width: 114px;
    text-align: center;
    padding: 8px 0;
}

.report-student-quality-bad-marks span {
    display: block;
    margin: 10px 0;
    line-height: 14px;
    white-space: pre-line;
    white-space: -o-pre-wrap;
    white-space: -moz-pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}

.report-student-quality-bad-mark-student {
    border-right: 1px solid #cfd4d7;
    box-sizing: border-box;
    padding: 0 5px;
}

.report-student-quality-table-body-column:nth-child(1) .student-name-block,
.report-student-quality-table-body-column:nth-child(2) .student-name-block,
.report-student-quality-table-body-column:nth-child(3) .student-name-block {
    padding: 0;
}

.one-mark-student {
	color: #049e08 !important;
}
