.report-progress-list-right-nav .filter-title {
    color: #ddeef6;
    font-size: 11px;
    font-weight: lighter;
    text-transform: uppercase;
    padding: 0 0 0 8px;
    margin-top: 0;
}

.report-progress-list-right-nav .filter-field {
    padding: 20px 0 0 0;
}

.report-progress-list-right-nav .filter-field-name {
    color: #fff;
    font-size: 14px;
    padding: 0 0 5px 10px;
}

.report-progress-list-right-nav select.filter-select {
    -webkit-appearance: none;
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat 94% 14px;
	  filter: hue-rotate(var(--icon-hue-rotate));
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    -ms-appearance: none;
    appearance: none!important;
    width: 134px;
    height: 36px;
    line-height: 1em;
    cursor: pointer;
    border: none;
    font-size: 14px;
    color: #000;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 5px 25px 5px 5px;
    outline: none;
}

.report-progress-list-right-nav select.filter-select::-ms-expand {
    display: none;
}

.report-progress-list-right-nav .filter-container-variants-wrapper {
    position: relative;
    width: 150px;
    margin: 0 auto;
}

.report-progress-list-right-nav .filter-container-variant {
    text-align: center;
    margin-bottom: 2px;
    line-height: 14px;
    padding: 16px 10px;
    cursor: pointer;
    background: #8092a4;
    font-size: 15px;
    color: #fff;
}

.report-progress-list-right-nav .filter-container-variant.active {
    background: #fff;
    color: #3b5777 !important;
    font-weight: bold;
}

.report-progress-list-right-nav .filter-container-variant-marker {
    position: absolute;
    left: 64px;
    display: block;
    padding: 10px 10px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQxQzFBRTBFODM2MTFFNEIwQTU4REY0ODg0NEJEODgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQxQzFBRTFFODM2MTFFNEIwQTU4REY0ODg0NEJEODgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NDFDMUFERUU4MzYxMUU0QjBBNThERjQ4ODQ0QkQ4OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NDFDMUFERkU4MzYxMUU0QjBBNThERjQ4ODQ0QkQ4OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pmii9bMAAAG+SURBVHjapFS/T8JQEH5tVCIUNpQWwu6PpSAkGmZNHPwDTJwNk+yuOuuG7pq46+AfQIy1Il3ElYQmJTrySyRpvVffiweWUvVLvoS7d+/j3vXuhIOjM/IfnB7uj9iiT2wWeALUgR1Gnfmyky7NePgywDIw73G2xlgCPgCLwCe/DLeA2gSxcdAYrXR8vomdAqohfcY9z1oUBZJKyUSWF0gkMu8GdLt9YlmvxDQtYtsOvzcErkMtq+NPLnM7FJojqrpCotHISEqxmORSURZJrfZMBoMP6p7FJeJPVoG5r8xETzEMeqaqy24sQw6ermLBXX6STCZ8xb5FJTcWYQ8LFrhXluOBe1BRFrBZwIIZ/M9BIUlhbK5iwSH3Oo7916ERsGCde2lrBEW73cWmgQUr3Ev7LCharTdsVrDgJfeaZot0Ot2pYjSGxiJcYUHa5Y/0h23bxDBe4ELPR6wHjV13Yxl0r0kpsoEX+v13omkGSacVkkjEYfTCrL49tyTNpoXFHHb3x7ahGe4Ar3mmjYbpcgq2eXZe2+aGzWQ1wDehMXkQu522D3W28zbYSNIJWELtRb/mBVtzgRYsxx3jr/ApwACBppFC55nD2gAAAABJRU5ErkJggg==) no-repeat;
}

.report-progress-list-right-nav .filter-container-variant-marker.first {
    top: 37px;
}

.report-progress-list-right-nav .filter-button {
    width: 125px;
    padding: 10px 0;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    text-align: center;
    margin-left: 15px;
}

.report-progress-list-right-nav .apply-button {
    background: var(--color-button-5);
    color: #fff;
}

.report-progress-list-right-nav .reset-button {
    background: #7a7a7a;
    color: #fff;
}
