/*-head table*/
.b-lt__head-table {
}

.b-lt-head-table__column {
	display: table-cell;
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	border-right: 1px solid #cfd4d7;
	padding: 10px 0px;
	vertical-align: middle;
	line-height: 14px;
}

.b-lt-head-table__column:nth-child(odd) {
	background: #f9fbfc;
}

.b-lt-head-table__column:nth-child(even) {
	background: #f0f4f5;
}

.b-lt-head-table__column:nth-child(1) {
	padding-left: 10px;
	width: 15%;
	border-left: 1px solid #cfd4d7;
}

.b-lt-head-table__column:nth-child(2) {
	text-align: center;
	width: 5%;
}

.b-lt-head-table__column:nth-child(3) {
	text-align: center;
	width: 10%;
}

.b-lt-head-table__column:nth-child(4) {
	text-align: center;
	width: 20%;
}

.b-lt-head-table__column:nth-child(5) {
	text-align: center;
	width: 10.45%;
}

.b-lt-head-table__column:nth-child(6) {
	padding: 0px 0px;
	width: 32.95%;
	vertical-align: top;
}

.b-lt-head-table__column:nth-child(7) {
	text-align: center;
	width: 2%;
}

.b-lt-head-table__column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-lt-head-table__column:nth-child(7) span {
	font-size: 12px;
	font-weight: bold;
	color: #454856;
}

/*-END head table*/

/*- head table rotate*/
.b-lt-ht__rotate-body {
	position: relative;
	display: table-cell;
	width: 373px;
	overflow: hidden;
}

.b-lt-ht__rotate-right,
.b-lt-ht__rotate-left {
	position: absolute;
	text-align: center;
	width: 17px;
	background: #dbdbde;
	padding: 23px 0px;
	cursor: pointer;
	color: #fff;
	z-index: 1;
}

.b-lt-ht__rotate-right {
	right: 0;
}

.b-lt-ht__columns-rotate {
	width: 339px;
	height: 66px;
	display: table-cell;
	position: relative;
	left: 17px;
	overflow: hidden;
}

.b-lt-ht__columns-rotate > div {
	position: absolute;
	top: 0;
	left: 0;
}

.b-lt-ht__column-rotate {
	width: 33px;
	min-width: 33px;
	padding: 25px 0px;
	display: table-cell;
	text-align: center;
	border-right: 1px solid #cfd4d7;
}

.b-lt-ht__column-rotate:last-of-type {
	border-right: none;
}

.b-lt-ht__column-rotate span {
	font-size: 15px;
	font-weight: bold;
	color: #454856;
}

/*- END head table rotate*/

/*-body table*/
.b-lt__table-table {
	display: table-cell;
}

.b-lt-body-table__column {
	display: table-cell;
	border-bottom: 2px solid #cfd4d7;
	border-right: 1px solid #cfd4d7;
	padding: 10px 0px;
	vertical-align: middle;
	line-height: 14px;
}

.b-lt-body-table__column:nth-child(odd) {
	background: #f9fbfc;
}

.b-lt-body-table__column:nth-child(even) {
	background: #fff;
}

.b-lt-body-table__column:nth-child(1) {
	padding-left: 9px;
	width: 15%;
	border-left: 1px solid #cfd4d7;
}

.b-lt-body-table__column:nth-child(2) {
	text-align: center;
	width: 5%;
}

.b-lt-body-table__column:nth-child(3) {
	text-align: center;
	width: 10%;
}

.b-lt-body-table__column:nth-child(4) {
	text-align: center;
	width: 20%;
	padding: 0px 0px;
	vertical-align: top;
	vertical-align: middle;
}

.b-lt-body-table__column:nth-child(5) {
	text-align: center;
	width: 12%;
	padding: 0px 0px;
	vertical-align: top;
	vertical-align: middle;
}

.b-lt-body-table__column:nth-child(6) {
	text-align: center;
	width: 30%;
	padding: 0px 0px;
	vertical-align: top;
	overflow: hidden;
	position: relative;
}

.b-lt-body-table__column:nth-child(7) {
	text-align: center;
	padding-right: 5px;
	width: 5%;
	min-width: 48px;
}

.b-lt-body-table__column span.red{
	display: inline;
}
.b-lt-body-table__column span {
	font-size: 14px;
	color: #646c73;
	display: block;
}

.b-lt-body-table__column:nth-child(7) span {
	font-size: 16px;
	font-weight: bold;
	color: #646c73;
}

.b-lt-body-table__column:nth-child(4) .b-lt-row__list {
	/* width: 89px; */
}

.b-lt-body-table__column:nth-child(5) .b-lt-row__list {
	/* width: 76px; */
}

.b-lt-row__list {
	text-align: left;
	padding: 5px 5px;
	line-height: 14px;
	border-bottom: 1px solid #cfd4d7;
}

.b-lt-row__list:last-of-type {
	border-bottom: none;
}

.b-lt-row__list span {
	font-size: 14px;
	color: #808387;
}

.b-lt-body__column-containers {
	position: absolute;
	top: 0;
	left: 0;
}

.b-ltb-column_container {
	width: 33px;
	min-width: 33px;
	display: table-cell;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
}

.b-ltb-column_container:last-of-type {
	border-right: none;
}

/*-END body table*/


