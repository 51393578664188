.b-contingent-tb-view__wrap {
	margin-bottom: 100px;
	padding: 20px 20px;
}

.b-records_page {
	padding: 0 0 10px;
	text-align: right;
}

.b-records_page span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-v-contingent-thead {
	background: #ebf2f5;
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-v-contingent-tbody__column,
.class-teacher-b-v-contingent-tbody__column,
.class-teacher-b-v-contingent-thead__column,
.b-v-contingent-thead__column,
.contingent-thead__column,
.contingent-tbody__column {
	display: table-cell;
	vertical-align: middle;
	border-right: 1px solid #cfd4d7;
	padding: 5px 5px;
}

.b-v-contingent-tbody__column:nth-child(1),
.b-v-contingent-thead__column:nth-child(1) {
	width: 30px;
}

.b-v-contingent-tbody__column:nth-child(2),
.b-v-contingent-thead__column:nth-child(2) {
	width: 242px;
}

.b-v-contingent-tbody__column:nth-child(3),
.b-v-contingent-thead__column:nth-child(3) {
	width: 164px;
}

.b-v-contingent-tbody__column:nth-child(4) {
	padding: 5px;
}

.b-v-legal-representative_item {
	width: 293px;
	/* padding: 5px; */
	border-bottom: 1px solid #cfd4d7;
}

.b-v-legal-representative_item:last-of-type {
	border-bottom: none;
}

.b-v-contingent-tbody__column:nth-child(4),
.b-v-contingent-thead__column:nth-child(4) {
	width: 303px;
	min-width: 303px;
}

.b-v-contingent-tbody__column:nth-child(5),
.b-v-contingent-thead__column:nth-child(5) {
	width: 85px;
	border-right: none;
}

.class-teacher-b-v-contingent-tbody__column:nth-child(1),
.class-teacher-b-v-contingent-thead__column:nth-child(1) {
	width: 30px;
}

.class-teacher-b-v-contingent-tbody__column:nth-child(2),
.class-teacher-b-v-contingent-thead__column:nth-child(2) {
	width: 25px;
	padding-left: 16px;
}

.class-teacher-b-v-contingent-tbody__column:nth-child(3),
.class-teacher-b-v-contingent-thead__column:nth-child(3) {
	width: 268px;
}

.class-teacher-b-v-contingent-tbody__column:nth-child(4),
.class-teacher-b-v-contingent-thead__column:nth-child(4) {
	width: 145px;
}

.class-teacher-b-v-contingent-tbody__column:nth-child(5),
.class-teacher-b-v-contingent-thead__column:nth-child(5) {
	width: 182px;
}

.class-teacher-b-v-contingent-tbody__column:last-child {
	border-right: none;
}

.b-v-contingent-thead__column span,
.class-teacher-b-v-contingent-thead__column span,
.contingent-thead__column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-v-contingent-tbody__column,
.class-teacher-b-v-contingent-tbody__column {
	line-height: 13px;
}

.b-v-contingent-tbody__column:nth-child(3) {
	text-align: center;
}

.b-v-contingent-tbody__column span,
.class-teacher-b-v-contingent-tbody__column span {
	color: #454856;
	font-size: 12px;
}

.b-v-contingent-tbody__column a,
.class-teacher-b-v-contingent-tbody__column a {
	font-size: 12px;
	color: var(--font-color);
}

.b-v-contingent-tbody__column a:hover,
.class-teacher-b-v-contingent-tbody__column a:hover {
	text-decoration: none;
}

.b-v-contingent-conteiner-item {
	border-bottom: 1px solid #cfd4d7;
}

.login-view .b-v-contingent-tbody__column:nth-child(1),
.login-view .b-v-contingent-thead__column:nth-child(1) {
	width: 30px;
}

.login-view .b-v-contingent-tbody__column:nth-child(2),
.login-view .b-v-contingent-thead__column:nth-child(2) {
	width: 260px;
}

.login-view .b-v-contingent-tbody__column:nth-child(3),
.login-view .b-v-contingent-thead__column:nth-child(3) {
	width: 184px;
}

.login-view .b-v-contingent-tbody__column:nth-child(4),
.login-view .b-v-contingent-thead__column:nth-child(4) {
	width: 184px;
}

.login-view .b-v-contingent-tbody__column:nth-child(5),
.login-view .b-v-contingent-thead__column:nth-child(5) {
	width: auto;
	border: none;
}

.par_cur_ass .contingent-thead__column:nth-child(1),
.par_cur_ass .contingent-tbody__column:nth-child(1) {
	width: 500px;
}

.par_cur_ass .contingent-thead__column:nth-child(2),
.par_cur_ass .contingent-tbody__column:nth-child(2) {
	width: 150px;
}

.par_cur_ass .contingent-thead__column:nth-child(3),
.par_cur_ass .contingent-tbody__column:nth-child(3) {
	width: 150px;
}

.par_cur_ass .contingent-thead__column:nth-child(4),
.par_cur_ass .contingent-tbody__column:nth-child(4) {
	border: none;
	width: 50px;
}

.text-column {
	font-size: 12px;
	line-height: 13px;
	color: #454856;
}

.b-v-contingent-thead-fixtop {
	width: 918px;
	position: fixed;
	top: 0;
	z-index: 100;
}

.group_ass .contingent-thead__column:nth-child(1),
.group_ass .contingent-tbody__column:nth-child(1) {
	width: 156px;
}

.group_ass .contingent-thead__column:nth-child(2),
.group_ass .contingent-tbody__column:nth-child(2) {
	width: 150px;
	word-break: break-word;
}

.group_ass .contingent-thead__column:nth-child(3),
.group_ass .contingent-tbody__column:nth-child(3) {
	width: 208px;
}

.group_ass .contingent-thead__column:nth-child(4),
.group_ass .contingent-tbody__column:nth-child(4) {
	/* border:none; */
	width: 150px;
}

.group_ass .contingent-thead__column:nth-child(5),
.group_ass .contingent-tbody__column:nth-child(5) {
	/* border:none; */
	width: 150px;
}

.group_ass .contingent-thead__column:nth-child(6),
.group_ass .contingent-tbody__column:nth-child(6) {
	border: none;
	width: 24px;
}

		/* e_cards b */

.e_cards_ass .contingent-thead__column:nth-child(1),
.e_cards_ass .contingent-tbody__column:nth-child(1) {
	width: 95px;
}

.e_cards_ass .contingent-thead__column:nth-child(2),
.e_cards_ass .contingent-tbody__column:nth-child(2) {
	width: 154px;
	word-break: break-word;
}

.e_cards_ass .contingent-thead__column:nth-child(3),
.e_cards_ass .contingent-tbody__column:nth-child(3)
 {
	width: 160px;
}


.e_cards_ass .contingent-thead__column:nth-child(4) {
	width: 260px;
	text-align:center;
}

.e_cards_ass .contingent-thead__column:nth-child(5),
.e_cards_ass .contingent-tbody__column:nth-child(5) {
	width: 130px;
}

.e_cards_ass .contingent-thead__column:nth-child(6),
.e_cards_ass .contingent-tbody__column:nth-child(6) {
	border: none;
	width: 60px;
}

.rowBorder {
  width: 260px;
  border-top-style:solid;
  border-color: #D8DCDF;
	border-width: 1px;
}

.columnBorder {
  border-left-style:solid;
  border-color: #D8DCDF;
	border-width: 1px;
	padding: 0 0 0 5px;
}



		/* e_cards e*/

.b-v-contingent-conteiner-item:hover {
	background: var(--menu-color-02);
}

.assmnts-group-col {
	display: table-cell;
	width: 360px;
	padding: 5px;
	vertical-align: middle;
}

.assmnts-group-col-row {
	display: table;
	margin-bottom: 5px;
}

.assmnts-group-col .assmnts-group-col-row:last-child {
	margin-bottom: 0;
}

.assmnts-group-col-to,
.assmnts-group-col-from,
.assmnts-group-col-controls {
	display: table-cell;
	vertical-align: middle;
}

.assmnts-group-col-from,
.assmnts-group-col-to {
	width: 150px;
	padding-right: 8px;
}

.assmnts-group-col-controls {
	width: 50px;
	height: 50px;
}
