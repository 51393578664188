.b-ae-class-units-edit__thead {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-ae-class-units-edit__thead-column,
.b-ae-class-units-edit__item-column {
	display: table-cell;
	padding: 5px 5px;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
}

.b-ae-class-units-edit__thead-column:nth-child(1),
.b-ae-class-units-edit__item-column:nth-child(1) {
	width: 320px;
}

.b-ae-class-units-edit__thead-column:nth-child(2),
.b-ae-class-units-edit__item-column:nth-child(2) {
	width: 537px;
	border-right: none;
}

.b-ae-class-units-edit__thead-column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-ae-class-units-edit__item-conteiner {
	border-bottom: 1px solid #cfd4d7;
}

.b-ae-class-units-edit__item-column a {
	font-size: 12px;
	color: var(--font-color);
}

.b-ae-class-units-edit__item-column a:hover {
	text-decoration: none;
}

.b-ae-class-units-edit__item-column span {
	font-size: 12px;
	color: #454856;
}
