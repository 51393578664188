
/*---------------head-----------------------*/
.b-curriculum__head {
	display: block;
}

.b-curriculum__school {
	width: 570px;
	float: left;
	text-align: center;
	padding-top: 14px;
	padding-bottom: 12px;
	border-bottom: 5px solid var(--color-button-6);
	background: #dedee1;
	cursor: pointer;
}

.b-curriculum__active {
	background: var(--color-button-6);
}

.b-curriculum__active span {
	color: #fff !important;
}

.b-curriculum__school span {
	font-size: 17px;
	color: #454856;
}

/*-------------end head------------------*/

/*---------------tabel body--------------*/
.b-curriculum__body {
	margin-bottom: 100px;
	padding: 20px 20px;
}

#b-cs-02 {
	display: none;
}

.b-ctb__list table {
	border-collapse: collapse;
	width: 100%;
}

.b-ctb__list table thead tr th {
	text-align: left;
	padding: 12px 15px 12px 22px;
	background: #f2f5f6;
	border: 2px solid #f2f5f6;
}

.b-ctb__list table thead tr th label {
	cursor: pointer;
}

.b-ctb__list table thead tr th span.b-ctb__title {
	font-size: 16px;
	font-weight: normal;
	color: #454856;
}

.b-ctb__list table tbody tr td {
	padding: 13px 22px;
	border-bottom: 1px solid #cfd4d7;

	font-size: 13px;
	color: #454856;
	font-weight: normal;
}

.b-ctb__list table tbody tr td:nth-child(1) {
	width: 276px;
	border-left: 3px solid var(--menu-item-highlight-color);
}

.b-ctb__list table tbody tr td:nth-child(5) {
	width: 78px;
}

td.b-ctb__type-pink {
	border-left: 3px solid #fca8bb !important;
}

span.b-ctb__name {
	font-size: 13px;
	font-weight: bold;
	color: #454856;
}

/*---------------END tabel body----------*/

/*--------ICON---------*/
.b-icon__copy {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTU0QTZDOURENzcwMTFFNDhGODk5QUNERTk5MzZFMzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTU0QTZDOUVENzcwMTFFNDhGODk5QUNERTk5MzZFMzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NTRBNkM5QkQ3NzAxMUU0OEY4OTlBQ0RFOTkzNkUzNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1NTRBNkM5Q0Q3NzAxMUU0OEY4OTlBQ0RFOTkzNkUzNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtXDd0MAAACPSURBVHjaYmSgAvj//z92cTJwBcxAGGZBM5SRBIe1YxNkooaXGRkZwRjdRf+R+IeBWIqAOVxQPd+hfGWQl3EZ+BCIo4H4GZGOvAvSix45yDyQgXKkRDQscqgShsiAhVjbccUHOQaSkpRGvTwivIxuYBqUZqWGl2cBsTGtwvYhGYUtOC9jlBCwco3cqgAgwAB2FkgeWxn7CwAAAABJRU5ErkJggg==) no-repeat;
	margin-right: 5px;
	cursor: pointer;
	width: 20px;
	height: 20px;
	display: inline-block;
}

.i-icon-tb-rows {
	padding: 4px 2px 5px 13px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAATCAYAAABLN4eXAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6M0U4NjA2MjhENzc1MTFFNEIzRjY4RkUxQUJBMTFGREQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0U4NjA2MjlENzc1MTFFNEIzRjY4RkUxQUJBMTFGREQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozRTg2MDYyNkQ3NzUxMUU0QjNGNjhGRTFBQkExMUZERCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozRTg2MDYyN0Q3NzUxMUU0QjNGNjhGRTFBQkExMUZERCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pg77dYcAAADOSURBVHjaYtx79CgDqYAJhzg/EG+F0kRpYgbi1UDsBaWZidHUB8SuULYrlI9XUyoQ56GJ5UHFsWqyB+IpOPw4BSqPokkJ6n42HJrYoPIgdQwsUMFoID5ARGiD1DXDNDWTEk+M////JzlyGclJETDn1QKxLhHqLyP7aSkQnwBiUTwaXgNxBXKQ3wPiUCD+hUPDL6j8PfTIPQjEOTg05UDlsSaj2UA8CU1sElQcb4ItAuLdUPZuKJ9gKv8Ldf82KP0XV5Cjg49A7I0rGAECDABImCgp/XbG6wAAAABJRU5ErkJggg==) no-repeat 0px 5px;
	margin-left: 2px;
	cursor: pointer;
}

/*-------END ICON------*/

.b-cs-tabs3 {
	width: 379px;
	border-right: 1px solid #aaaab1;
}
