body.white-theme .filter-title,
body.white-theme .filter-field-name {
    color: #000;
}

body.white-theme .filter-container-variant {
    background: #000;
    color: #fff;
}

body.white-theme .filter-container-variant.active {
    background: #fff;
    color: #000 !important;
    border: 1px solid #000;
}

body.white-theme .filter-panel-wrapper {
    background: #fff;
}