.tree-autocomplete__container {

}

.tree-autocomplete__input-field {
	width: 100%;
	border: 1px solid #E6F1F2;
}

.tree-autocomplete__textarea {

}

.tree-autocomplete__hint-container {
    cursor: pointer;
    padding: 5px;
}

.tree-autocomplete__hint-container__node,
.tree-autocomplete__hint-container__node-active {
    border-bottom: 1px dashed #929192;
    padding: 0px;
    margin: 0 5px 0 5px;
    color: #929192;
    display: inline-block;
}

.tree-autocomplete__hint-container__node-active {
    font-weight: bolder;
}