.b-pattern_schedules-head__title {
	padding-bottom: 15px;
	border-bottom: 1px solid #cfd4d7;
}

.b-pattern_schedules-head__title span {
	font-size: 14px;
	font-weight: bold;
	color: #6f8498;
}

.b-pattern_schedules__columns {
	margin-top: 20px;
}

.b-pattern_column {
	position: relative;
	width: 191px;
	margin-right: 19px;
	display: inline-block;
	border: 1px solid #cfd4d7;
	padding: 10px 5px 10px;
	margin-bottom: 10px;
	cursor: pointer;
}

.b-pattern_column:nth-child(5n) {
	margin-right: 0px;
}

.b-pattern_item {
	font-size: 11px;
	font-style: normal;
	position: relative;
	text-transform: uppercase;
	top: -4px;
	display: inline-block;
	margin: 0px 5px;
}

.b-pattern_item span {
	font-size: 10px;
}

.b-pattern_item .b-pattern_item_watch {
	font-style: normal;
	color: var(--font-color2);
	position: absolute;
	top: 10px;
	left: 0px;
}

/**
 *  WARNING: это такой особенный чекбокс
 *  http://redmine.dfsystems.ru/issues/22465#note-14
 */
.b-pattern_column > input[type="checkbox"] + label span {
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	cursor: pointer;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAA+CAYAAAArvjtdAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wYWCyEEUinHogAAAbxJREFUWMPtl8srRFEcxz/DGBOS5NlQokZpSE2TCCULWclOFkopecS/YE8WZGHHEinExs5QmCLKwtSwYFAeGYPxGFybuzDcm3vdq5Tzq9upW+fT+T3O9/c7FkmSMNPiMNkE8D8ArQDdI/PmAj9ZEpCocf8TEFEDuoApoETHgSRgD2iV15gYTuqEAViAUmBCKSnlBkLnVgLGicIWQAHUAAwY4BwoAXuA0A9gIaBTSb6WAQfgBOI1wqKAX9ZFRYGNADtGYmgRo4gA/oHJ4eMJi4EV4Flu4Fq+KOCVbxdKjb4WSNDpYY289wvQY8DTCrMbvcUQJNluw26zmlM2RbnpDLTV09Ho0Qcszs8kKy0l5l9ZYQ59zVVIEixu7mseOAFob3Bjs8YzvuTDf3xBtauAlroyrm8fGJ1b5zx0p66HcmHHCKMjI5XepkqS7TZ2D85wOx0EL24YW9jg5v5RNTGqLp9chhmaWeUqHMHtdOAPXjI8u6YG+95lgKtwhMFpL66CbLYDp7y8vv1oaI9tMk9RfP7g35CvQwOcIyVg/+c3h0Z7ALqUYrgI5AGFOt8pASCslpRrYEs0egEUwN8GvgMyYIGJNEJfegAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 0px -8px;
	filter: none;
	margin-right: 5px;
	border-right: 1px solid #cfd4d7;
	padding: 0 4px;
}

.b-pattern_column > input[type="checkbox"]:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAA+CAYAAAArvjtdAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wYWCyEEUinHogAAAbxJREFUWMPtl8srRFEcxz/DGBOS5NlQokZpSE2TCCULWclOFkopecS/YE8WZGHHEinExs5QmCLKwtSwYFAeGYPxGFybuzDcm3vdq5Tzq9upW+fT+T3O9/c7FkmSMNPiMNkE8D8ArQDdI/PmAj9ZEpCocf8TEFEDuoApoETHgSRgD2iV15gYTuqEAViAUmBCKSnlBkLnVgLGicIWQAHUAAwY4BwoAXuA0A9gIaBTSb6WAQfgBOI1wqKAX9ZFRYGNADtGYmgRo4gA/oHJ4eMJi4EV4Flu4Fq+KOCVbxdKjb4WSNDpYY289wvQY8DTCrMbvcUQJNluw26zmlM2RbnpDLTV09Ho0Qcszs8kKy0l5l9ZYQ59zVVIEixu7mseOAFob3Bjs8YzvuTDf3xBtauAlroyrm8fGJ1b5zx0p66HcmHHCKMjI5XepkqS7TZ2D85wOx0EL24YW9jg5v5RNTGqLp9chhmaWeUqHMHtdOAPXjI8u6YG+95lgKtwhMFpL66CbLYDp7y8vv1oaI9tMk9RfP7g35CvQwOcIyVg/+c3h0Z7ALqUYrgI5AGFOt8pASCslpRrYEs0egEUwN8GvgMyYIGJNEJfegAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 0px -33px;
	filter: none;
}

.b-pattern_column > input[type="checkbox"] + label {
	cursor: pointer;
	font-size: 10px;
}

.b-pattern_column .b-icon__edit,
.b-pattern_column .b-icon__remove {
	vertical-align: middle;
}

.b-pattern_schedules-body {
	margin-top: 20px;
}

.b-pattern__week {
	position: relative;
	top: 1px;
	left: -5px;
	display: inline-block;
	width: 20px;
	height: 21px;
	border-right: 1px solid #cfd4d7;
	padding: 0px 4px;
	text-align: center;
	font-weight: bold;
}

.b-pattern__week-active {
	border: 1px solid #00a35a;
}

.b-pattern__week-active-strong {
	border: 1px solid #002816;
}

.b-pattern__week-hide {
	opacity: 0.5;
}


/*chekbox*/

/*right panel*/

.sj-container_variants {
	margin-top: 15px !important;
	position: relative;
}

.tw_line-container__variant {
	display: inline-block;
	cursor: pointer;
	background: #8092a4;
	padding: 9px;
	color: #6f8092;
}

.tw_line-container__variant span {
	color: #fff;
}

.tw_line-container__variant:nth-child(3) {
	width: 56px;
}

.tw_line-marker_container {
	display: inline-block;
	position: absolute;
	padding: 10px 10px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQxQzFBRTBFODM2MTFFNEIwQTU4REY0ODg0NEJEODgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQxQzFBRTFFODM2MTFFNEIwQTU4REY0ODg0NEJEODgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NDFDMUFERUU4MzYxMUU0QjBBNThERjQ4ODQ0QkQ4OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NDFDMUFERkU4MzYxMUU0QjBBNThERjQ4ODQ0QkQ4OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pmii9bMAAAG+SURBVHjapFS/T8JQEH5tVCIUNpQWwu6PpSAkGmZNHPwDTJwNk+yuOuuG7pq46+AfQIy1Il3ElYQmJTrySyRpvVffiweWUvVLvoS7d+/j3vXuhIOjM/IfnB7uj9iiT2wWeALUgR1Gnfmyky7NePgywDIw73G2xlgCPgCLwCe/DLeA2gSxcdAYrXR8vomdAqohfcY9z1oUBZJKyUSWF0gkMu8GdLt9YlmvxDQtYtsOvzcErkMtq+NPLnM7FJojqrpCotHISEqxmORSURZJrfZMBoMP6p7FJeJPVoG5r8xETzEMeqaqy24sQw6ermLBXX6STCZ8xb5FJTcWYQ8LFrhXluOBe1BRFrBZwIIZ/M9BIUlhbK5iwSH3Oo7916ERsGCde2lrBEW73cWmgQUr3Ev7LCharTdsVrDgJfeaZot0Ot2pYjSGxiJcYUHa5Y/0h23bxDBe4ELPR6wHjV13Yxl0r0kpsoEX+v13omkGSacVkkjEYfTCrL49tyTNpoXFHHb3x7ahGe4Ar3mmjYbpcgq2eXZe2+aGzWQ1wDehMXkQu522D3W28zbYSNIJWELtRb/mBVtzgRYsxx3jr/ApwACBppFC55nD2gAAAABJRU5ErkJggg==) no-repeat;
	left: 59px;
	top: 12px;
}

.all-schedule-button-wrapper {
	flex-direction: row;
	justify-content: center;
	display: flex;
}

.all-schedule-button-wrapper .download-button,
.all-schedule-button-wrapper .calc-button {
	display: flex;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	background: var(--color-button-5);
	color: #fff;
	border-radius: 3px;
	margin-right: 16px;
	cursor: pointer;
}
