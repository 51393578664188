/*Таблица*/
table.b-table_periods {
	border-collapse: collapse;
	width: 100%;
	margin-top: 10px;
}

/*Ширина колонок*/
table.b-table_periods tr td:nth-child(1) {
	width: 5%;
}

table.b-table_periods tr td:nth-child(2) {
	width: 23%;
}

table.b-table_periods tr td:nth-child(3) {
	width: 7%;
}

table.b-table_periods tr td:nth-child(4) {
	width: 55%;
}

table.b-table_periods tr td:nth-child(5) {
	width: 10%;
	position: relative;
}

/*Оформление шапки*/
table.b-table_periods thead {
	width: 100%;

	background-color: #EBF2F5;
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;

	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

/*Строки*/
table.b-table_periods tbody tr {
	border-bottom: 1px solid #cfd4d7;
}

/*Ячейки*/
table.b-table_periods tr td {
	padding: 5px;
	vertical-align: middle;
	text-align: center;
	border-right: 1px solid #cfd4d7;
}

/*Инпуты*/
input[type="number"].b-table_periods_input,
input[type="text"].b-table_periods_input {
	border: 1px solid #CFD4D7;
	padding: 4px 5px;
	text-align: center;
	margin: 0 auto;
	background-color: #F5F8FA;
}

input[type="number"].b-table_periods_input {
	max-width: 40px;
}

input[type="text"].b-table_periods_input {
	width: 94%;
}

.b-table_periods_name {
	white-space: nowrap;
	display: block;
	width: 100%;
	overflow: hidden;
	height: 30px;
	padding: 6px;
}

.b-table_periods_input_group-horizontal {
	float: left;
	border-bottom: 1px solid rgba(108, 135, 150, 0.2);
	margin-bottom: 6px;
}

.b-table_periods_input-date {
	float: left;
	margin-left: 50px;
	margin-bottom: 5px;
}

/** Разрыв строк **/
.b-table_periods_splitter {
	height: 20px;
	background-color: #cfd4d7;
}

.b-table_periods_input_group-inline {
	width: 200px;
	//margin-top: 5px;
	height: 30px;
	display: block;
	float: left;
}
