body.white-theme .filter-title,
body.white-theme .filter-field-name {
    color: #000;
}

body.white-theme .filter-button {
    border-color: #000;
    background-color: #fff;
}

body.white-theme .filter-button a {
    color: #000;
}

body.white-theme .filter-panel-wrapper {
    background: #fff;
}