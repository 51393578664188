.edit-in-place span {
	cursor: pointer;
	position: relative;
}

.edit-in-place span.black {
	color: #BDC5C5;
}

.edit-in-place input,
.edit-in-place textarea {
	display: none;
	color: #ff6248;
	position: relative;
}

.edit-in-place.active span {
	display: none !important;
}

.edit-in-place.active input,
.edit-in-place.active textarea {
	display: inline-block;
}

.edit-in-place.active span.black {
	display: block !important;
	color: #BDC5C5;
}

.edit-in-place span.study_period:before, .edit-in-place span.holiday:before, .edit-in-place span.mixed:before, .edit-in-place span.mixed:after {
	content: ' ';
	width: 4px;
	display: inline-block;
	position: absolute;
	height: 3px;
	top: 28px;
}

.edit-in-place span.study_period:before {
	background-color: #57922f;
	left: 16px;
}

.edit-in-place span.holiday:before {
	background-color: #92302b;
	left: 16px;
}

.edit-in-place span.mixed:before {
	background-color: #57922F;
	left: 12px;
}

.edit-in-place span.mixed:after {
	background-color: #92302b;
	left: 21px;
}
