.as-nav-btn {
	cursor: pointer;
	font-weight: bold;
	font-size: 14px;
	white-space: normal;
	line-height: 18px;
	user-select: none;
	outline: none;
	border: 0;
	padding: 0 6px;
	background: transparent;
	text-transform: uppercase;
	color: white;
}
.as-nav-btn:hover {
	color: #163344;
	background: #b1ccd7;
}

.as-nav-btn:disabled {
	font-weight: bold;
	color: darkgrey;
}

.as-nav-btn:disabled:hover {
	color: darkgrey;
	background: transparent;
}
