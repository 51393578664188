body.black-theme .all-marks-header.fix-top,
body.black-theme .all-marks-header,
body.black-theme .all-marks-header .table-col {
    background-color: #fff !important;
    color: #000;
    border-color: #fff !important;
    border-width: 1px;
}

body.black-theme .all-marks-data {
    color: #fff;
    background-color: #000;
}

body.black-theme .slider-button {
    background-color: #000;
    border-color: #fff;
    color: #fff;
}

body.black-theme .mark-value.final-mark,
body.black-theme .module-avg-mark {
    color: #fff;
}

body.black-theme .subject-period.selected {
    color: #000;
    background-color: #fff;
}

body.black-theme .subject-period.selected .mark-value.final-mark,
body.black-theme .subject-period.selected .module-avg-mark {
    color: #000;
}

body.black-theme .criterial-info {
    border-color: #000;
    color: #000;
}


body.black-theme .module-marks-row.final-mark {
    border-top: 1px solid #fff;
}

body.black-theme .subject-period:hover .module-title {
    border-bottom: 1px solid #000;
}

body.black-theme .subject-period:hover .module-marks-row.final-mark {
    border-top: 1px solid #000;
}