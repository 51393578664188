.input-time-wrapper {
	display: inline-flex;
	margin: 0;
	padding: 2px;
	border: 1px solid #eee;
	font-size: 12px;
	height: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	min-width: 63px;
}

.input-time-wrapper .input-time-placeholder {
	font-family: inherit;
	font-size: 15px;
	color: #9E9E9E;
}

.input-time-wrapper.disabled {
	border: 1px solid #E6EBED !important;
	background-color: #EEEEEE;
}
.input-time-wrapper.disabled .input-time-values input {
	background: none;
	color: rgba(0,0,0,0.47) !important;
}


.input-time-wrapper.invalid {
	border-color: #ff0000;
}

.input-time-clear {
	clear: both;
}

.input-time-separator {
	position: relative;
	left: -2px;
	cursor: default;
}

.input-time-values input {
	border: none !important;
	padding: 0;
	margin: 0;
	width: 17px;
	line-height: 19px;
	height: 20px;
	text-align: center;
}

.input-time-to {
	position: relative;
	left: -3px;
}

.input-time-values {
	width: 44px;
	float: left;
}

.input-time-controls {
	float: right;
}

.input-time-controls-up-arrow,
.input-time-controls-down-arrow {
	width: 15px;
	height: 10px;
	cursor: default;
	padding: 0;
	margin: 0;
	background-repeat: no-repeat;
}

.input-time-controls-up-arrow {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUcAsS7qEsAAABOSURBVDjLY/z48eN/BjIBEwMFYJBqjo+PJ08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGAsXLsRvM7oCfOJMxCjEZSATISfi0jiEEwkAhCEhuybrLiwAAAAASUVORK5CYII=);
	background-position: 0 0;
}

.input-time-controls-down-arrow {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUcAsS7qEsAAABOSURBVDjLY/z48eN/BjIBEwMFYJBqjo+PJ08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGAsXLsRvM7oCfOJMxCjEZSATISfi0jiEEwkAhCEhuybrLiwAAAAASUVORK5CYII=);
	background-position: 0 -10px;
}

.input-time-controls-up-arrow:hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUeKVoxM4kAAABOSURBVDjLY7x06dJ/BjIBEwMFYJBqrqurI08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGE1NTfhtRleAT5yJGIW4DGQi5ERcGodwIgEAjV0hAQzCJM0AAAAASUVORK5CYII=);
}

.input-time-controls-down-arrow:hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUeKVoxM4kAAABOSURBVDjLY7x06dJ/BjIBEwMFYJBqrqurI08zTCM+A5iIsRGXAUzEOhWbOONoPJMGWEhJGE1NTfhtRleAT5yJGIW4DGQi5ERcGodwIgEAjV0hAQzCJM0AAAAASUVORK5CYII=);
}

.input-time-controls-up-arrow.active {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUhJpJQBCQAAABNSURBVDjLY6yoqPjPQCZgYqAADFLN7e3t5GmGacRnABMxNuIygIlYp2ITZxyNZ9IACykJo7KyEr/N6ArwiTMRoxCXgUyEnIhL4xBOJACJqh3yQVTE3gAAAABJRU5ErkJggg==) !important;
}

.input-time-controls-down-arrow:active {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAYAAABSx2cSAAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB98JEQUhJpJQBCQAAABNSURBVDjLY6yoqPjPQCZgYqAADFLN7e3t5GmGacRnABMxNuIygIlYp2ITZxyNZ9IACykJo7KyEr/N6ArwiTMRoxCXgUyEnIhL4xBOJACJqh3yQVTE3gAAAABJRU5ErkJggg==) !important;
}

.input-time-values input.active-input {
	background-color: #D3E4F5;
	/*color: #fff;*/
}
