body.white-theme .portfolio-final-mark-year-title,
body.white-theme .portfolio-final-mark-subjects {
    border-color: #000;
}

body.white-theme .portfolio-view-type-selector .tab {
    background: #fff;
    color: #000;
    border-top: 1px solid #fff !important;
    border-bottom: 5px solid #000 !important;
}

body.white-theme .portfolio-view-type-selector .tab.active {
    background: #000;
    color: #fff !important;
    border-top: 1px solid #000 !important;
    border-right: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
    border-bottom: 5px solid #000 !important;
}

body.white-theme .portfolio-academic-year-selector {
    color: #000;
}

body.white-theme .b-portfolio__thead {
    border-color: #000;
    background: #000;
}

body.white-theme .b-portfolio__pre-thead-col,
body.white-theme .b-portfolio__tbody-item-col {
    border-color: #000;
}

body.white-theme .b-portfolio__thead span {
    color: #fff;
}

body.white-theme .b-portfolio__pre-thead-col span {
    color: #000;
}

body.white-theme .b-portfolio__tbody-item-col span {
    color: #000;
}

body.white-theme .i-icon__print {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjQ3RkE0RDYxRjNFMTFFNThFNDlBNzBDMTRGNTU1NkQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjQ3RkE0RDcxRjNFMTFFNThFNDlBNzBDMTRGNTU1NkQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGNDdGQTRENDFGM0UxMUU1OEU0OUE3MEMxNEY1NTU2RCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGNDdGQTRENTFGM0UxMUU1OEU0OUE3MEMxNEY1NTU2RCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpBDS2oAAAEYSURBVHjaYvz//z8DVQGagdFA/BmI/xCJP0P1wM1iQTPfAYh/AbEnke7ZCNWzFCaAbuAnIL4HxEeINPAeVA8DuoGKQKwLxFZArA3EwUQaqA31uh8QXwbi+4wgfzMyMr4GcgSA+B0Q/wZiQSINfA/ErEAsBMQfgGaJwiIFRFggKWQmEsOABSROEJHyAy0s/pKYWD5BzWBgQhLkoSD1wfUyMVAZICebW0DsCMQLSDQjAYjPoxsIcukHUIw5OjrKNTU1EWVSXV0dw/79+1mhepmQDWQD4kwg1tHU1GSwsbEhykCQWqCB/kCmMtQMuIGrgbgVlP7u3r3LcPXqVaIMBKkFgixoelyNrXCIhCYBUnAkcuHASO3iCyDAANX/aYyOM4zKAAAAAElFTkSuQmCC) no-repeat;
}