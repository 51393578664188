.student-edit-field {
	display: block;
	margin: 20px;
}

.student-edit-field select {
	cursor: pointer;
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat 94% 14px;
	filter: hue-rotate(var(--icon-hue-rotate));
}

.student-edit-field select, .student-edit-field input, .student-edit-field textarea {
	display: inline-block;
	float: right;
	border: 1px solid #e7e7e7;
	font-size: 14px;
	-webkit-appearance: none;
	color: #000;
	width: 400px;
	margin-left: 10px;
	height: 36px;
}

.student-edit-field .profile-info {
	display: inline-block;
	float: right;
	font-size: 14px;
	-webkit-appearance: none;
	color: #000;
	width: 400px;
	margin-left: 10px;
	height: 36px;
}

.student-edit-field textarea {
	height: 130px
}

.student-edit-field label {
	display: inline-block;
	float: left;
}

.student-edit-field input[required] + label:after, .student-edit-field select[required] + label:after {
	content: ' *';
	color: red;
	vertical-align: super;
}

.student-edit-field select[multiple] {
	background: none;
}

.r-st-filter__field .sort {
	color: #e7e7e7;
	text-transform: uppercase;
	cursor: pointer;
}

.r-st-filter__field .sort:before {
	content: '||';
	display: inline-block;
}

.col-2 {
	min-height: 503px;
}

.col-2 div {
	padding: 20px 0 0 0;
}

.col-2 div div {
	padding-top: 5px;
}

.col-2:first-child div div {
	padding-top: 0;
}

.paginator .prev {
	margin-right: 15px;
	margin-left: 5px;
	transform: rotate(90deg);
}

.paginator .next {
	margin-left: 15px;
	margin-right: 5px;
	transform: rotate(-90deg);
}

.paginator .next, .paginator .prev {
	display: inline-block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat;
	filter: hue-rotate(var(--icon-hue-rotate));
	width: 17px;
	height: 17px;
	cursor: pointer;
}

.paginator {
	font-size: 17px;
}

.paginator .small-input {
	width: 20px;
	border: none;
	text-align: center;
	font-size: 17px;
	font-family: 'Open Snas', sans-serif;
}

.student-edit-field input[type=checkbox] {
	display: none;
}

.student-edit-field input[type=time] {
	width: 105px;
	float: none;
	height: auto;
}

.student-edit-field.check input[type=checkbox] + label span {
	float: right;
	margin-top: -7px;
}

.student-edit-field.check input[type=checkbox] + label {
	width: 100%;
}

.student-edit-field .r-cab_lessons-inp {
	height: 39px;
	width: 135px;
	display: inline-block;
	margin-left: 10px;
	float: right;
	margin-top: 10px;
}

.b-ec__selects .r-cab_lessons-inp {
	height: 39px;
	width: 135px;
	display: inline-block;
	margin-left: 10px;
	margin-top: 10px;
}

.b-ec__selects .r-cab_lessons-inp input {
	border: none;
	width:450px;
	padding: 9px;
}
.student-edit-field .multiple-container {
	width: 400px;
	padding-left: 446px;
}

.student-edit-field .r-cab_lessons-inp input {
	width: 114px;
	padding: 0 0 0 10px;
}

.student-edit-field .r-cab_lessons-inp i {
	right: 10px;
}

.student-edit-field .table {
	display: table;
	float: right;
	min-width:380px;
}

.student-edit-field .table div {
	padding: 0;
}

.student-edit-field .table > div {
	display: table-row;
	vertical-align: top;
}

.student-edit-field .table > div > div {
	display: table-cell;
	vertical-align: top;
	line-height: 31px;
	padding-right: 31px;
}

.alph {
	/* float: left; */
	margin: 2px;
	padding: 2px;
	text-align: center;
	width: 13px;
	color: #fff;
	border: none;
	display: inline-block;
	cursor: default;
}

.alph-enabled {
	cursor: pointer;
	border: 1px solid #ddeef6;
	width: 12px;
	padding: 0 2px;
	/* font-size: 12px; */
}

.alph-selected {
	background-color: #ddeef6;
	color: #444b53;
}

.b-rf-events__control-stud {
	background: #fbfbfc none repeat scroll 0 0;
	color: #cccdcd;
	font-size: 16px;
	font-weight: normal;
	padding: 10px 26px 10px 16px;
	width: 24px;
	cursor: pointer;
}

.b-rf__dot-bg-stud {
	left: 57px;
	position: absolute;
	top: 13px;
}

.r-cab_box__head-stud {
	background: none;
	border-bottom: none;
	padding: 0 8px 9px;
}

.r-cab_number-stud input[type="text"] {
	padding: 5px 20px;
	width: 17px;
}
