body.white-theme .student-homework-list .b-icon__file002 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAACyCAYAAABCzO/+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AMXCh0eWApwfQAABHpJREFUaN7t2l2IVVUUB/DfVCrINErTUJSaTgP1kGJUFCSTCRURpJFFFqEEGhEE9VAPNS9RYPTiW/rQh2UYPYhBEmJRQR8+ZFoPZUFZkfmd3dFRs+z0cNa14+3ce8/ce6UxzoLNOfucc/937bXXx15r764kSXSSztJhKgHHIKAkSTRRnYnYiE8yz6ZhIMvQSZwmgJMC6DgW4WK8hyTa17isKGAftuEIbg+OfsABLMOd+Bn7cU0zwCn4FhXMw0zsxq64r9JUfI9vGgEO4Cfsw7XRfg3uBnA2XsL78f1z6YjzAWdiL3bG/TwcCllNwXisD/k9Gs9+weY8wGnByXfByU04hs9DnhPxLk5gaXyzI34zKw/wZYygHxOwJ8AmRdscs31PcL8r5Dqz3ixX8GIM/fIY1iOYjS9jtm8LmR7IyLSuHiZ4Nt6Px/aMvu3BYEam20N+GgF+FsKfEN/0YGHoW3fo4tGMTDUDvCUE/g4m13x/H/7ARyFPRQDh3uBiH5bjIayJP9oYM200gMI2Xwt1SMIShkKuTZ1MVxmkSsD/dRgNBR/OeJhqO445hTFqABPMCudQbU+H+XUXATzF9Lq6uhLMCOdZpXPwaYSIozl4w0mSzMp+3Iz+xM24JOfdRdig5t+L0MFotfRbqYdnAGDeLPfkRL161NOK6TVrw3UtpZzlErAELBj1GprZ0NDQVZn+v36fJMnoOOzt7T2Fl9Mx5KQtwCKOJC8u16X+/v6rBwcHT/YrlYp169ZtaRmwDtddpaWUgGMNsIzLJWAJ2KlsNNuORemqLk7tov1cafF2c+ZZd2Zxfwdel9ZdPyyaBezG+XgSXTnvd0oLk1vwFR4okjxegRsiV87Ku0daOZ4tLegullb0nm+0tpmLJTU5cx6tkJb+TmBxS2ubGjqCK4PzVzqhNk9F9r+oU3r4V4hld2nLZ2ACvhDnaVARHg3gq9IK+jRNqsJFARfHdQmewfQCGBcWcQ7D0v2nHQUZGymXIiVgCdjOUqSiWEVuK8bllQjy0tvH/LORmkerpRuJb2BJUmu7OYF+QZNBfYCP8TvebKuIEbQlPNFh3FWkzFLL4XiszHG4C2T2lYty2I23cX9cu9tRmz5swlrpTuTa6PeNtrJUHfIqzI/76kpsfjyvO+Sis5y3tGtJhqfN9JZ3GnDlmFqKzIhFeTOa0UlvU22HGnqbMgSUgCVgM4/dwOwO4tKiOCdNL4L8Uum5myy9ECOZIz0iUxfwlEV7AN4YgbzWq2zFj/L3mGFvkiR3N0orsrQDt0rPKuXRAB7Ok2G13pBH46qLoxyamw1WRWd5WbSWPHY9DrULeAEejBm+Lp5NjlrDKunRrVEBjuCLqC5NjWfbQtYjrXB4GG/FfTUrXd8pGe7vhAyztKZVwAE5pyua0MBonUORVinjcglYApYJ+NhLwOtRS8njikx+3Bf9ttRmk3TvZE5cN7UbUzagN8CWqjnJ1wqH1+Nx6aHlJ6I/KsWunZTVmZg8Pfo6USLo2Cz/986hTMBLwBKwBMylvwGKZ8Yz5vrzwQAAAABJRU5ErkJggg==);
}

body.white-theme .student-homework-list .homework-table-header {
    background: #fff;
    border-color: #000;
}

body.white-theme .student-homework-list .homework-table-header .column,
body.white-theme .student-homework-list .homework-details .column {
    border-color: #000;
    color: #000;
}

body.white-theme .student-homework-list a.homework-book-link {
    color: #000;
}

body.white-theme .student-homework-list .column.is-required input[type="checkbox"]:checked + label span {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYzODY3QjZGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYzODY3QjdGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NjM4NjdCNEYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NjM4NjdCNUYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuirhYcAAAEZSURBVHja7NQ9S0JRHMdxlVxEglwcjBuBSCC4CQpNQTVI6JCBi74EfRU6ubg6Ci4OSS0KRQ8GNvsS7isQBcEHuH4vHEHilPd6lRzOgQ8H7oXfef67DcNwOW0e1w7a4YQcJdstRwH9TPZ/l3OKV4S3DTnHJ65Q3SbkAj0R9I2C3ZAY3hES/Q2GdkLieEMQHaQw/rmx5jT7OJMEXOIFATwijYnsdCpIiA2LrP2/RhfHaOIBs9+O2NygL2j4QBR3eIYPdeSxkN5Y0Y9wi7YY3TwBP7yooQTDymWbiNGfcCICzGUW/wpYn8mqTXGPBgYoW3qAkm9z5DaNbuXt2Cp3blUe91RjdV13FKBpmqqxqsaqGqtqrKqx8rYUYAAMRWAd03EUCAAAAABJRU5ErkJggg==) top no-repeat !important;
}

body.white-theme .student-homework-list .student-homework-file-upload-link,
body.white-theme .student-homework-list .student-homework-file-upload-link span,
body.white-theme .student-homework-list .student-homework-file-upload-link a {
    color: #000;
    border-color: #000;
}