.transfer-action-control {
    color: var(--font-color);
    text-decoration: underline;
    cursor: pointer;
}

.transfer-action-control:hover {
    text-decoration: none;
}

.transfer-info .transfer-action-control.move-to {
    display: none;
}

.transfer-info:hover .transfer-action-control.move-to {
    display: inline;
}

.transfer-info span {
	font-size: 11px;
}
