.b-teacher__title {
	width: 850px
}

.b-teacher__control-works {
	display: inline-block;
	margin-bottom: 15px;
	width: 405px;
	padding: 0 30px 0 0;
	position: relative;
	vertical-align: top;
}

.b-teacher-cw__left {
	float: left;
	width: 70px;
}

.b-teacher-cw__right {
	width: 330px;
	padding: 5px 0 21px 0;
	float: left;
	border-bottom: 1px solid #e4e4e4;
}

.b-teacher__photo {
	background: #FFFFFF;
	text-align: center;
	height: 50px;
	width: 50px;
	padding: 1px 1px 1px 1px;
	border: 1px solid #fff;
	border-radius: 50%;
	box-shadow: 0 0 3px #B5B5B5;
}

.b-teacher-cw__title {
	font-size: 17px;
	font-weight: bold;
	color: #000;
}

.b-teacher-cw__location {
	font-size: 12px;
	font-weight: normal;
	color: #696969;
}
