.mrko-date-time-picker-directive-wrapper {
	position: relative;
	margin: 0 auto;
	padding: 0;
	width: 150px;
}

.mrko-date-time-picker-input-icon {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wceBTAjVrB9jAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAA5SURBVDjLY7TcvO4/AwWAiYFCwAJjHPMJJEmj1Zb11HEB9byA7jRcAN2rLIQUjHphZHmBkNNp5gIAKJYUjzGhDC8AAAAASUVORK5CYII=) 0 0 no-repeat;
	filter: hue-rotate(var(--icon-hue-rotate));
	height: 16px;
	width: 16px;
	cursor: pointer;
	display: block;
	float: left;
	margin: 7px 0 0 5px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mrko-date-time-picker-input input {
	width: 120px;
	height: 30px;
	line-height: 30px;
	display: block;
	float: left;
	font-size: 18px;
	color: #929192;
	border: none;
	text-align: center;
}

.mrko-date-time-picker-input input:disabled {
	background-color: #ddd;
}

.mrko-date-time-picker-calendar,
.mrko-date-time-picker-input {
	border: 1px solid #6c8796;
	background-color: #fff;
}

.mrko-date-time-picker-calendar {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 12px;
	border-top: none;
	position: absolute;
	top: 32px;
	left: 0;
	z-index: 100;
	width: 165px;
}

.mrko-date-time-picker-clearfix {
	clear: both;
}

.mrko-date-time-picker-calendar-header {
	position: relative;
	color: #ffffff;
	background-color: var(--color-button-5);
	height: 22px;
	padding: 0 3px 0 8px;
	font-size: 15px;
}

.mrko-date-time-picker-calendar-header div {
	display: block;
	height: 20px;
	float: left;
}

.mrko-date-time-picker-calendar-header .title {
	text-align: center;
	width: 118px;
	font-weight: bold;
	cursor: default;
}

.mrko-date-time-picker-calendar-header .left,
.mrko-date-time-picker-calendar-header .right {
	width: 15px;
	cursor: pointer;
}

.mrko-date-time-picker-calendar-header .left:hover,
.mrko-date-time-picker-calendar-header .right:hover {
	font-weight: bold;
}

.mrko-date-time-picker-calendar-weekdays {
	margin-left: 6px;
	cursor: default;
}

.mrko-date-time-picker-calendar-weekdays span {
	font-weight: bold;
}

.mrko-date-time-picker-calendar-days {
	border-top: 1px solid #c3d2dA;
	padding-left: 5px;
}

.mrko-date-time-picker-calendar-days + .selected-week {
	background-color: #FEE7F0;
}

.mrko-date-time-picker-calendar-weekdays span,
.mrko-date-time-picker-calendar-days span {
	color: #000000;
	text-align: center;
	padding-top: 2px;
	width: 22px;
	height: 22px;
	display: inline-block;
}

.mrko-date-time-picker-calendar-days span:hover {
	background-color: #ededed;
}

.mrko-date-time-picker-calendar-days span {
	cursor: pointer;
	font-weight: normal;
}

.mrko-date-time-picker-calendar-days span.weekend {
	color: #b02020;
}

.mrko-date-time-picker-calendar-days span.another-month-day {
	color: #d0d0d0;
}

.mrko-date-time-picker-calendar-days span.selected {
	font-weight: bold;
}

.mrko-date-time-picker-input.error input {
	background-color: rgba(255, 0, 0, 0) !important;
}
