@media print {
    header.header,
    div.b-left__sidebar,
    div.b-right__nav_sidebar,
    .b-events__right_nav,
    footer.footer, .date-period {
        display: none;
    }
    .date-period-active {
        display: block;
    }
    .b-right__sidebar, .b-container__wrap, .row-1-1, main {
        width: 100%!important;
        margin: 0;
        padding: 0;
    }

    .b-hw-page-hw__tb-item {
        page-break-after: auto;
        page-break-inside: avoid;
    }
}

.reports-homework-table-wrapper {
    width: 878px;
    margin: 30px auto;
}

.reports-homework-table-header {
    background: #ebf2f5;
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
}

.reports-homework-table-body {
    border-bottom: 1px solid #cfd4d7;
    font-size: 12px;
    color: #454856;
}

.reports-homework-table-column {
    display: table-cell;
    box-sizing: border-box;
    vertical-align: middle;
    border-right: 1px solid #cfd4d7;
    line-height: 13px;
    padding: 5px;
}

.reports-homework-table-column.date-assign,
.reports-homework-table-column.date-prepare {
    width: 110px;
}

.reports-homework-table-column.subject {
    width: 140px;
}

.reports-homework-table-column.description {
    width: 518px;
    border-right: none;
}

.reports-homework-table-body .subject {
    white-space: -o-pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: pre-line;
    word-wrap: break-word;
    word-break: break-all;
}

.reports-homework-fixed-header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 878px;
    margin: 0 auto;
    background-color: #ebf2f5;
}