.assignments {
	border-top: 1px solid #DADCDC;
	border-bottom: 1px solid #DADCDC;
}

.assignments__title {
	padding-top: 10px;
	padding-left: 20px;
	color: #6c8796;
	font-size: 12px;
	font-weight: 500;
}

.assignments__new {

}

.assignments__new + .assignments__title {
	margin-top: 10px;
}

.assignments__new,
.assignments__edit {
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 5px;
	display: inline-flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
}

.assignments__edit_current {
	background-color: hsla(178,52%,90%,1);
}

.assignments__select_timetable {

}

.assignments__select_timetable_inline {

}

.assignments__controls {
	display: flex;
	margin-left: 10px;
	margin-top: 5px;
}

.assignments__controls_save {
	margin-left: 10px;
}

.assignments__controls_cancel {
	margin-left: 10px;
}

.assignments__controls_remove {
	margin-left: 10px;
}

.b-icon__ok {
	margin: 0;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAYlJREFUOI291D9IllEUBvCf7ydIEBZaBgkmOEVhRFNTYIOLizokppsELa1tgY1J6FBrYzRVU1s0NTUpRrmIOrR8mC6Civ0Z7rHerq/f9wXRgRfec5/nPPece889/GNra4K34wr6UGANizj42416sIAt/Mi+LTzB+VYzHMZzdGEJr7ES2EWMRoY3sdkss2HsB3G8Ae9E6X8GD6pIPSG0iUvNdg67Jx3DKjpzcCHARpmV7X7wP6M3B9ulw15sQagNsyG2hHNVpGtBmA3/NEaOEZsL7gd0H7fraJAmw3+Fb5gqcQo8Dd57nGpUxqHg7fAHsIHvmEYNz4LzFidLsd247M+bdzXID0trh6Llpn6TB2I+sP7yYk1ql+WMPICvEfASHRlek57jRl4yPI7AiQrReakTcrvraGW/7Azq2MZgFSGz69jBFw0uaAh7ITqp+q0XuBNiu7hRBqsChvACZ/HJ7+FQSMNhTDqGOm7hXQvV6MKjCMrHV11q7sqmbjZgC2lQXAh/HR+l/vw/9hPV8l41iMM20QAAAABJRU5ErkJggg==) no-repeat 0px 0px;
	cursor: pointer;
	display: inline-block;
	padding: 0;
	margin: 0;
	width: 20px;
	height: 20px;
	background-position: 0px 0px;
	cursor: pointer;
}

.b-icon__delete {
	margin: 0px;
	margin-left: 7px;
}

.b-icon__edit {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REQzRTYzQjhCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REQzRTYzQjlCRDlBMTFFNDlGRDNCQkUyOEJGODAzMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpERDNFNjNCNkJEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpERDNFNjNCN0JEOUExMUU0OUZEM0JCRTI4QkY4MDMyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnzhP70AAAFDSURBVHjarJRLagJBEIadaOIjuYeZGTfewRN4DF15EVdm6RE8QryEOgSCkAMEERJ8ZNP5C/6Esq1uHbDgW0xT89HdVV2Jc65y0xChBSIDY1CAAym4lgX/M0Q5mIAVGIEU1EnKtRVz8qgQ0Qd7MAS1yO5rzJHcvinkznagV+Jee5TmllCOMFTfViTg3luTnU5OhCyA3Es1IhTZFMzAg1qv8t9MC6VyI++eLJkj/1LmSqHGWijtkAaEvszx+04JpfqFFkqP1cvKlFB2e9TCoyx6wmfQBPOQTAmfwFfwyIw16IIWeLVkStgBi2BRGFvwSWmDx68EhGdFOWkbxgfYgHfQjjT3edv4jV1mkJiNbT29a4g+PW84DK4YDoPocFDJUrEXNb7afL+PPMXf+JKczsV5aAzYN/ADvsHy0oBNbjHxdfwKMABmJu3KHmRHFgAAAABJRU5ErkJggg==) no-repeat;
	display: inline-block;
	padding: 0;
	margin: 0;
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.assignments__select {

}

.assignments__select_title {
	color: #FFFFFF;
	font-size: 14px;
	padding: 0 0 5px 10px;
}

.assignments__select select {
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat 94% 12px;
	border: 1px solid #6C8796;
	cursor: pointer;
	font-size: 14px;
	color: #000;
	width: 134px;
	height: 32px;
	margin-left: 10px;
	padding: 5px;
	text-indent: 0.01px;
	text-overflow: '';
	-ms-appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none !important;
}

.assignments__select select:disabled {
	background: #ddd;
}

.assignments__select select::-ms-expand {
	display: none;
}


.assignments_table  .ts-class-checked{
	height: 46px !important;
	background-color: #BDBDBD !important;
	background-position: 6px 6px !important;
	background-size: 34px 34px !important;
	background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGQ9Ik05IDE2LjJMNC44IDEybC0xLjQgMS40TDkgMTkgMjEgN2wtMS40LTEuNEw5IDE2LjJ6Ii8+Cjwvc3ZnPg==) !important;
	background-repeat: no-repeat !important;
}
.assignments_table  .ts-class-checked.time-has-come{
	background-color: #39b3ae !important;
}
