.registry-staff__wrap {
	margin-bottom: 140px;
	padding: 20px 20px;
}

.registry-staff__thead {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.registry-staff__thead-column,
.registry-staff__item-column {
	display: table-cell;
	padding: 5px 5px;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
}

.registry-staff__thead-column:nth-child(1),
.registry-staff__item-column:nth-child(1) {
	width: 260px;
}

.registry-staff__thead-column:nth-child(2),
.registry-staff__item-column:nth-child(2) {
	width: 90px;
}

.registry-staff__thead-column:nth-child(3),
.registry-staff__item-column:nth-child(3) {
	width: 64px;
}

.registry-staff__thead-column:nth-child(4),
.registry-staff__item-column:nth-child(4) {
	width: 420px;
}


.registry-staff__thead-column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.registry-staff__item-conteiner {
	border-bottom: 1px solid #cfd4d7;
}

.registry-staff__item-column a {
	font-size: 12px;
	color: #39b3ae;
}

.registry-staff__item-column a:hover {
	text-decoration: none;
}

.registry-staff__item-column span {
	font-size: 12px;
	color: #454856;
}
