.report-final-marks-table-col.print-version {
    display: none;
}

.report-final-marks-table {
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    border-top: 1px solid #cfd4d7;
    border-bottom: 1px solid #cfd4d7;
    font-size: 11px;
    width: 1000px;
}

.vertical-title {
    -moz-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    height: 30px;
    width: 210px;
    display: block;
    margin-right: -200px;
    margin-left: -92px;
}

.vertical-title-inverse {
	-moz-transform: rotate(270deg);
	-webkit-transform: rotate(270deg);
	-o-transform: rotate(270deg);
	height: 26px;
	width: 80px;
	margin-left: -48px;
	vertical-align: middle;
	text-align: center;
	display: block;
	padding-top: 50px;
}

.report-final-marks-table-header {
    display: table-row;
    background-color: #ebf2f5;
    color: #6c8796;
    font-weight: bold;
}

.report-final-marks-table-header-col-wrap {
	float: left;
	text-align: center;
}

.report-final-marks-table-col {
    display: table-cell;
    box-sizing: border-box;
    border-top: 1px solid #cfd4d7;
    border-right: 1px solid #cfd4d7;
    border-bottom: 1px solid #cfd4d7;
    text-align: center;
    vertical-align: middle;
    line-height: 12px;
    position: relative;
    width: 27px;
}

.report-final-marks-table-body {
    display: table-row;
    border-bottom: 1px solid #cfd4d7;
}

.report-final-marks-table-body .report-final-marks-table-col,
.report-final-marks-table-header .report-final-marks-table-col {
    border-left: 1px solid #cfd4d7;
    box-sizing: border-box;
    padding: 3px;
}

.report-final-marks-table-body .report-final-marks-table-col {
    padding: 5px 0;
}

.break-module-name {
    white-space: pre-line;
    white-space: -o-pre-wrap;
    white-space: -moz-pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}

.report-final-marks-table-col.ultra-big-cell {
	width: 300px;
	min-width: 300px;
	max-width: 300px;
}

.report-final-marks-table-col.medium-big-cell {
	width: 170px;
	min-width: 170px;
	max-width: 170px;
}

.report-final-marks-table-col.big-cell {
    width: 130px;
    min-width: 130px;
    max-width: 130px;
}

.report-final-marks-table-col.medium-cell {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
}

.report-final-marks-table-header .report-final-marks-table-col {
    height: 220px;
}

.report-final-marks-table-header .report-final-marks-table-col-inverse {
	height: 80px;
	overflow: hidden;
}


.table-cell-data-wrapper {
    display: block;
    padding: 2px 0;
    margin: 0;
    min-height: 20px;
    line-height: 20px;
}

.table-cell-data-wrapper-inverse {
	border: 1px solid #cfd4d7;
	height: 20px;
	overflow: hidden;
}

.report-final-marks-table .slider-wrapper {
	display: table-cell;
    position: relative;
    overflow: hidden;
    width: 700px;
    max-width: 700px;
    min-width: 700px;
}

.report-final-marks-table .slider-content {
    display: block;
}

.report-final-marks-table .slider-button {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 220px;
    width: 17px;
    cursor: pointer;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAD/Rn+7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg1Nzc3NENERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg1Nzc3NERERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5ODU3Nzc0QUREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5ODU3Nzc0QkREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkxI2MEAAADfSURBVHjaYvz//z/DYAZMDIMcjDqQlg60pKM7LEl1YDQQHwXiSXRw3ESoXdHEOtAJiOcBMSMQX6eDA29A7ZoHtRsVgIoZJKwLxB/+Q0A7mhwtcTvUzg9QN8DlkBXJAPFjqMIlQMxIRwcyQu38D3WDDLoDRYH4KlTBXiBmo6PjYJgNajcIXIG6CSUNsg6i0oUdWxocTFEsiy0NDvpMAsNOQPwTqiGTDo7LhNr1E2o3ijy2cnAfECeBYh+INemQ3jSgdiVB7UYBjHiaW6Dq5zgdqzqsdjGOtgdHHTjAACDAAOCOBAWQkOCzAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-color: #dbdbde;
    z-index: 100;
}

.report-final-marks-table .slider-button.left {
    background-position: 3px 99px;
    top: 0;
    left: 282px;
}

.report-final-marks-table .slider-button.right {
    background-position: -26px 99px;
    top: 0;
    right: -17px;
}

.report-final-marks-table .slider-button-inverse {
	height: 108px;
}

.report-final-marks-table .slider-button-inverse.left {
	background-position: 3px 45px;
	top: 0;
	left: 282px;
}

.report-final-marks-table .slider-button-inverse.right {
	background-position: -26px 45px;
	top: 0;
	right: -17px;
}

@media print {
    header.header,
    div.b-left__sidebar,
    div.b-right__nav_sidebar,
    .b-events__right_nav,
    footer.footer, .date-period,
    div.report-final-marks-download-report {
        display: none;
    }

    .date-period-active {
        display: block;
    }

    .b-right__sidebar, .b-container__wrap, .row-1-1, main {
        width: 100% !important;
        margin: 0;
        padding: 0;
        clear: both;
    }

    .report-final-marks-table.page-break {
        position: relative;
        page-break-inside: avoid;
    }

    .report-final-marks-table .slider-wrapper,
    .report-final-marks-table .slider-button {
        display: none;
    }

    .report-final-marks-table {
        width: 1140px;
    }

    .report-final-marks-table-col.print-version {
        display: table-cell;
    }

    .report-final-marks-table-col {
        width: 25px;
    }

    @page {size:landscape}
}
