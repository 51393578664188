body.black-theme .student-homework-list .b-icon__file002 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAACyCAYAAABCzO/+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AMXChAVenbXuAAABNFJREFUaN7tml2IVVUUx//LZkYYdK70QZGjOTWgD41MVBg0lAkVkaSDJmmEEmhEENRDPVQvToHRy7ylD31YRtFDGCghY2hS5oNT1kNa0GiR6eiM5jijpuWvB9etw5l9zz33oxhpb9jce/bZ93fWXnutvc7a+xqgepZJqnOJwAkIFKAs0wGagW3A7kTbTKAdmDSOkwUECsBu4DywHJgOfMo/ZT8wOxcQuAbYB5wBHnKJDgHDwBpgCfALMATcngkEWoEfgFPAAqADOAocAToS/WYAA8D3JYEuyc/AcWCe1xMuXTtwBfAmsMP7v+rDHw90SY4Bh/37AuC066oVaAI2O+AZb/sV2DMO6DN3AvjRJbkXOAd85fpsBrYDfwKrvc9B/83cEPAtYAy4EZgMDDqs4HWPz/YjLv0R12tHcJZ9At7wm3N8WE8DncC3PtsPuk6HizotaYcOeMVvNgEHEvY2CNyV0OkBoDXoIAngXlf+ZO/QAix1e5vitni2qNOSHpcA3u8K/wSYlur8KHAB+BwoZLpwymxWuBTHgXXAk8Amf9A2oLnsmhAw7NnAu24OuCe8BDTlWWQsBqkI/D8ARySRqucldZmZStWgyySWsLnAtERd6+43pZzbhVwPSW1mdijRuUHSl5JmSjob4I1Imlu8aCinEzP7A7hP0g2B29dL2ppsaMijaDM7KelkYKi/RTu8DIChWW5JR72M0jLOIlKGPSJpaoVCnU6CY5CKwAisBkhW7enpubX4PRjxKpVweHg4ufxT9yGHoBUBQ0Mst3xlLj0DAwO37dq16+/rQqGg7u7u/qqBpYSKhh2BE/3NwaIOIzACq8xGk/WcpCVZGWna9aZKWi5pT6JtSqJft6T3gCEz+yxvFnBU0tWSXijh24clbQf6JX1nZo/nSR5vlnS3pLUp9bRI2iepU9IMSSuB/Wb2Wp5sdMTMejPeGnp9BOuAY5I21jrLZyTd4oy362E2L0pq80ms3Q7N7KJvJRyNrncZJuBLJV0pqbkewHckterSLlJTzUAzW+lutUrSy8CsHIzr8iwOI5KmSzqYU7CxuEUQgRFYC/BUuW0Cr19Lagzlz6H09llJOzKE2ChpjqQPJK0alxIHdtoXl9kW2Al8AfwOfJj6fVU67PeVaFTSw6EnZkrox5rr08dwwOLkuXIuCf1AYYukxyRtyTpgKGs2fv7ZJ+l9Scf9sy90LppryMAGYJF/P+Sfi4ANWUPONctFYM06/Dddb11dgWa2fkK9irQBnTl+2xYcTWpxOKXAgUFGGVXq7CAG+giMwEqBpYL8SUk3ZZ2AJwN+0vWQtFrS3tRDX/cHd5nZhaxQEgLeY2Y7Ux3b/NXjJwXOmL0ck7Ss3BZBcXE9CDwgaV6JLu2SngpFPYD5JYbTCDSWuDc/GazyzvIar1Wt2KHSWEsIKA7lWklP+Azf4W3TJF2UtMHMBiuVcEzSN743M8Pb9rk1jFUsoZmNSvrYJZvlbZvrpcOhmnWYknZTtcD20L8rypT2UmG00picTtgLMS5HYATGBHwCJeAZklaVgPcW82P/T3ZvrWbTp0tnJ12Stvt19THFzLYCVzlstZltrUlC4E5Jz0laIel5v65pyGskLTSzjyQtzPV+85/O8oRYHGICHoERGIHB8heMnZYD3PlNlwAAAABJRU5ErkJggg==);
}

body.black-theme .student-homework-list .homework-table-header {
    background: #fff;
    border-color: #000;
}

body.black-theme .student-homework-list .homework-details .column {
    border-color: #fff;
    color: #fff;
}

body.black-theme .student-homework-list .homework-table-header .column {
    border-color: #000;
    color: #000;
}

body.black-theme .student-homework-list a.homework-book-link {
    color: #fff;
}

body.black-theme .student-homework-list .column.is-required input[type="checkbox"]:checked + label span {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTc0M0EyQjQxRkVBMTFFNTkwNTREQzlDNkYxNzM4MjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTc0M0EyQjUxRkVBMTFFNTkwNTREQzlDNkYxNzM4MjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFNzQzQTJCMjFGRUExMUU1OTA1NERDOUM2RjE3MzgyNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNzQzQTJCMzFGRUExMUU1OTA1NERDOUM2RjE3MzgyNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsQxp0QAAADhSURBVHjaYvz//z8DpYCJgQpg1BDqGSIDxHuBWAXMA0UxiVgRiO/9h4CN4CRCogEaQPwEasBxIBYg1RA9IH4BNWA/EPPC5Ig1wBSI30IN2A7EXMjyyM4EOU8eiwE2QPwRasA6IGZDVwNjrIcqegjEakgKXIH4K1RuKRCzYHMpjMEHxIehip8DsTYQ+wLxD6jYLCBmxuVdZA7In7ugmt4B8S8oeyIQM+ILM3QBdmjcw0AbMQGPTZAViFcAcRWx0c+Io1BiBCVmSvPO/9HyZNSQ0TJ2tIwdLWNHy9hhbQhAgAEAVNBP0xzc/PgAAAAASUVORK5CYII=) top no-repeat !important;
}

body.black-theme .student-homework-list .student-homework-file-upload-link,
body.black-theme .student-homework-list .student-homework-file-upload-link span,
body.black-theme .student-homework-list .student-homework-file-upload-link a {
    color: #fff;
    border-color: #fff;
}