.b-panel-visually-impaired-wrap {
	width: 100%;
	height: 50px;
	background: #000;
}

.b-panel-visually-impaired-head {
	width: 1120px;
	margin: 0 auto;
	color: #fff;
}

.b-panel-visually-impaired-call {
	display: table-cell;
	vertical-align: middle;
	height: 50px;
	width: 373px;
	text-align: center;
}

.b-panel-visually-impaired-call span {
	display: inline-block;
	margin-left: 5px;
	font-size: 14px;
	cursor: pointer;
}

span.b-zoom-font {
	width: 20px;
	border: 1px solid #fff;
	cursor: pointer;
}

span.b-zoom-font__active {
	background: #fff;
	color: #000;
}

body.white-theme {
	background: #fff;
}

body.white-theme .b-logo span,
body.white-theme .oezd-logo span {
	color: #000;
}

body.white-theme .b-news-title {
	background: #fff;
	color: #000;
	border-bottom: 1px solid #000;
}

body.white-theme header.header,
body.white-theme .b-header__user-account {
	background: #fff;
	border-bottom: 1px solid #000;
}

body.white-theme .b-top-menu__nav nav ul li > a {
	color: #000;
}

.b-ctb__nav nav > ul li > a:hover {
	background: #000;
}

body.white-theme .b-user,
body.white-theme .ul-labels:first-of-type {
	border-color: #000;
}

body.white-theme .b-user p,
body.white-theme .b-user p span.name,
body.white-theme .b-user p span,
body.white-theme .ul-labels a {
	color: #000;
}

body.white-theme .count-messenge,
body.white-theme i.count-solutions {
	background: #000;
	border: none;
}

body.white-theme .logout {
	color: #000;
	border-color: #000;
}

body.white-theme .wrapper,
body.white-theme .content {
	background: #fff;
}

body.white-theme i.m-title-icon-bg {
	background: #000;
	color: #fff !important;
}

body.white-theme .b-messange__controls a {
	color: #000;
}

body.white-theme .content {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	width: 1128px;
}

body.white-theme .b-right__sidebar {
	/*width: 1146px;*/
	width: 919px;
	min-height: 886px;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	background: #fff;
	box-sizing: border-box;
}

body.white-theme .top-menu .top-menu-group:hover .top-menu-pan {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	box-shadow: none;
	top: 1px;
}

body.white-theme .r-main_box__title {
	background: #fff;
	color: #000;
	border-bottom: 1px solid #000;
	height: 55px;
}

body.white-theme .b-events__right_nav:hover,
body.white-theme .b-events__right_nav:active,
body.white-theme .b-events__right_nav:focus,
body.white-theme .b-events__right_nav.open {
	border-color: #000;
	background: #000 !important;
}

body.white-theme .b-right__nav_sidebar {
	width: 154px;
}

body.white-theme .b-reference-form__title {
	width: 817px;
	background: #fff;
	/*border-right: 1px solid #000;*/
}

body.white-theme .b-reference-form__title_wide {
	width: calc(100% - 95px);
	background: #fff;
}

body.white-theme .b-events__right_nav.open {
	background: #fff;
}

body.white-theme div.b-events__right_nav {
	height: 56px;
	width: 58px;
	border-bottom: 1px solid #000 !important;
	box-sizing: border-box;
}

body.white-theme .b-reference-form__title span {
	color: #000;
}

body.white-theme .b-calendar,
body.white-theme .b-left__sidebar,
body.white-theme .b-choice-date__title,
body.white-theme .b-ctb__nav,
body.white-theme .closed-menu .b-choice-date,
body.white-theme .closed-menu .b-choice-date:hover {
	background: #fff;
	color: #000;
}

body.white-theme .b-nav_left-part a {
	color: #000;
}

body.white-theme .b-right__nav_sidebar {
	height: 886px;
	background: #fff;
}

body.white-theme .header-up select,
body.white-theme .b-right__nav_sidebar select,
body.white-theme .b-right__nav_sidebar input {
	background-color: #fff;
	border: 1px solid #000;
	color: #000;
}

body.white-theme .ls_active,
body.white-theme .ls_active .ls-link__name,
body.white-theme .b-nav_left-part a:hover,
body.white-theme .b-nav_left-part a.selected,
body.white-theme .b-nav_left-part-turned a:hover,
body.white-theme .b-nav_left-part a.selected {
	color: #fff;
	background: #000;
}

body.white-theme .b-calendar-events__title,
body.white-theme .b-calendar-events,
body.white-theme #calendar2,
body.white-theme #calendar2 thead tr:last-child td:nth-child(n+7),
body.white-theme #calendar2 thead tr:last-child td,
body.white-theme #calendar2 tbody td > span,
body.white-theme #calendar2 tbody td > span:hover,
body.white-theme #calendar2 thead tr:nth-child(1) td:nth-child(2),
body.white-theme .b-calendar__body {
	background: #fff;
	color: #000;
}

body.white-theme .b-events__wrapper header {
	background: #fff;
}

body.white-theme .b-events__search,
body.white-theme .b-events__right_nav {
	border-top: none;
	border-bottom: none;
	border-right: none;
	height: 56px;
}

body.white-theme .b-events__add,
body.white-theme .b-events__all,
body.white-theme .b-events__search,
body.white-theme .b-events__wrapper {
	border-bottom: 1px solid #000;
}

body.white-theme .b-events__all,
body.white-theme .b-events__search,
body.white-theme .b-events__right_nav {
	border-left: 1px solid #000;
}

body.white-theme .b-calendar__csv {
	border-top: 1px solid #000;
	background: #fff;
}

body.white-theme .b-calendar__csv a {
	color: #000;
}

body.white-theme .b-calendar-events {
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
}

body.white-theme .sprite-main-nav {
	background-color: #fff;
	border: 1px solid #000;
}

body.white-theme .b-nav_left-part__name {
	color: #000;
}

body.white-theme .b-infomation__sidebar {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	border-top: 1px solid #000;
	width: 1128px;
	background: #fff;
	color: #000;
}

body.white-theme .b-messange-title,
body.white-theme .b-messange__list {
	background: #fff;
	color: #000;
}

body.white-theme .b-infomation__sidebar .b-news_list span,
body.white-theme .b-news_list a {
	color: #000;
}

body.white-theme .b-news__controls {
	border-color: #000;
}

body.white-theme .b-messenge__more {
	border-left: 1px solid #000;
	border-bottom: 1px solid #000;
}

body.white-theme .b-messenge__more,
body.white-theme .b-messenge__write {
	padding-bottom: 21px;
}

body.white-theme .b-news__more {
	border-left: 1px solid #000;
}

body.white-theme .b-messenge__write {
	border-bottom: 1px solid #000;
}

body.white-theme .b-news__more a,
body.white-theme .b-messenge__more a,
body.white-theme .b-messenge__write a {
	color: #000;
}

body.white-theme .row-2.b-messange {
	background: #000;
	border: 1px solid #fff;
	border-right-color: #000;
	width: 561px;
}

body.white-theme .row-2.b-list-news {
	width: 565px;
}

body.white-theme .b-infomation__sidebar span {
	color: #fff;
}

/*right panel*/
body.white-theme .r-st-filter__title,
body.white-theme .r-st-filter__field-name {
	color: #000;
}

body.white-theme .r-st-filter__calendar {
	border: 1px solid #000;
}

body.white-theme .ts-container__variant {
	border: 1px solid #000;
	padding: 15px 30px;
	background: #fff;
}

body.white-theme .ts-container__variant span,
body.white-theme .ts-distribution_cabinet_auto span {
	color: #000;
}

body.white-theme .ts-container__variant-active {
	background: #000;
}

body.white-theme .ts-container__variant-active span {
	color: #fff;
}

body.white-theme .ts-marker_container {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDFBMjg5N0ExRkUzMTFFNThEMkJBQzE5MUVGQjhBNzEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDFBMjg5N0IxRkUzMTFFNThEMkJBQzE5MUVGQjhBNzEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMUEyODk3ODFGRTMxMUU1OEQyQkFDMTkxRUZCOEE3MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMUEyODk3OTFGRTMxMUU1OEQyQkFDMTkxRUZCOEE3MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Ptge/aoAAADmSURBVHjarFTLDoIwECyEpD3Jr6o39UA5qDf1V/FUTytbbKLt9CEwCaHJbqezz4qIxJpoErbcS1UpYalkQsT1TLLonSZHpvteGPOyZ6Wk0F0XI7VKq6+ikE80DE97ftxv9r/d7e2/bTeIeAqdCclDpzWNl+nzSPCxjX0AghxasDKnCoFtTr2PGuWsFNDXDzkVKgo9G7KUctGkBITcGq6aKbAPVzufQ9xnOIfAF1Y5p5Jt7AMHPNbYh+NpnBADG1spJa6XM2zsKCGamMiERAnnLoefjVOX7Lh/9mKTcFhtwS5RK94CDACILa/fNE+LxgAAAABJRU5ErkJggg==) no-repeat;
}

body.white-theme .b-rp-controls__tools ul > li {
	background: #000;
}

body.white-theme .support a {
	color: #000;
}

body.white-theme .ts-distribution_cabinet_auto {
	margin-top: 58px;
	border: 1px solid #000;
	padding: 8px 0px 8px 13px;
	width: 128px;
	background: #fff;
}

/*end right panel*/

/*footer*/
body.white-theme .b-footer {
	background: #fff;
}

body.white-theme .b-footer__copyrights,
body.white-theme .b-bottom-menu__nav nav ul li > a {
	color: #000;
}

body.white-theme footer.footer {
	border-top: 1px solid #000;
}

/*end footer*/

/*ДНЕВНИК*/
body.white-theme .b-diary-week-head__title,
body.white-theme .b-dl__dynamic-pause {
	background: #000;
	border-color: #fff;
}

body.white-theme .b-diary-week-head__title span,
body.white-theme .b-dl__dynamic-pause span {
	color: #fff !important;
}

body.white-theme .b-diary-st__body div {
	border-color: #000;
}

body.white-theme .b-diary-st__body div span {
	color: #000;
}

body.white-theme .b-dl-active,
body.white-theme .b-dl-td-hw-assessment:hover,
body.white-theme .b-dl-td_column:hover {
	background: #fff;
}

body.white-theme .b-dl-active span,
body.white-theme .b-dl-td-hw-assessment:hover span,
body.white-theme .b-dl-td_column:hover span {
	color: #000 !important;
}

body.white-theme div.b-dl__lessons-comment,
body.white-theme div.b-dl__time-comment {
	border-color: #000;
}

body.white-theme div.b-dl__lessons-comment span,
body.white-theme div.b-dl__time-comment span,
body.white-theme div.b-dl__lessons-comment:hover span,
body.white-theme div.b-dl__time-comment:hover span {
	color: #000 !important;
}

body.white-theme .i-icon__home-work {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjZDOTNGQjcxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjZDOTNGQjgxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNkM5M0ZCNTE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNkM5M0ZCNjE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvzqPbAAAABUSURBVHjaYvz//z8DNQETA5UB1Q1kQeZ0dXVhKCgrKxucXv4PxYMsDLEB9HAlFKbEupDoIKC/l0kNgkGTU3CG6eDPy0OstEECjCTyERKDvsQGCDAABpIVJlUI0EQAAAAASUVORK5CYII=) no-repeat;
	background-position: -6px -38px;
}

body.white-theme .i-icon__hw-timer {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OThDREEyRTNGNEIxMTFFNDk5MDRFRTU0NDc5MjA3RjUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OThDREEyRTRGNEIxMTFFNDk5MDRFRTU0NDc5MjA3RjUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5OENEQTJFMUY0QjExMUU0OTkwNEVFNTQ0NzkyMDdGNSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5OENEQTJFMkY0QjExMUU0OTkwNEVFNTQ0NzkyMDdGNSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnDnFkYAAADpSURBVHjaYvz//z8DNQETA5UB1Q1kAHkZhtGAFhDPAOJ7QPwbiu9BxbRwmoHFQEYgrocasBKIA4BYHYoDoGK/oWoYiTGwC4hfA7E9lM+GxWP2UDVdhAy0AuK/QGyNpLkdiOWwGGoNVWuFz8C1QLwYTaMFEBfjiAKQ2rX4DHwLxIFomkDhtArKNgFiGyQ5kNq3yGagJxshIH6KnhCA+BEQ9wNxMBCfQZJ7CtUDByxomt8BsTQWr00DYlEgPokmLg3VgzMdYgtDfIBgGGKLZVyAqFjGlg4ZKEmHNMkpFOVlxpFXHlLdQIAAAwD5b//nZXFm4wAAAABJRU5ErkJggg==) no-repeat;
	background-position: 0px 2px;
}

/*КОНЕЦ ДНЕВНИК*/
/******************/
/*------ДНЕВНИК ДЗ----------*/
body.white-theme .b-hw-page-hw__thead {
	border-color: #000;
	background: #fff;
}

body.white-theme .b-hw-page-hw__thead-column,
body.white-theme .b-hw-page-hw__tb-item-column {
	border-color: #000;
}

body.white-theme .b-hw-page-hw__thead span {
	color: #000;
}

body.white-theme .b-hw-page-hw__tb-item span {
	color: #000;
}

body.white-theme .b-hw-page-hw__tb-item-column a {
	color: #000;
	border-bottom: 1px dashed #000
}

body.white-theme .b-hw-page-hw__tb-item-control input[type="checkbox"]:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTc0M0EyQjQxRkVBMTFFNTkwNTREQzlDNkYxNzM4MjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTc0M0EyQjUxRkVBMTFFNTkwNTREQzlDNkYxNzM4MjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFNzQzQTJCMjFGRUExMUU1OTA1NERDOUM2RjE3MzgyNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNzQzQTJCMzFGRUExMUU1OTA1NERDOUM2RjE3MzgyNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsQxp0QAAADhSURBVHjaYvz//z8DpYCJgQpg1BDqGSIDxHuBWAXMA0UxiVgRiO/9h4CN4CRCogEaQPwEasBxIBYg1RA9IH4BNWA/EPPC5Ig1wBSI30IN2A7EXMjyyM4EOU8eiwE2QPwRasA6IGZDVwNjrIcqegjEakgKXIH4K1RuKRCzYHMpjMEHxIehip8DsTYQ+wLxD6jYLCBmxuVdZA7In7ugmt4B8S8oeyIQM+ILM3QBdmjcw0AbMQGPTZAViFcAcRWx0c+Io1BiBCVmSvPO/9HyZNSQ0TJ2tIwdLWNHy9hhbQhAgAEAVNBP0xzc/PgAAAAASUVORK5CYII=) top no-repeat;
	background-position: 37px 2px;
}

body.white-theme .b-hw-page-hw__tb-item-control input[type="checkbox"] + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDBCODQ2MkYxRkVCMTFFNTkzMEVFQ0EzMjVDNTdBRjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDBCODQ2MzAxRkVCMTFFNTkzMEVFQ0EzMjVDNTdBRjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMEI4NDYyRDFGRUIxMUU1OTMwRUVDQTMyNUM1N0FGOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMEI4NDYyRTFGRUIxMUU1OTMwRUVDQTMyNUM1N0FGOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjEaZQAAAAERSURBVHjaYvz//z8DpYBx1JBBZMjcuXNlgNRCIE5PTk6+w0SGAYpA6hAQOwFxL0iMiUQDNIDUYSAGGXQCiONJ8g7QAD0gtQuIxYH4ABD7Ab3ymWhDgAaYAqkdQCwEpYOBBnxDCVioM+cDcQRQ8iGaATZAaisQ8wHxeqiaX8hqYGHSDsQWoAADalJDMsAVSO2EGrAMiMPQDUB2CR/UNpCtL4DYBYiVgHg1ELMD8WwgzgQa8BdvOgEaxAWkNgAxyPb3QMwDxKxAPAmIC4AG/CcqsQENAtm6ChTyMG8CNVeRnGKBBoFsXwzEl4AGtBEV/yBD0PGcOXMYsYnjwqNFwagho2XsaBk7WsaOlrEj0xCAAAMAIwhbRBdp7ccAAAAASUVORK5CYII=) top no-repeat;
	background-position: 37px -19px;
}

body.white-theme .i-icon__homw-work001 {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjZDOTNGQjcxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjZDOTNGQjgxNDE5MTFFNThGQzlCM0VGMTgxRERBNDUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNkM5M0ZCNTE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNkM5M0ZCNjE0MTkxMUU1OEZDOUIzRUYxODFEREE0NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvzqPbAAAABUSURBVHjaYvz//z8DNQETA5UB1Q1kQeZ0dXVhKCgrKxucXv4PxYMsDLEB9HAlFKbEupDoIKC/l0kNgkGTU3CG6eDPy0OstEECjCTyERKDvsQGCDAABpIVJlUI0EQAAAAASUVORK5CYII=) top no-repeat;
}

/*------КОНЕЦ ДНЕВНИК ДЗ----------*/
/******************/
/*------КОНТАКТЫ----------*/
body.white-theme .b-contacts__thead,
body.white-theme .b-contacts__thead-column,
body.white-theme .b-contacts__item {
	border-color: #000;
	background: #fff;
}

body.white-theme .b-contacts__thead-column span {
	color: #000;
}

body.white-theme .b-contacts__item-user-col span,
body.white-theme .b-contacts__item span {
	color: #000;
}

/*-------КОНЕЦ КОНТАКТЫ---*/
/******************/


/* ВСЕ ОЦЕНКИ TRUE */
body.white-theme .progress-all-grades-table-header {
	background-color: #fff !important;
	color: #000;
	border-color: #000;
}

body.white-theme .progress-all-grades-table-body {
	color: #000;
	border-bottom: 1px solid #000;
	background-color: #fff;
}

body.white-theme .progress-all-grades-table-header div.table-col:nth-child(2) .b-bgt-ht-rotate_row-right,
 body.white-theme .progress-all-grades-table-header div.table-col:nth-child(2) .b-bgt-ht-rotate_row-left {
	 background-color: #000;
	 top: -2px;
	 padding: 9px 0;
	 border: 2px solid #000;
	 box-sizing: border-box;
 }

body.white-theme .progress-all-grades-table-header div.table-col:nth-child(2),
body.white-theme .progress-all-grades-table-body div.table-col:nth-child(2),
body.white-theme .progress-all-grades-module-list td,
body.white-theme .grades-module-info-title {
	border-color: #000;
}

body.white-theme .progress-all-grades-module-list td.selected {
	background-color: #fff;
	color: #000;
}

body.white-theme .grades-module-info-avg-mark {
	color: #000;
}

/* АНАЛИЗ ПРЕДЫДУЩИХ ПЕРИОДОВ */
body.white-theme .b-progress-arc-thead {
	border-color: #000;
	background: #fff;
}

body.white-theme .b-progress-arc-thead-column {
	border-color: #000;
}

body.white-theme .b-progress-arc-thead-column span {
	color: #000;
}

body.white-theme .b-progress-arc-tbody-item-col_lvl1 span,
body.white-theme .b-progress-arc-tbody-item-col_lvl0 span,
body.white-theme .b-progress-arc-tbody-item-col_lvl0:nth-child(1) span {
	color: #000;
}

body.white-theme .b-progress-arc-tbody-item__lvl1 {
	background: #fff;
}

body.white-theme .b-progress-arc-tbody-item__lvl0,
body.white-theme .b-progress-arc-thead-column,
body.white-theme .b-progress-arc-tbody-item-col_lvl0 {
	border-color: #000;
}

body.white-theme .b-progress-arc-tbody-item__lvl1:last-of-type,
body.white-theme .b-progress-arc-tbody-item__lvl1,
body.white-theme .b-progress-arc-tbody-item-col_lvl1 {
	border-color: #000;
}

body.white-theme .mrko-date-time-picker-input input {
	color: #000;
	background-color: #fff;
}

body.white-theme .mrko-date-time-picker-calendar,
body.white-theme .mrko-date-time-picker-input {
	border-color: #000;
	background-color: #fff;
}

body.white-theme .mrko-date-time-picker-calendar-header {
	color: #000;
	background-color: #fff;
}

body.white-theme .mrko-date-time-picker-calendar-days {
	border-color: #000;
}

body.white-theme .mrko-date-time-picker-calendar-weekdays span,
body.white-theme .mrko-date-time-picker-calendar-days span {
	color: #000;
}

body.white-theme .mrko-date-time-picker-calendar-days span:hover {
	background-color: #fff;
	color: #000 !important;
}

body.white-theme .mrko-date-time-picker-calendar-days span.weekend {
	color: #000;
}

body.white-theme .mrko-date-time-picker-calendar-days span.another-month-day {
	color: #000;
}

body.white-theme .apply_button {
	border-color: #000;
}

body.white-theme .apply_button a {
	color: #000;
}

body.white-theme .dropdown-profile-header,
body.white-theme .b-user p,
body.white-theme .b-user span.name {
	color: #000;
}

body.white-theme .b-icon__file002 {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAACyCAYAAABCzO/+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AMXCh0eWApwfQAABHpJREFUaN7t2l2IVVUUB/DfVCrINErTUJSaTgP1kGJUFCSTCRURpJFFFqEEGhEE9VAPNS9RYPTiW/rQh2UYPYhBEmJRQR8+ZFoPZUFZkfmd3dFRs+z0cNa14+3ce8/ce6UxzoLNOfucc/937bXXx15r764kSXSSztJhKgHHIKAkSTRRnYnYiE8yz6ZhIMvQSZwmgJMC6DgW4WK8hyTa17isKGAftuEIbg+OfsABLMOd+Bn7cU0zwCn4FhXMw0zsxq64r9JUfI9vGgEO4Cfsw7XRfg3uBnA2XsL78f1z6YjzAWdiL3bG/TwcCllNwXisD/k9Gs9+weY8wGnByXfByU04hs9DnhPxLk5gaXyzI34zKw/wZYygHxOwJ8AmRdscs31PcL8r5Dqz3ixX8GIM/fIY1iOYjS9jtm8LmR7IyLSuHiZ4Nt6Px/aMvu3BYEam20N+GgF+FsKfEN/0YGHoW3fo4tGMTDUDvCUE/g4m13x/H/7ARyFPRQDh3uBiH5bjIayJP9oYM200gMI2Xwt1SMIShkKuTZ1MVxmkSsD/dRgNBR/OeJhqO445hTFqABPMCudQbU+H+XUXATzF9Lq6uhLMCOdZpXPwaYSIozl4w0mSzMp+3Iz+xM24JOfdRdig5t+L0MFotfRbqYdnAGDeLPfkRL161NOK6TVrw3UtpZzlErAELBj1GprZ0NDQVZn+v36fJMnoOOzt7T2Fl9Mx5KQtwCKOJC8u16X+/v6rBwcHT/YrlYp169ZtaRmwDtddpaWUgGMNsIzLJWAJ2KlsNNuORemqLk7tov1cafF2c+ZZd2Zxfwdel9ZdPyyaBezG+XgSXTnvd0oLk1vwFR4okjxegRsiV87Ku0daOZ4tLegullb0nm+0tpmLJTU5cx6tkJb+TmBxS2ubGjqCK4PzVzqhNk9F9r+oU3r4V4hld2nLZ2ACvhDnaVARHg3gq9IK+jRNqsJFARfHdQmewfQCGBcWcQ7D0v2nHQUZGymXIiVgCdjOUqSiWEVuK8bllQjy0tvH/LORmkerpRuJb2BJUmu7OYF+QZNBfYCP8TvebKuIEbQlPNFh3FWkzFLL4XiszHG4C2T2lYty2I23cX9cu9tRmz5swlrpTuTa6PeNtrJUHfIqzI/76kpsfjyvO+Sis5y3tGtJhqfN9JZ3GnDlmFqKzIhFeTOa0UlvU22HGnqbMgSUgCVgM4/dwOwO4tKiOCdNL4L8Uum5myy9ECOZIz0iUxfwlEV7AN4YgbzWq2zFj/L3mGFvkiR3N0orsrQDt0rPKuXRAB7Ok2G13pBH46qLoxyamw1WRWd5WbSWPHY9DrULeAEejBm+Lp5NjlrDKunRrVEBjuCLqC5NjWfbQtYjrXB4GG/FfTUrXd8pGe7vhAyztKZVwAE5pyua0MBonUORVinjcglYApYJ+NhLwOtRS8njikx+3Bf9ttRmk3TvZE5cN7UbUzagN8CWqjnJ1wqH1+Nx6aHlJ6I/KsWunZTVmZg8Pfo6USLo2Cz/986hTMBLwBKwBMylvwGKZ8Yz5vrzwQAAAABJRU5ErkJggg==);
}
