.b-panel-visually-impaired-wrap {
	width: 100%;
	height: 50px;
	background: #000;
}

.b-panel-visually-impaired-head {
	width: 1120px;
	margin: 0 auto;
	color: #fff;
}

.b-panel-visually-impaired-call {
	display: table-cell;
	vertical-align: middle;
	height: 50px;
	width: 373px;
	text-align: center;
}

.b-panel-visually-impaired-call span {
	display: inline-block;
	margin-left: 5px;
	font-size: 14px;
	cursor: pointer;
}

span.b-zoom-font {
	width: 20px;
	border: 1px solid #fff;
	cursor: pointer;
}

span.b-zoom-font__active {
	background: #fff;
	color: #000;
}

body.black-theme {
	background: #000;
}

body.black-theme header.header,
body.black-theme .b-header__user-account {
	background: #fff;
}

body.black-theme .b-top-menu__nav nav ul li > a {
	color: #000;
}

.b-ctb__nav nav > ul li > a:hover {
	background: #fff;
}

body.black-theme .b-user,
body.black-theme .ul-labels:first-of-type {
	border-color: #000;
}

body.black-theme .b-user p,
body.black-theme .b-user p span.name,
body.black-theme .b-user p span,
body.black-theme .ul-labels a {
	color: #000;
}

body.black-theme .count-messenge,
body.black-theme i.count-solutions {
	background: #000;
	border: none;
}

body.black-theme .logout {
	color: #000;
	border-color: #000;
}

body.black-theme .wrapper,
body.black-theme i.m-title-icon-bg,
body.black-theme .content {
	background: #000;
}

body.black-theme .b-news-title,
body.black-theme i.m-title-icon-bg {
	border: 1px solid #fff;
}

body.black-theme .b-news-title {
	color: #fff;
	background: #000;
}

body.black-theme .content {
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	width: 1128px;
}

body.black-theme .b-infomation__sidebar {
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	border-bottom: 1px solid #fff;
	width: 1128px;
	background: #000;
	color: #fff;
}

body.black-theme .b-infomation__sidebar span {
	color: #fff;
}

body.black-theme .b-messange-title {
	background: #000;
	border-top: 1px solid #fff;
}

body.black-theme .b-news__more,
body.black-theme .b-messenge__more,
body.black-theme .b-messenge__write {
	border-left: 1px solid #fff;
	border-bottom: 1px solid #fff;
}

body.black-theme .row-2.b-messange {
	background: #000;
	border: 1px solid #fff;
}

body.black-theme .b-right__sidebar {
	/*width: 1146px;*/
	width: 919px;
	min-height: 941px;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	background: #000;
	box-sizing: border-box;
}

body.black-theme .r-main_box__title {
	background: #000;
	color: #fff;
	border-bottom: 1px solid #fff;
}

body.black-theme .b-reference-form__title {
	width: 821px;
	background: #000;
}

body.black-theme .b-reference-form__title_wide {
	width: calc(100% - 95px);
	background: #000;
}

body.black-theme .b-events__right_nav.open,
body.black-theme .b-events__right_nav,
body.black-theme .b-events__wrapper header,
body.black-theme .b-events__wrapper {
	background: #fff;
}

body.black-theme div.b-events__right_nav {
	height: 55px;
}

body.black-theme .b-reference-form__title span {
	color: #fff;
}

body.black-theme .b-logo span {
	color: #000;
}

body.black-theme .header-up select,
body.black-theme .b-right__nav_sidebar select,
body.black-theme .b-right__nav_sidebar input {
	background-color: #fff;
	border: 1px solid #000;
	color: #000;
}

body.black-theme .ls_active,
body.black-theme .b-nav_left-part a:hover,
body.black-theme .b-nav_left-part a.selected,
body.black-theme .b-nav_left-part-turned a:hover,
body.black-theme .b-nav_left-part a.selected {
	color: #000;
	background: #fff;
}

body.black-theme .b-calendar-events__title,
body.black-theme .b-calendar-events,
body.black-theme #calendar2,
body.black-theme #calendar2 thead tr:last-child td:nth-child(n+7),
body.black-theme #calendar2 thead tr:last-child td,
body.black-theme #calendar2 tbody td > span,
body.black-theme #calendar2 tbody td > span:hover,
body.black-theme #calendar2 thead tr:nth-child(1) td:nth-child(2),
body.black-theme .b-calendar__body {
	background: #fff;
	color: #000;
}

body.black-theme .b-calendar,
body.black-theme .b-left__sidebar,
body.black-theme .b-choice-date__title,
body.black-theme .b-ctb__nav,
body.black-theme .closed-menu .b-choice-date,
body.black-theme .closed-menu .b-choice-date:hover {
	background: #000;
	color: #fff;
}

body.black-theme .b-right__nav_sidebar {
	height: 886px;
	background: #fff;
}

/*right panel*/
body.black-theme .r-st-filter__title,
body.black-theme .r-st-filter__field-name {
	color: #000;
}

body.black-theme .r-st-filter__calendar {
	border: 1px solid #000;
}

body.black-theme .ts-container__variant {
	border: 1px solid #000;
	padding: 15px 30px;
	background: #fff;
}

body.black-theme .ts-container__variant span,
body.black-theme .ts-distribution_cabinet_auto span {
	color: #000;
}

body.black-theme .ts-container__variant-active {
	background: #000;
}

body.black-theme .ts-container__variant-active span {
	color: #fff;
}

body.black-theme .ts-marker_container {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDFBMjg5N0ExRkUzMTFFNThEMkJBQzE5MUVGQjhBNzEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDFBMjg5N0IxRkUzMTFFNThEMkJBQzE5MUVGQjhBNzEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMUEyODk3ODFGRTMxMUU1OEQyQkFDMTkxRUZCOEE3MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMUEyODk3OTFGRTMxMUU1OEQyQkFDMTkxRUZCOEE3MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Ptge/aoAAADmSURBVHjarFTLDoIwECyEpD3Jr6o39UA5qDf1V/FUTytbbKLt9CEwCaHJbqezz4qIxJpoErbcS1UpYalkQsT1TLLonSZHpvteGPOyZ6Wk0F0XI7VKq6+ikE80DE97ftxv9r/d7e2/bTeIeAqdCclDpzWNl+nzSPCxjX0AghxasDKnCoFtTr2PGuWsFNDXDzkVKgo9G7KUctGkBITcGq6aKbAPVzufQ9xnOIfAF1Y5p5Jt7AMHPNbYh+NpnBADG1spJa6XM2zsKCGamMiERAnnLoefjVOX7Lh/9mKTcFhtwS5RK94CDACILa/fNE+LxgAAAABJRU5ErkJggg==) no-repeat;
}

body.black-theme .b-rp-controls__tools ul > li {
	background: #000;
}

body.black-theme .support a {
	color: #000;
}

body.black-theme .ts-distribution_cabinet_auto {
	margin-top: 58px;
	border: 1px solid #000;
	padding: 8px 0px 8px 13px;
	width: 128px;
	background: #fff;
}

/*end right panel*/

/*footer*/
body.black-theme .b-footer {
	background: #000;
}

body.black-theme .footer {
	height: 121px;
}

/*end footer*/

body.black-theme .dropdown-profile-list li:hover,
body.black-theme .dropdown-profile-list li.selected {
	background-color: #fff;
	color: #000;
	border: 1px solid #000;
}

/*ДНЕВНИК*/
body.black-theme .b-diary-week-head__title,
body.black-theme .b-dl__dynamic-pause {
	background: #fff;
	border-color: #fff;
}

body.black-theme .b-diary-week-head__title span,
body.black-theme .b-dl__dynamic-pause span {
	color: #000 !important;
}

body.black-theme .b-diary-st__body div {
	border-color: #fff;
}

body.black-theme .b-diary-st__body div span {
	color: #fff;
}

body.black-theme .b-dl-active,
body.black-theme .b-dl-td-hw-assessment:hover,
body.black-theme .b-dl-td_column:hover {
	background: #fff;
}

body.black-theme .b-dl-td_column:hover span {
	color: #000;
}

body.black-theme .b-dl__time-comment span {
	color: #000;
}

body.black-theme div.b-dl__lessons-comment,
body.black-theme div.b-dl__comment,
body.black-theme div.b-dl__time-comment {
	border-color: #000;
}

body.black-theme div.b-dl__lessons-comment span,
body.black-theme div.b-dl__time-comment span,
body.black-theme div.b-dl__lessons-comment:hover span,
body.black-theme div.b-dl__time-comment:hover span {
	color: #000 !important;
}

body.black-theme .i-icon__home-work {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAABjCAYAAAD+Q9ILAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjJBMjE2RTkxRkU1MTFFNUE1MzBCMzQ3RjkxQjIwOTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjJBMjE2RUExRkU1MTFFNUE1MzBCMzQ3RjkxQjIwOTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMkEyMTZFNzFGRTUxMUU1QTUzMEIzNDdGOTFCMjA5OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMkEyMTZFODFGRTUxMUU1QTUzMEIzNDdGOTFCMjA5OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pu/UB7gAAAHISURBVHja7Ji7SwNBEIcTEx9Y+OgtVASx0sJOsPVR5S+w19JabO3sbAQrS0sFSxHBQgQfjWKjiHZaJIJvcv4W9uBYIs7csbcH/hY+CJfJ8mV3drmZchRFpSKMtlJBBkUoQhGK5CmyACIFDY1IWXHFX4NRMCSIHQBHZn4fInGgZPJBcKsRqWbYVvPbRVBJPNsGz2knSzu+waGTZ40s/ypLovc5Iu3gK28Rs//9Th50gNe8RZrgzhF5C7E1JknHna25Bx+plldxfD9tDng5vpqb9d3nFV9VbkXyYgsm0gU2wZogdgecqkxMjggxY04QVwFNMKaYW7UiF2AKXAliH8C0MFZ9ambArj05kvECenyI8A2NIhShCEUoQhF2A9gNYDeA3QB2A9gNYDeA3QB2A/iqSBGKUIQiFKEIRf6xSP2P5syxLUO9FlhxKbEMDlp8twVGwCOYtVWf15LTVHDnv7yj7oNhuzLz4DJUjkyCG/v5JFSyroM98ATOQKevAisusmqCuJqN9VJgtWrUbIDuxLMVW1zl2h8xJ2gpRBEuWZFV7bEt3IoU5opPsyKmBT4hiPF6xdcVFb5p/vayG0ARilCEIhShiDN+BBgAULLVPkheFQIAAAAASUVORK5CYII=) no-repeat;
	background-position: -6px -38px;
}

body.black-theme .i-icon__hw-timer {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjNGNkE5MDAxRkU2MTFFNUE0MTRGQjVGMDIwN0QxNkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjNGNkE5MDExRkU2MTFFNUE0MTRGQjVGMDIwN0QxNkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCM0Y2QThGRTFGRTYxMUU1QTQxNEZCNUYwMjA3RDE2QiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCM0Y2QThGRjFGRTYxMUU1QTQxNEZCNUYwMjA3RDE2QiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlXchT0AAAENSURBVHja7NQxagJREMbxfSIp7BSshLTCktIiaMArKNa5hDmApWBjJ9tbJbAHEdJ4gS0EK0lKmyWs/8CnLuOLrCipfPCD3XkzgzijLsuy4JanFNz4/GvDEBESpJIoFl7S0GGEJaoY4kmGii2V406qf4diTLBBV+8Pnpyucib2zia28YNOLjbGo6dpR7ntcw1jzE3sGW+ehoFy43MNv9A3MYcPPbfwkrvrq+aQb4dSw9p+zVhhigE+c3dr1RxO2RR/o+GZ/Ax1LEy8oZrjipifXowtXgvu8RwVfXLv2vim/JdCU/btYXDNHu6nOkKKd/TQlJ5iqXJckYZ7ISIkapDqOdKdt87d/w+vPjsBBgCzKBsXPn6ioQAAAABJRU5ErkJggg==) no-repeat;
	background-position: 0px 2px;
}

/*КОНЕЦ ДНЕВНИК*/
/******************/
/*------ДНЕВНИК ДЗ----------*/
body.black-theme .b-hw-page-hw__thead {
	border-color: #fff;
	background: #fff;
}

body.black-theme .b-hw-page-hw__thead span {
	color: #000;
}

body.black-theme .b-hw-page-hw__tb-item span {
	color: #fff;
}

body.black-theme .b-hw-page-hw__tb-item-column a {
	color: #fff;
	border-bottom: 1px dashed #fff
}

body.black-theme .b-hw-page-hw__tb-item-control input[type="checkbox"]:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTc0M0EyQjQxRkVBMTFFNTkwNTREQzlDNkYxNzM4MjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTc0M0EyQjUxRkVBMTFFNTkwNTREQzlDNkYxNzM4MjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFNzQzQTJCMjFGRUExMUU1OTA1NERDOUM2RjE3MzgyNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNzQzQTJCMzFGRUExMUU1OTA1NERDOUM2RjE3MzgyNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsQxp0QAAADhSURBVHjaYvz//z8DpYCJgQpg1BDqGSIDxHuBWAXMA0UxiVgRiO/9h4CN4CRCogEaQPwEasBxIBYg1RA9IH4BNWA/EPPC5Ig1wBSI30IN2A7EXMjyyM4EOU8eiwE2QPwRasA6IGZDVwNjrIcqegjEakgKXIH4K1RuKRCzYHMpjMEHxIehip8DsTYQ+wLxD6jYLCBmxuVdZA7In7ugmt4B8S8oeyIQM+ILM3QBdmjcw0AbMQGPTZAViFcAcRWx0c+Io1BiBCVmSvPO/9HyZNSQ0TJ2tIwdLWNHy9hhbQhAgAEAVNBP0xzc/PgAAAAASUVORK5CYII=) top no-repeat;
	background-position: 37px 2px;
}

body.black-theme .b-hw-page-hw__tb-item-control input[type="checkbox"] + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDBCODQ2MkYxRkVCMTFFNTkzMEVFQ0EzMjVDNTdBRjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDBCODQ2MzAxRkVCMTFFNTkzMEVFQ0EzMjVDNTdBRjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMEI4NDYyRDFGRUIxMUU1OTMwRUVDQTMyNUM1N0FGOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMEI4NDYyRTFGRUIxMUU1OTMwRUVDQTMyNUM1N0FGOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjEaZQAAAAERSURBVHjaYvz//z8DpYBx1JBBZMjcuXNlgNRCIE5PTk6+w0SGAYpA6hAQOwFxL0iMiUQDNIDUYSAGGXQCiONJ8g7QAD0gtQuIxYH4ABD7Ab3ymWhDgAaYAqkdQCwEpYOBBnxDCVioM+cDcQRQ8iGaATZAaisQ8wHxeqiaX8hqYGHSDsQWoAADalJDMsAVSO2EGrAMiMPQDUB2CR/UNpCtL4DYBYiVgHg1ELMD8WwgzgQa8BdvOgEaxAWkNgAxyPb3QMwDxKxAPAmIC4AG/CcqsQENAtm6ChTyMG8CNVeRnGKBBoFsXwzEl4AGtBEV/yBD0PGcOXMYsYnjwqNFwagho2XsaBk7WsaOlrEj0xCAAAMAIwhbRBdp7ccAAAAASUVORK5CYII=) top no-repeat;
	background-position: 37px -19px;
}

body.black-theme .i-icon__homw-work001 {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkE2MkM1ODUxRkVCMTFFNUExNTBDQzI2MTVCM0Q3NDkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkE2MkM1ODYxRkVCMTFFNUExNTBDQzI2MTVCM0Q3NDkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2QTYyQzU4MzFGRUIxMUU1QTE1MENDMjYxNUIzRDc0OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2QTYyQzU4NDFGRUIxMUU1QTE1MENDMjYxNUIzRDc0OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhvPSlMAAABSSURBVHjaYvz//z8DuYAFShsCMRMBtSBbrgLxT5gAExqNDzACsRYQczKQoAkZgNRrADE3OZphetTJ1QzzAtmaGUY1D0nN/0jUB1bPSEmWBAgwAO3SC6zdSxFTAAAAAElFTkSuQmCC) top no-repeat;
}

/*------КОНЕЦ ДНЕВНИК ДЗ----------*/
/******************/
/*------КОНТАКТЫ----------*/
body.black-theme .b-contacts__thead {
	border-color: #fff;
	background: #fff;
}

body.black-theme .b-contacts__thead-column span {
	color: #000;
}

body.black-theme .b-contacts__item-user-col span,
body.black-theme .b-contacts__item span {
	color: #fff;
}

/*-------КОНЕЦ КОНТАКТЫ---*/


/*------УСПЕВАЕМОСТЬ ВСЕ ОЦЕНКИ----------*/
body.black-theme .b-paa-header__column {
	background: #fff;
}

body.black-theme .b-paa-header__column span {
	color: #000;
}

body.black-theme .b-paa-body__column span {
	color: #fff;
}

body.black-theme .b-paa_conteiner-active {
	background: #fff;
}

body.black-theme .b-paa_conteiner-active .b-paa_asssisment_theme span,
body.black-theme .b-paa_conteiner-active .b-paa_asssisment span {
	color: #000 !important;
}

/*------КОНЕЦ УСПЕВАЕМОСТЬ ВСЕ ОЦЕНКИ----------*/

.b-header__user-account { /*родительский блок пользователя в шапке*/
	position: relative;
}

.b-main-visually_impaired {
	background: #000;
	padding: 0px 5px 5px 5px;
	width: 183px;
	position: absolute;
	left: -193px;
	cursor: pointer;
}

.b-main-visually_impaired span {
	font-size: 10px;
	line-height: 11px;
	color: #fff;
}

.b-main-visually_impaired .i-icon__glasses {
	position: relative;
	top: 5px;
}

.i-icon__glasses {
	display: inline-block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAYAAACeXl35AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUZENzQ3MTMxRUZBMTFFNTg0NEVDRENBN0Y3RTIxNkEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NUZENzQ3MTQxRUZBMTFFNTg0NEVDRENBN0Y3RTIxNkEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1RkQ3NDcxMTFFRkExMUU1ODQ0RUNEQ0E3RjdFMjE2QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1RkQ3NDcxMjFFRkExMUU1ODQ0RUNEQ0E3RjdFMjE2QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsmiEisAAAE/SURBVHjaYvz//z8DPQETA53BqIUmVLJHH8ZgwaGADYizgVgEiDWAeDUQ/yTDIpD56UAsBTVnHS4fRgPxOSCuBmIuIJYgwzJmIC4F4kdQc4SBWJwRLVuIAnE/EH8D4jS0oP+HZiDIAGcg/gvEu4D4M5p8GRDfBuL1KI4AWQjFUUD8+T8CPAZicyR5ZBwExB+R1L4GYmeoHMgThUAcjE0vzIcKQHwfS7B8hwYpKyg4gPgJEPMD8QccwQjytT00DazEFakM0GDEBjiBeA0QXwBiDiD+CsSeeOJtFRAXA/FFfKkIBBTxGAKyZDYQvwZiaSD2waP2Fj7LwAAatgH/cQM5tHiwxqNWH0ecwzEyZyMWAzpwaFyCRe1kQpYhJxoYyIfG0S9opC/FEzipQOwLZYPieRExmZNxtHoatZBUABBgAAmZTsIsM72UAAAAAElFTkSuQmCC) no-repeat;
	background-position: 0px 0px;
	width: 28px;
	height: 20px;
}

/* ВСЕ ОЦЕНКИ TRUE */
body.black-theme .progress-all-grades-table-header {
	background-color: #fff !important;
	color: #000;
	border-color: #fff;
}

body.black-theme .progress-all-grades-table-body {
	color: #fff;
	border-bottom: 1px solid #fff;
	background-color: #000;
}

body.black-theme .progress-all-grades-table-header div.table-col:nth-child(2) .b-bgt-ht-rotate_row-right,
body.black-theme .progress-all-grades-table-header div.table-col:nth-child(2) .b-bgt-ht-rotate_row-left {
	background-color: #000;
	top: -2px;
	padding: 10px 0;
	border: 1px solid #fff;
}

body.black-theme .progress-all-grades-table-header div.table-col:nth-child(2),
body.black-theme .progress-all-grades-table-body div.table-col:nth-child(2),
body.black-theme .progress-all-grades-module-list td,
body.black-theme .grades-module-info-title {
	border-color: #fff;
}

body.black-theme .progress-all-grades-module-list td.selected {
	background-color: #fff;
	color: #000;
}

body.black-theme .grades-module-info-avg-mark {
	color: #000;
}

/* АНАЛИЗ ПРЕДЫДУЩИХ ПЕРИОДОВ */
body.black-theme .b-progress-arc-thead {
	border-color: #fff;
	background: #fff;
}

body.black-theme .b-progress-arc-thead-column {
	border-color: #000;
}

body.black-theme .b-progress-arc-thead-column span {
	color: #000;
}

body.black-theme .b-progress-arc-tbody-item-col_lvl1 span,
body.black-theme .b-progress-arc-tbody-item-col_lvl0 span,
body.black-theme .b-progress-arc-tbody-item-col_lvl0:nth-child(1) span {
	color: #fff;
}

body.black-theme .b-progress-arc-tbody-item__lvl1 {
	background: #000;
}

body.black-theme .b-progress-arc-tbody-item__lvl0,
body.black-theme .b-progress-arc-thead-column,
body.black-theme .b-progress-arc-tbody-item-col_lvl0 {
	border-color: #fff;
}

body.black-theme .b-progress-arc-tbody-item__lvl1:last-of-type,
body.black-theme .b-progress-arc-tbody-item__lvl1,
body.black-theme .b-progress-arc-tbody-item-col_lvl1 {
	border-color: #fff;
}

body.black-theme .mrko-date-time-picker-input input {
	color: #fff;
	background-color: #000;
}

body.black-theme .mrko-date-time-picker-calendar,
body.black-theme .mrko-date-time-picker-input {
	border-color: #fff;
	background-color: #000;
}

body.black-theme .mrko-date-time-picker-calendar-header {
	color: #fff;
	background-color: #000;
}

body.black-theme .mrko-date-time-picker-calendar-days {
	border-color: #fff;
}

body.black-theme .mrko-date-time-picker-calendar-weekdays span,
body.black-theme .mrko-date-time-picker-calendar-days span {
	color: #fff;
}

body.black-theme .mrko-date-time-picker-calendar-days span:hover {
	background-color: #fff;
	color: #000 !important;
}

body.black-theme .mrko-date-time-picker-calendar-days span.weekend {
	color: #fff;
}

body.black-theme .mrko-date-time-picker-calendar-days span.another-month-day {
	color: #fff;
}

body.black-theme .apply_button {
	border-color: #000;
	background-color: #000;
}

body.black-theme .apply_button a {
	color: #fff;
}

body.black-theme .b-icon__file002 {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAACyCAYAAABCzO/+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AMXChAVenbXuAAABNFJREFUaN7tml2IVVUUx//LZkYYdK70QZGjOTWgD41MVBg0lAkVkaSDJmmEEmhEENRDPVQvToHRy7ylD31YRtFDGCghY2hS5oNT1kNa0GiR6eiM5jijpuWvB9etw5l9zz33oxhpb9jce/bZ93fWXnutvc7a+xqgepZJqnOJwAkIFKAs0wGagW3A7kTbTKAdmDSOkwUECsBu4DywHJgOfMo/ZT8wOxcQuAbYB5wBHnKJDgHDwBpgCfALMATcngkEWoEfgFPAAqADOAocAToS/WYAA8D3JYEuyc/AcWCe1xMuXTtwBfAmsMP7v+rDHw90SY4Bh/37AuC066oVaAI2O+AZb/sV2DMO6DN3AvjRJbkXOAd85fpsBrYDfwKrvc9B/83cEPAtYAy4EZgMDDqs4HWPz/YjLv0R12tHcJZ9At7wm3N8WE8DncC3PtsPuk6HizotaYcOeMVvNgEHEvY2CNyV0OkBoDXoIAngXlf+ZO/QAix1e5vitni2qNOSHpcA3u8K/wSYlur8KHAB+BwoZLpwymxWuBTHgXXAk8Amf9A2oLnsmhAw7NnAu24OuCe8BDTlWWQsBqkI/D8ARySRqucldZmZStWgyySWsLnAtERd6+43pZzbhVwPSW1mdijRuUHSl5JmSjob4I1Imlu8aCinEzP7A7hP0g2B29dL2ppsaMijaDM7KelkYKi/RTu8DIChWW5JR72M0jLOIlKGPSJpaoVCnU6CY5CKwAisBkhW7enpubX4PRjxKpVweHg4ufxT9yGHoBUBQ0Mst3xlLj0DAwO37dq16+/rQqGg7u7u/qqBpYSKhh2BE/3NwaIOIzACq8xGk/WcpCVZGWna9aZKWi5pT6JtSqJft6T3gCEz+yxvFnBU0tWSXijh24clbQf6JX1nZo/nSR5vlnS3pLUp9bRI2iepU9IMSSuB/Wb2Wp5sdMTMejPeGnp9BOuAY5I21jrLZyTd4oy362E2L0pq80ms3Q7N7KJvJRyNrncZJuBLJV0pqbkewHckterSLlJTzUAzW+lutUrSy8CsHIzr8iwOI5KmSzqYU7CxuEUQgRFYC/BUuW0Cr19Lagzlz6H09llJOzKE2ChpjqQPJK0alxIHdtoXl9kW2Al8AfwOfJj6fVU67PeVaFTSw6EnZkrox5rr08dwwOLkuXIuCf1AYYukxyRtyTpgKGs2fv7ZJ+l9Scf9sy90LppryMAGYJF/P+Sfi4ANWUPONctFYM06/Dddb11dgWa2fkK9irQBnTl+2xYcTWpxOKXAgUFGGVXq7CAG+giMwEqBpYL8SUk3ZZ2AJwN+0vWQtFrS3tRDX/cHd5nZhaxQEgLeY2Y7Ux3b/NXjJwXOmL0ck7Ss3BZBcXE9CDwgaV6JLu2SngpFPYD5JYbTCDSWuDc/GazyzvIar1Wt2KHSWEsIKA7lWklP+Azf4W3TJF2UtMHMBiuVcEzSN743M8Pb9rk1jFUsoZmNSvrYJZvlbZvrpcOhmnWYknZTtcD20L8rypT2UmG00picTtgLMS5HYATGBHwCJeAZklaVgPcW82P/T3ZvrWbTp0tnJ12Stvt19THFzLYCVzlstZltrUlC4E5Jz0laIel5v65pyGskLTSzjyQtzPV+85/O8oRYHGICHoERGIHB8heMnZYD3PlNlwAAAABJRU5ErkJggg==);
}

body.black-theme .dropdown-profile-header,
body.black-theme .b-user p,
body.black-theme .b-user span.name {
	color: #000;
}
