.report-ua-table {
	font-size: 12px;
	line-height: 13px;
	color: #454856;
	width: 860px;
}

.report-ua-table .is-warning {
	color: #f44336;
	font-weight: bold;
}

.report-ua-table-head-wrap, .report-ua-table-body-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-content: stretch;
}

.report-ua-table-head-wrap .col, .report-ua-table-body-wrap .col {
	flex: 0 1 auto;
	align-self: auto;
	padding: 4px 5px;
	border-top: 1px solid #cfd4d7;
	border-left: 1px solid #cfd4d7;
}

.report-ua-table-body-wrap .col-parent {
	min-height: 26px;
}

.report-ua-table-head-wrap .col {
	font-weight: bold;
	background: #ebf2f5;
	text-align: center;
	position: relative;
	font-size: 12px;
	color: #6c8796;
}

.report-ua-table-head-wrap .col:last-child, .report-ua-table-body-wrap .col:last-child {
	border-right: 1px solid #cfd4d7;
}

.report-ua-table .col-student-name {
	width: 220px;
}

.report-ua-table .col-last-sign-in {
	width: 114px;
}

.report-ua-table .col-parent-name-header {
	width: 255px;
}

.report-ua-table .col-phone-header {
	width: 90px;
}

.report-ua-table .col-last-sign-in-parent-header {
	width: 129px;
}

.report-ua-table-body-wrap .col-last-sign-in {
	cursor: pointer;
}

.report-ua-table-body-wrap .col-parent-name-header,
.report-ua-table-body-wrap .col-last-sign-in-parent-header,
.report-ua-table-body-wrap .col-phone-header {
	padding: 0;
}
.report-ua-table-body-wrap .col-parent-name-header {
	width: 265px;
}

.report-ua-table-body-wrap .col-last-sign-in-parent-header {
	width: 139px;
	cursor: pointer;
}

.report-ua-table-body-wrap .col-phone-header {
	width: 100px;
}

.report-ua-table-body-wrap .col-parent-name-header div,
.report-ua-table-body-wrap .col-last-sign-in-parent-header div,
.report-ua-table-body-wrap .col-phone-header div
{
	padding: 4px 5px;
	border-bottom: 1px solid #cfd4d7;
}

.report-ua-table-body-wrap .col-parent-name-header div:last-child,
.report-ua-table-body-wrap .col-last-sign-in-parent-header div:last-child,
.report-ua-table-body-wrap .col-phone-header div:last-child {
	border-bottom: 0;
}

.report-ua-table-body-wrap:last-child .col {
	border-bottom: 1px solid #cfd4d7;
}

.active {
	background-color: #fff !important;
	font-weight: bold;
}
.active	span {
	color: #3b5777;
}
.report-ua-table .is-warning_print {
	color: #444b53 !important;
}
