.b-progress__dynamics {
    width: 878px;
    margin: 30px auto;
}

.b-progress__dynamics-thead {
    background: #ebf2f5;
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
}

.b-progress__dynamics-thead-column,
.b-progress__dynamics-tbody-item-column {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    border-right: 1px solid #cfd4d7;
    padding: 5px 5px;
    width: 38px;
    height: 38px;
    text-align: center;
    z-index: 1;
}

.b-progress__dynamics-thead-column-rotate-wrapper,
.b-progress__dynamics-items-column-rotate-wrapper {
    overflow: hidden;
    width: 588px;
    height: 48px;
    position: relative;
    text-align: left;
    z-index: 1;
}

.b-progress__dynamics-items-column-rotate-wrapper {
    display: table-cell;
}

.b-progress-clear-padding {
    padding: 0;
}

.b-progress__dynamics-thead-column-rotate-content,
.b-progress__dynamics-items-column-rotate-content {
    position: absolute;
    height: 48px;
    width: 2600px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: left 0.2s ease-in-out;
    -moz-transition: left 0.2s ease-in-out;
    -ms-transition: left 0.2s ease-in-out;
    -o-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out;
}

.b-progress__dynamics-thead-column-item,
.b-progress__dynamics-cell-column-item {
    border-right: 1px solid #cfd4d7;
    padding: 15px 5px;
    width: 38px;
    /*height        : 38px;*/
    text-align: center;
    display: inline-block;
}

.b-progress__dynamics-cell-column-item {
    border-bottom: 1px solid #cfd4d7;
    height: 18px;
}

.b-progress__dynamics-thead-left,
.b-progress__dynamics-thead-right {
    position: absolute;
    background-color: #cfd4d7;
    height: 80px;
    z-index: 999;
    cursor: pointer;
    top: -2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.b-progress__dynamics-thead-left i,
.b-progress__dynamics-thead-right i {
    margin-top: 28px;
}

.b-progress__dynamics-thead-left {
    left: -17px;
}

.b-progress__dynamics-thead-right {
    right: -17px;
}

.b-progress__dynamics-tbody-item-column {
    border-bottom: 1px solid #cfd4d7;
}

.b-progress__dynamics-thead-column:nth-child(1),
.b-progress__dynamics-tbody-item-column:nth-child(1) {
    width: 278px;
    text-align: left;
    line-height: 13px;
}

.b-progress__dynamics-thead-column:nth-child(1) {
    height: 64px;
}

.b-progress__dynamics-thead-weeks-header {
    width: 588px;
    text-align: center;
    padding: 3px 0;
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
    border-bottom: 2px solid #cfd4d7;
}

.hide-knowledge-subjects .b-progress-arc-tbody-item__lvl1 {
    display: none !important;
}

.b-progress__dynamics-thead-column span {
    font-size: 14px;
    color: #454856;
    font-weight: bold;
}

.b-progress__dynamics-thead-column:nth-child(1) span {
    font-size: 12px;
    font-weight: bold;
    color: #6c8796;
}

.b-progress__dynamics-tbody-item-column span {
    font-size: 14px;
    color: #454856;
}

.b-progress__dynamics-footer-wrap {
    border-top: 3px solid var(--color-button-5);
    background: #faf9fb;
}

.b-progress__dynamics-f_title {
    background: #fff;
    padding: 5px 20px;
}

.b-progress__dynamics-f_title span {
    font-weight: bold;
    font-size: 11px;
    color: #454856;
}

.b-progress__dynamics-f-graph-conteiner {
    border-bottom: 1px solid #cfd4d7;
    padding: 20px 20px;
}

.b-progress__dynamics-f-graph-conteiner:last-of-type {
    border-bottom: none;
}

.ts-marker_container.progress-dynamic-filter-control {
    top: 37px !important;
}

.ts-marker_container.progress-dynamic-filter-all {
    top: 85px !important;
}

.ts-container__variant {
    margin-bottom: 2px;
}

.ts-container__variants.progress-dynamic .ts-container__variant {
    padding: 16px 0;
    text-align: center;
}

.chart-element {
    width: 878px;
    height: 500px;
}

.b-progress__dynamics-f-graph-conteiner circle {
    display: none;
}

.mark-info-hint {
    display: block;
    padding: 5px 0;
}

.header-week-info,
.body-week-info {
    position: absolute;
    padding: 5px 2px;
    border-top: 5px solid var(--color-button-5);
    box-shadow: 0 0 5px #cfd4d7;
    background: #fff;
    line-height: 12px;
    text-align: left;
    font-size: 12px;
    color: #454856;
    z-index: 99;
}

.header-week-info {
    width: 80px;
}

.body-week-info {
    width: 200px;
}

.body-week-info .mark {
    display: inline-block;
    margin-right: 5px;
}
