.b-matrix-table__head {
	margin-top: 70px;
	height: 135px;
}

.b-matrix-table__head_margin_10 {
	margin-top: 10px;
	height: 135px;
}

.b-bt-head__title-01 {
	width: 56px;
	float: left;
}

.b-bt-head__title-01 span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
	position: relative;
	top: 110px;
	left: 5px;
}

.b-bt-head__title-01s-01 {
	margin-left: 97px;
	height: 135px;
	float: left;
}

.b-bth-title__name {
	float: left;
	display: block;
	width: 165px;
	height: 49px;
	margin-top: 49px;
	margin-left: -153px;
	padding: 5px 7px 5px 30px;
	border-top: 1px solid #dadcdc;
	line-height: 10px;
	-moz-transform: rotate(308deg);
	-webkit-transform: rotate(308deg);
	-o-transform: rotate(308deg);
	-ms-transform: rotate(308deg);
	transform: rotate(309deg);
	text-align: left;
}

.b-bth-title__name:first-of-type {
	margin-left: 0px
}

.b-bth-title__name span {
	font-size: 12px;
	font-weight: bold;
	color: #616365;
}

.b-bth-title__name a {
	font-size: 12px;
	color: #39b3ae;
	text-decoration: none;
}

.b-matrix-table__body-01 {
	position: relative;
	z-index: 1;
}

.b-matrix-table__list-01 table {
	border-collapse: collapse;
}

.b-matrix-table__list-01 table tbody:nth-child(even) tr > td {
	background: #f6f6f7;
}

.b-matrix-table__list-01 table tbody tr td:nth-child(2) {
	border-left: 1px solid #dadcdc;
}

.b-matrix-table__list-01 table tbody tr td:nth-child(5) {
	border-left: 1px solid #dadcdc;
}

.b-matrix-table__list-01 table tbody tr > td:first-of-type {
	font-size: 12px;
	width: 160px;
	padding-left: 7px;
	border: none;
	border-top: 1px solid #dadcdc;
	border-bottom: 1px solid #dadcdc;
	background: none;
}

.b-matrix-table__list-01 table tr > td {
	width: 46px;
	height: 45px;
	border: 1px solid #dadcdc;
}

input[type="checkbox"].b-check__matrix {
	display: none;
}

input[type="checkbox"].b-check__matrix + label {
	cursor: pointer;
}

input[type="checkbox"].b-check__matrix + label span {
	display: inline-block;
	width: 46px;
	height: 45px;
	vertical-align: middle;
	background: none;
	cursor: pointer;
}

input[type="checkbox"].b-check__matrix:checked + label span {
	background: url(;) top no-repeat;
}

/**/
input[type="radio"].b-check__matrix {
	display: none;
}

input[type="radio"].b-check__matrix + label {
	cursor: pointer;
}

input[type="radio"].b-check__matrix + label span,
input[type="radio"].b-check__matrix_clear + label span {
	display: inline-block;
	width: 46px;
	height: 45px;
	vertical-align: middle;
	background: none;
	cursor: pointer;
}

input[type="radio"].b-check__matrix_clear {
	display: none;
}

input[type="radio"].b-check__matrix:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzU2NjhDNThERDMyMTFFNEFFOUVFRTkzMTI3MTREQzIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzU2NjhDNTlERDMyMTFFNEFFOUVFRTkzMTI3MTREQzIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3NTY2OEM1NkREMzIxMUU0QUU5RUVFOTMxMjcxNERDMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3NTY2OEM1N0REMzIxMUU0QUU5RUVFOTMxMjcxNERDMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgBWOKoAAAIFSURBVHja7Jm7SwNBEMYTiWjhAywEC02pINrER7A1QjSxsNHOwkat/BdsJQgKFloI2gsWJkEQLEWTqEQbuxAVBC18YBFUOL+BsQkR9vZ2EgI78GOI2Z39sszNzp5+x3F89WgNvjo1K9wKt8KtcLMW8BpgLHnoaf5ZfMamiq41gSjYBnnwBhz2ef57lMfVPlU4xgJYBV0Vvm8Hg8wieAZrYAt81WTHkd+9cJdg5x/RlawTrIMs6NNd26/bZEH0MNwx6PDw21/BJB7Qi6oIh2jaqXNOA6/2DsIQfycqHKIb4XKcs6bsFoQg/lsyx1cMiyYb4LgyO47dboYr8gNm2qjaBLHrJYkdjwuJ/qs201KpEhM+EKekhIcUxxXAOGhlX1CcNyQlvEdxHJ2kp+CT/bzivG4p4ap1O1f2OWM4vmvhH4rjwuUFyXB818IfFMftgghoYb+vOO9Rqjukxqhf8Vk40agqGakdTwuXw7SU8CM+4STsheObF87HcUJIeEL1uNdtsjbBjWHR1B1uiN6AuPWc4z7ahFGcWTctrfbVjZv+iIF8p/kTbi8Rnu6cWIxOR7q+XWmGuAYjiJOt+mUZi97DjYJl8KQ4jcYtkWju7Wv2euKH35nscb8e4y4yCNr4GC/y24AUSIKS10UDBisDiTlgxM1v/wdkhVvhVrgVboVb4S7sV4ABAPYLgqVAis8/AAAAAElFTkSuQmCC) top no-repeat;
}

/**/

/*icon*/
.b-icon__eye {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEI3QkVERDJERDMzMTFFNDlCOUNFMEJFMkRENzMxRUEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEI3QkVERDNERDMzMTFFNDlCOUNFMEJFMkRENzMxRUEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0QjdCRUREMEREMzMxMUU0OUI5Q0UwQkUyREQ3MzFFQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0QjdCRUREMUREMzMxMUU0OUI5Q0UwQkUyREQ3MzFFQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PulWmZMAAAGDSURBVHja5NStS0NRGMfxTaYONUyLRdNUmMGXomnRYhERw2BNy4JgcGLQ/Q9ahmlJFBwGFwyiyRXxBUFhEwRREV9AMQkOnN8Dv8Hxeu8wLAg+8OHs3HvOc17uOfP5/lO0IldXo2S9OMRltUYNGEcaBzhFXnXzvFHtxvCEuKn4PRJNYxFFbOMML2hDv5L04ER1M8CR26z6cI4dNayE+T1pPWvBngYpqN+PiGvqMetZE9ZxrZmaMocLrGo1MXvJlVjAFSKOQZaxYe3XKN6x72gXUX+Tx5fUXnW4zPoRnfo9h3tM4NmlrelfDGj9BU3bGc34wJo+wjDetBXOMP2L5hxOwZSbLg3z+pKfiOIWM9h1GThrn5p6ZHCMLj2Lasmv2sdZlTcIW8m61S+jPN8ioSRZlSMIKVlaZcg6r0ktNVHtdqxrnx6whEEE9D6gegp3GjjslaxdV2xLB3dAx8bcz5IOcUn1Fb33jCHtTcrjOgb1bxL87b/FvO7n3wt/uVyuacIvAQYAMKRYPTXkDG0AAAAASUVORK5CYII=) no-repeat;
	padding: 5px 10px 5px 10px;
	background-position: 0px 4px;
	margin-right: 5px;
	cursor: pointer;
}

.i-icon__add_graph {
	width: 16px;
	height: 16px;
	display: inline-block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTkzMEEzNTdEMTU1MTFFNEI5MTlCMTdFMTNDQzE5MkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTkzMEEzNThEMTU1MTFFNEI5MTlCMTdFMTNDQzE5MkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFOTMwQTM1NUQxNTUxMUU0QjkxOUIxN0UxM0NDMTkyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFOTMwQTM1NkQxNTUxMUU0QjkxOUIxN0UxM0NDMTkyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvHQdT0AAAFASURBVHjadNLNK4RRFMfxB8PGJCJJdoQNKS8limQjJQspmpUsLPwHsiX+AhtiJa+lKWUhW0QoZUcRQiR2Rvge/a4OjVuf7vM8c8+95545sebkepRm5KEFxbjFLl78gtifgHxMYggZeEIh3jGHcX2LMl1QGfbRgUGdWoI4EujEHkp9oO2+ot0asYYU6pGt9wY8YsnWh8A+1KHf3cVOOkCV3l8xoI17fOAGLl3qcc1v7tsFkrY+FKdCKdgYQ7u7xhSucIQZzb3hxw9k6fkah7hxKRYgx9XjM5x4iiY9z2uuUTVH8eDStXVn4USraDcq3YLcNHesRhcWQ+AmdrCqbrFxjHKlGun7Mrax5Rsgod3tfsNqgHMUYURFSekv+dU5do82LGAad2o1mycwi1Y8f1fonya3bqlVevc40SY/40uAAQAH8UfpAGJSaAAAAABJRU5ErkJggg==) no-repeat;
	filter: hue-rotate(var(--icon-hue-rotate));
	background-position: -1px 3px;
}

.b-control__matrix {
	position: relative;
	transform: rotate(3deg) !important;
	top: -2px;
	left: 175px;
}

/*end icon*/

/*----*/
.b-class-pupils {
	font-size: 14px;
	font-weight: bold;
	color: #454856;
	margin-top: 20px;
	display: block;
}

.b-pupil__name {
	font-size: 14px;
	font-weight: normal;
	color: #454856;
}

.b-bt-h-bp__title {
	margin-top: -5px;
	line-height: 13px;
}

.b-bth-title__name {
	position: relative;
}

.b-control__matrix {
	position: absolute;
	width: 100px;
	top: 14px;
}

/*----*/

.student-to-group-dir-wrapper {
	margin: 0 0 35px 35px;
}

.student-to-group-dir-wrapper .b-bt-head__title-01s-01,
.student-to-group-dir-wrapper .b-bgt-ht-columns-rotate,
.student-to-group-dir-wrapper .b-bgt-head-table__column {
	height: 157px;
}

.student-to-group-dir-wrapper .b-bgt-ht-columns-rotate {
	width: 100%;
	left: 0;
}

.student-to-group-dir-wrapper .b-bgt-head-table__column {
	width: 100%;
	padding: 0;
}

.gender-separator-control.selected {
	background-color: #39b3ae;
	color: #fff;
}

.gender-separator-control span {
	font-size: 11px;
	line-height: 11px;
	font-weight: normal;
	color: #454856;
}

.gender-separator-control {
	text-align: center;
	width: 100%;
	cursor: pointer;
}

.mark-select-control {
	line-height: 14px;
	cursor: pointer;
	width: 40px;
	color: #39b3ae;
	border: none;
	text-overflow: '';
	-ms-appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none !important;
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat right 4px;
}

.student-to-group-dir-wrapper .b-bt-head__title-01s-01 {
	margin-left: 118px;
}

.student-to-group-dir-wrapper .b-bgt-ht-rotate-body,
.student-to-group-dir-wrapper .b-bgt-ht-column-rotate {
	width: 642px;
	background: none;
	border: none !important;
}

.student-to-group-dir-wrapper .b-bgt-ht-column-rotate div {
	-webkit-transition: left 0.2s ease-in-out;
	-moz-transition: left 0.2s ease-in-out;
	-ms-transition: left 0.2s ease-in-out;
	-o-transition: left 0.2s ease-in-out;
	transition: left 0.2s ease-in-out;
}

.student-to-group-students-count {
	float: left;
	border-bottom: none !important;
	font-size: 12px;
	color: #6c8796;
	font-weight: normal;
}

.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-left,
.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-right {
	z-index: 1;
	bottom: 0;
	height: 42px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-left_wide,
.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-right_wide {
	z-index: 1;
	bottom: 0;
	height: 42px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-left .i-icon-rotate_row-l,
.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-right .i-icon-rotate_row-r {
	margin-top: 10px;
}

.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-left_wide .i-icon-rotate_row-l,
.student-to-group-dir-wrapper .b-bgt-ht-rotate_row-right_wide .i-icon-rotate_row-r {
	margin-top: 10px;
}

.student-to-group-dir-wrapper table tbody tr td:nth-child(2),
.student-to-group-dir-wrapper table tbody tr td:last-child {
	width: 16px;
	position: relative;
	background: #f4f4f4;
}

.student-to-group-dir-wrapper .b-bgt-head-table__column,
.student-to-group-dir-wrapper .b-bgt-head-table__column:nth-child(1) {
	border: none;
	background: none;
}

.class-unit-header > td {
	height: 60px !important;
	vertical-align: top;
}

.student-to-group-cell {
	width: 49px;
	height: 45px;
	border-right: 1px solid #dadcdc;
	float: left;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.rotate-students-parent .student-to-group-cell:first-child {
	border-left: 1px solid #dadcdc;
}

.class-unit-header .student-to-group-cell-header:first-child {
	border-left: 1px solid #dadcdc;
}

.student-to-group-dir-wrapper .student-to-group-cell {
	-webkit-transition: left 0.2s ease-in-out;
	-moz-transition: left 0.2s ease-in-out;
	-ms-transition: left 0.2s ease-in-out;
	-o-transition: left 0.2s ease-in-out;
	transition: left 0.2s ease-in-out;
	z-index: 0 !important;
}

table.student-to-group-scroll-content tbody tr td,
table.student-to-group-scroll-content tbody tr td span {
	z-index: 10;
}

table.student-to-group-scroll-content tbody tr td:first-child {
	border-left: 1px solid #dadcdc;
}

table.student-to-group-scroll-content tbody tr td {
	background: none;
}

.student-to-group-cell-header {
	height: 60px;
	border-right: 1px solid #dadcdc;
	float: left;
	text-align: center;
}

.student-to-group-cell-header div {
	border-bottom: 1px solid #dadcdc;
}

.student-to-group-dir-wrapper .class-unit-header .b-bgt-ht-columns-rotate {
	height: 60px !important;
}

.b-bgt-ht-column-rotate {
	padding: 0;
}

.student-to-group-transition {
	-webkit-transition: left 0.2s ease-in-out;
	-moz-transition: left 0.2s ease-in-out;
	-ms-transition: left 0.2s ease-in-out;
	-o-transition: left 0.2s ease-in-out;
	transition: left 0.2s ease-in-out;
}

.profile-to-group-radio-control {
	background: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	cursor: pointer;
	border: none;
}

.profile-to-group-radio-control:checked {
	background-color: #39b3ae;
	border: 1px solid #39b3ae;
}

.checked-hack-image {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzU2NjhDNThERDMyMTFFNEFFOUVFRTkzMTI3MTREQzIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzU2NjhDNTlERDMyMTFFNEFFOUVFRTkzMTI3MTREQzIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3NTY2OEM1NkREMzIxMUU0QUU5RUVFOTMxMjcxNERDMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3NTY2OEM1N0REMzIxMUU0QUU5RUVFOTMxMjcxNERDMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgBWOKoAAAIFSURBVHja7Jm7SwNBEMYTiWjhAywEC02pINrER7A1QjSxsNHOwkat/BdsJQgKFloI2gsWJkEQLEWTqEQbuxAVBC18YBFUOL+BsQkR9vZ2EgI78GOI2Z39sszNzp5+x3F89WgNvjo1K9wKt8KtcLMW8BpgLHnoaf5ZfMamiq41gSjYBnnwBhz2ef57lMfVPlU4xgJYBV0Vvm8Hg8wieAZrYAt81WTHkd+9cJdg5x/RlawTrIMs6NNd26/bZEH0MNwx6PDw21/BJB7Qi6oIh2jaqXNOA6/2DsIQfycqHKIb4XKcs6bsFoQg/lsyx1cMiyYb4LgyO47dboYr8gNm2qjaBLHrJYkdjwuJ/qs201KpEhM+EKekhIcUxxXAOGhlX1CcNyQlvEdxHJ2kp+CT/bzivG4p4ap1O1f2OWM4vmvhH4rjwuUFyXB818IfFMftgghoYb+vOO9Rqjukxqhf8Vk40agqGakdTwuXw7SU8CM+4STsheObF87HcUJIeEL1uNdtsjbBjWHR1B1uiN6AuPWc4z7ahFGcWTctrfbVjZv+iIF8p/kTbi8Rnu6cWIxOR7q+XWmGuAYjiJOt+mUZi97DjYJl8KQ4jcYtkWju7Wv2euKH35nscb8e4y4yCNr4GC/y24AUSIKS10UDBisDiTlgxM1v/wdkhVvhVrgVboVb4S7sV4ABAPYLgqVAis8/AAAAAElFTkSuQmCC) top no-repeat !important;
	z-index: 0;
	position: relative;
}

.rotate-students-parent {
	position: relative;
	width: 600px;
	height: 45px;
	background: none !important;
	z-index: 999;
	overflow: hidden;
}

.student-to-group-save-button {
	padding-top: 0;
}

.disable-student-to-group-cell {
	background-color: #f3f3f3;
}

.student-to-group-info-message {
	width: 100%;
	padding: 10px;
	text-align: center;
}

.class-unit-title {
	text-align: center;
	width: 185px;
	padding: 23px 0 24px 0;
	border-left: 1px solid #ddd;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	font-size: 14px;
	font-weight: bold;
	color: #454856;
}

.student-to-group-table-header {
	margin-top: 5px;
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
	display: table;
}

.student-to-group-table-header-rotate-wrapper {
	width: 1060px;
	height: 191px;
	overflow: hidden;
	position: relative;
}

.student-to-group-table-header-rotate-wrapper_wide {
	width: 6000px;
	height: 191px;
	overflow: hidden;
	position: relative;
}

.student-to-group-table-header-left-column {
	display: table-cell;
	text-align: left;
	vertical-align: bottom;
	line-height: 12px;
}

.student-to-group-table-header-right-column {
	display: table-cell;
	width: 1036px;
}

.student-to-group-table-header-right-column .b-bgt-ht-rotate_row-left {
	 z-index: 1;
	 top: 192px;
	 left: 204px;
	 height: 42px;
	 -webkit-user-select: none;
	 -moz-user-select: none;
	 -ms-user-select: none;
	 user-select: none;
 }

.student-to-group-table-header-right-column .b-bgt-ht-rotate_row-left_wide {
	z-index: 1;
	top: 234px;
	left: 204px;
	height: 42px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.b_sj-table__head-fixtop .student-to-group-table-header-right-column .b-bgt-ht-rotate_row-left {
	left: 169px;
	top: 135px;
}

.b_sj-table__head-fixtop .student-to-group-table-header-right-column .b-bgt-ht-rotate_row-right {
	top: 135px;
}

.student-to-group-table-header-right-column .b-bgt-ht-rotate_row-right {
	 z-index: 1;
	 top: 192px;
	 right: 37px;
	 height: 42px;
	 -webkit-user-select: none;
	 -moz-user-select: none;
	 -ms-user-select: none;
	 user-select: none;
 }

.student-to-group-table-header-right-column .b-bgt-ht-rotate_row-right_wide {
	z-index: 1;
	top: 234px;
	right: 20px;
	height: 42px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.b_sj-table__head-fixtop .b-bgt-ht-rotate_row-right,
.b_sj-table__head-fixtop .b-bgt-ht-rotate_row-left {
	top: 135px;
}

.b_sj-table__head-fixtop_wide .b-bgt-ht-rotate_row-right_wide {
	top: auto;
	right: 2px;
}
.b_sj-table__head-fixtop_wide .b-bgt-ht-rotate_row-left_wide {
	top: auto;
	left: 169px;
}

.b_sj-table__head-fixtop .b-bgt-ht-rotate_row-right {
	right: 0px;
}

.table-header-rotated {
	border-collapse: collapse;
	padding: 0;
	margin: 0;
}

.table-header-rotated th.row-header {
	width: auto;
}

.table-header-rotated td {
	width: 40px;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	vertical-align: middle;
	text-align: center;
	background: none !important;
}

.table-header-rotated th.rotate-45 {
	height: 130px;
	width: 50px;
	min-width: 50px;
	max-width: 50px;
	position: relative;
	vertical-align: bottom;
	padding: 0;
	font-size: 12px;
	line-height: 0.8;
}

.table-header-rotated th.rotate-45 > div {
	position: relative;
	top: 0;
	left: 55px;
	height: 100%;
	-ms-transform: skew(-40deg, 0deg);
	-moz-transform: skew(-40deg, 0deg);
	-webkit-transform: skew(-40deg, 0deg);
	-o-transform: skew(-40deg, 0deg);
	transform: skew(-40deg, 0deg);
	overflow: hidden;
	border-left: 1px solid #ddd;
}

.table-header-rotated th.rotate-45 span {
	-ms-transform: skew(40deg, 0deg) rotate(310deg);
	-moz-transform: skew(40deg, 0deg) rotate(310deg);
	-webkit-transform: skew(40deg, 0deg) rotate(310deg);
	-o-transform: skew(40deg, 0deg) rotate(310deg);
	transform: skew(40deg, 0deg) rotate(310deg);
	position: absolute;
	bottom: 51px;
	left: -40px;
	display: inline-block;
	width: 135px;
	text-align: left;
	font-size: 12px;
	line-height: 12px;
	font-weight: bold;
	color: #6c8796;
}

.group-marker {
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.low-rider-element {
	-webkit-transition: left 0.2s ease-in-out;
	-moz-transition: left 0.2s ease-in-out;
	-ms-transition: left 0.2s ease-in-out;
	-o-transition: left 0.2s ease-in-out;
	transition: left 0.2s ease-in-out;
}

.remove-assmnts-toplan {
	color: #fff !important;
}
