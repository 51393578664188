.student-final-marks-table {
    display: table;
    padding: 0;
    margin: 20px auto 0;
    width: 878px;
    border-collapse: collapse;
    font-size: 13px;
    line-height: 15px
}

.student-final-marks-table-header {
    display: table-row;
    font-weight: bold;
    color: #6c8796;
    background-color: #ebf2f5 !important;
    border-top: 2px solid #cfd4d7;
    border-bottom: 2px solid #cfd4d7;
}

.student-final-marks-table .year-mark,
.student-final-marks-table .periods-wrapper,
.student-final-marks-table .subject-name {
    display: table-cell;
    margin: 0;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 5px;
}

.student-final-marks-table .subject-name {
    width: 220px;
}

.student-final-marks-table .periods-wrapper {
    width: 693px;
    padding: 0 0 0 5px
}

.student-final-marks-table .student-final-marks-table-body .periods-wrapper {
    padding: 0;
}

.student-final-marks-table .periods span {
    display: block;
    height: 35px;
    line-height: 35px;
}

.student-final-marks-table .year-mark {
    text-align: center;
}

.student-final-marks-table .bold-mark {
    font-weight: bold;
}

.student-final-marks-table-body {
    display: table-row;
    border-bottom: 1px solid #cfd4d7;
}

.student-final-marks-table-body .year-mark,
.student-final-marks-table-body .periods {
    display: table-cell;
    width: 45px;
    text-align: center;
}

.student-final-marks-table-header .year-mark,
.student-final-marks-table-header .subject-name,
.student-final-marks-table-header .periods-wrapper,
.student-final-marks-table-body .subject-name,
.student-final-marks-table-body .year-mark,
.student-final-marks-table-body .periods-wrapper,
.student-final-marks-table-body .periods {
    border-right: 1px solid #cfd4d7;
}